import React from "react";
import HomeNavbar from "./Layouts/HomeNavbar.jsx";
import Swiper from "swiper";
import Sildes from "./Sildes.jsx";
import NoticeBar from "./NoticeBar.jsx";
// import "../index.css";
import Balance from "./Balance.jsx";
import GameMenu from "./GameMenu.jsx";
import GameList from "./GameList.jsx";
import LuckyWinners from "./LuckyWinners.jsx";
import DailyChart from "./DailyChart.jsx";
import MenuBar from "./MenuBar.jsx";

const Dashboard = () => {
  return (
    <>
      <div className="content">
        <HomeNavbar />
        <Sildes />
        <NoticeBar />
        <Balance />
        <GameMenu />
        <GameList />
        <LuckyWinners />
        <DailyChart />
      </div>
      <MenuBar />
    </>
  );
};

export default Dashboard;
