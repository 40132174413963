import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { SET_SECOND_TIMER, SelectBatBtnDisable, SelectColorActiveGame, SelectSecondTimer } from '../../../redux/colorGameSlice'
import {useSelector, useDispatch} from "react-redux"
import SpinnerComponent from './SpinnerComponent'

const TimerComponent = () => {
    let dispatch = useDispatch()
//   let {secondTimer, setSecondTimer, activeGame,} = GameInfoState()
  let secondTimer = useSelector(SelectSecondTimer)
  const batBtnDisable = useSelector(SelectBatBtnDisable)

  
  let activeGame = useSelector(SelectColorActiveGame)

  const [secondDiffShow, setSecondDiffShow] = useState(null)

   useEffect(() => {
    if(!secondTimer){
      setSecondDiffShow(null)
      return
    }
    let endTime = moment()
    endTime.add(secondTimer, 'seconds')
      .format('LTS');
    let interval = setInterval(() => {
      getTime(endTime)
    }, 1000);

    return () => clearInterval(interval);
  }, [secondTimer]);

  useEffect(() => {
    let modal = document.getElementById('modal_remainig_time')
    if(secondDiffShow <=5 && secondDiffShow){
      let modal_remainig_time_1 = document.getElementById('modal_remainig_time_1')
      let modal_remainig_time_2 = document.getElementById('modal_remainig_time_2')
      if(modal.style.display === 'none'){
        modal.style.display = 'flex'
      }
        modal_remainig_time_2.innerHTML =  secondDiffShow?.toString().substring(0, 1)
        modal_remainig_time_1.innerHTML =  0
    }else{
      if(modal.style.display === 'flex'){
        modal.style.display = 'none'
      }
    }
  }, [secondDiffShow,batBtnDisable])
  


  let getTime = (endTime) => {
    let startTime = moment()
    var secondsDiff = endTime.diff(startTime, 'seconds');
    
    if(secondsDiff===0){
      dispatch(SET_SECOND_TIMER(0))
    }
    setSecondDiffShow(secondsDiff)

  }
   

  useEffect(() => {
    setSecondDiffShow(null)
  }, [activeGame])
  
    

  return (
    <>
       { secondDiffShow ? 
       
       <>
       <div className="TimeLeft__C-time">
       {moment.utc(secondDiffShow * 1000).format('mm:ss').split('').map((n,index)=>{
        return <div key={index}>{n}</div>
       })}
      </div>
       </>
       :<div className="TimeLeft__C-time_loader items-center justify-center min-w-[104px]"><SpinnerComponent /></div>
}
    </>
    
  )
}

export default TimerComponent