import "./BetRecords.css";
const BetRecords = () => {
  return (
    <>
      <div class="bet-container">
        <div class="navbarBet">
          <div
            class="navbarBet-fixed"
            style={{ background: "rgb(63, 63, 63)" }}
          >
            <div class="navbar__contentBet">
              <div class="navbar__contentBet-left">
                <i class="van-badge__wrapper van-icon van-icon-arrow-left"></i>
              </div>
              <div class="navbar__contentBet-center">
                <div class="navbar__contentBet-title">Bet history</div>
              </div>
              <div class="navbar__contentBet-right"></div>
            </div>
          </div>
        </div>
        <div class="bet-container-sticky">
          <div>
            <div style={{ background: "rgb(41, 41, 41)" }}>
              <div class="fun-tabs tabs" activeclassname="tab_active">
                <div
                  class="fun-tabs__tab-list"
                  style={{
                    transitionTimingFunction:
                      "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
                    transitionDuration: "360ms",
                    transform: "translate3d(0px, 0px, 0px)",
                  }}
                >
                  <div
                    class="fun-tab-item funtab_item activeClassName"
                    style={{ color: "rgb(22, 119, 255)" }}
                  >
                    <div class="fun-tab-item__wrap">
                      <div class="fun-tab-item__label">
                        <div class="tab_item tab_active">
                          <img src="https://www.bdggame.in/assets/png/lotteryActive-1ab204b4.png" />
                          <span>Lottery</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fun-tab-item funtab_item">
                    <div class="fun-tab-item__wrap">
                      <div class="fun-tab-item__label">
                        <div class="tab_item">
                          <img src="https://www.bdggame.in/assets/png/video-155c08c2.png" />
                          <span>Casino</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fun-tab-item funtab_item">
                    <div class="fun-tab-item__wrap">
                      <div class="fun-tab-item__label">
                        <div class="tab_item">
                          <img src="https://www.bdggame.in/assets/png/fish-7bb09242.png" />
                          <span>Fishing</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fun-tab-item funtab_item">
                    <div class="fun-tab-item__wrap">
                      <div class="fun-tab-item__label">
                        <div class="tab_item">
                          <img src="https://www.bdggame.in/assets/png/chess-b84d67c5.png" />
                          <span>Rummy</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fun-tab-item funtab_item">
                    <div class="fun-tab-item__wrap">
                      <div class="fun-tab-item__label">
                        <div class="tab_item">
                          <img src="https://www.bdggame.in/assets/png/flash-3b77ef92.png" />
                          <span>Original</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fun-tab-item funtab_item">
                    <div class="fun-tab-item__wrap">
                      <div class="fun-tab-item__label">
                        <div class="tab_item">
                          <img src="https://www.bdggame.in/assets/png/slot-bd279114.png" />
                          <span>Slots</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="fun-tabs__active-line"
                    style={{
                      transition: "all 300ms ease 0s",
                      width: "0px",
                      height: "3px",
                      transform: "translate3d(72px, 0px, 0px)",
                      backgroundColor: "rgb(22, 119, 255)",
                    }}
                  ></div>
                </div>
              </div>
              <div class="bet-container-searchBar">
                <div class="ar-searchbar">
                  <div class="ar-searchbar__selector">
                    <div>
                      <span class="ar-searchbar__selector-default">Win Go</span>
                      <i class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
                    </div>
                  </div>
                  <div class="ar-searchbar__selector">
                    <div>
                      <span class="ar-searchbar__selector-default">
                        Choose a date
                      </span>
                      <i class="van-badge__wrapper van-icon van-icon-arrow-down"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div class="bet-content__box">
          <div
            class="infiniteScroll"
            id="refreshb146e9d846e1480d9486f126fafb4e6f"
          >
            <div class="bet-container-items">
              <div class="bet-container-lottery"></div>
            </div>
            <div class="infiniteScroll__loading">
              <div class="empty__container empty">
                <img
                  alt=""
                  class=""
                  data-origin="https://www.bdggame.in/assets/png/empty-ea102850.png"
                  src="https://www.bdggame.in/assets/png/empty-ea102850.png"
                />
                <p>No data</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BetRecords;
