import { SelectUserWallet } from "../../../redux/comanGameInfoSlice";
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";



const Wallet = () => {
  let navigate = useNavigate();

  let walletAmount = useSelector(SelectUserWallet)

  let depositAdd = ()=>{
    // 
    navigate('/color/wallet/recharge')
  }


  return (
    <>
      <div class="Wallet__C">
        <div class="Wallet__C-balance">
          <div class="Wallet__C-balance-l2">
            <div>Wallet balance</div>
          </div>
          <div class="Wallet__C-balance-l1">
            <div>₹{walletAmount}</div>
          </div>
          <div class="Wallet__C-balance-l3">
            <div className="cursor-pointer" onClick={depositAdd}>Deposit</div>
            <div>Withdraw</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
