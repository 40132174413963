import React from "react";
import styled from "./Login.module.css";

const Tab = ({ label, onClick, isActive }) => {
  return (
    <>
      <div
        className={`${styled.tab} ${isActive ? styled.active : ""}`}
        onClick={onClick}
      >
        <div
          className={`${styled.phonebg} ${isActive ? styled.phoneactive : ""}`}
        ></div>
        <div
          className={`${styled.font30} ${
            isActive ? styled.phonefont30active : ""
          }`}
        >
          {label}
        </div>
      </div>
    </>
  );
};

export default Tab;
