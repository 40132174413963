const LuckyWinners = () => {
  return (
    <div class="luckyWinners__container">
      <h1>Winning information</h1>
      <div class="luckyWinners__container-wrapper">
        <div style={{ position: "relative" }}>
          <div class="luckyWinners__container-wrapper__item">
            <div class="luckyWinners__container-wrapper__item-img">
              <img
                class=""
                data-origin="/images/14-a397ff6b.png"
                src="/images/14-a397ff6b.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-info">
              <h1>Mem***WRJ</h1>
            </div>
            <div class="luckyWinners__container-wrapper__item-winType">
              <img
                class=""
                data-origin="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
                src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-winAmount">
              <h1>Receive ₹784.00</h1>
              <span>Winning amount</span>
            </div>
          </div>
          <div class="luckyWinners__container-wrapper__item">
            <div class="luckyWinners__container-wrapper__item-img">
              <img
                class=""
                data-origin="/images/7-00479cfa.png"
                src="/images/7-00479cfa.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-info">
              <h1>Mem***BLF</h1>
            </div>
            <div class="luckyWinners__container-wrapper__item-winType">
              <img
                class=""
                data-origin="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
                src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-winAmount">
              <h1>Receive ₹196.00</h1>
              <span>Winning amount</span>
            </div>
          </div>
          <div class="luckyWinners__container-wrapper__item">
            <div class="luckyWinners__container-wrapper__item-img">
              <img
                class=""
                data-origin="/images/1-c7e4efc8.png"
                src="/images/1-c7e4efc8.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-info">
              <h1>Mem***RTI</h1>
            </div>
            <div class="luckyWinners__container-wrapper__item-winType">
              <img
                class=""
                data-origin="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
                src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-winAmount">
              <h1>Receive ₹18,620.00</h1>
              <span>Winning amount</span>
            </div>
          </div>
          <div class="luckyWinners__container-wrapper__item">
            <div class="luckyWinners__container-wrapper__item-img">
              <img
                class=""
                data-origin="/images/7-00479cfa.png"
                src="/images/7-00479cfa.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-info">
              <h1>Mem***UAQ</h1>
            </div>
            <div class="luckyWinners__container-wrapper__item-winType">
              <img
                class=""
                data-origin="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
                src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-winAmount">
              <h1>Receive ₹980.00</h1>
              <span>Winning amount</span>
            </div>
          </div>
          <div class="luckyWinners__container-wrapper__item">
            <div class="luckyWinners__container-wrapper__item-img">
              <img
                class=""
                data-origin="/images/10-29a6603e.png"
                src="/images/10-29a6603e.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-info">
              <h1>Mem***EFS</h1>
            </div>
            <div class="luckyWinners__container-wrapper__item-winType">
              <img
                class=""
                data-origin="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
                src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-winAmount">
              <h1>Receive ₹196.00</h1>
              <span>Winning amount</span>
            </div>
          </div>
          <div class="luckyWinners__container-wrapper__item">
            <div class="luckyWinners__container-wrapper__item-img">
              <img
                class=""
                data-origin="/images/7-00479cfa.png"
                src="/images/7-00479cfa.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-info">
              <h1>Mem***ADN</h1>
            </div>
            <div class="luckyWinners__container-wrapper__item-winType">
              <img
                class=""
                data-origin="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
                src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062124qut6.png"
              />
            </div>
            <div class="luckyWinners__container-wrapper__item-winAmount">
              <h1>Receive ₹441.00</h1>
              <span>Winning amount</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LuckyWinners;
