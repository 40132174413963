import React from "react";
import styled from "./css/HomeNavbar.module.css";

const HomeNavbar = () => {
  return (
    <>
      <div className={styled.navbar}>
        <div
          className={styled.navbar_fixed}
          style={{ background: "rgb(63, 63, 63)" }}
        >
          <div className={styled.navbar__content}>
            <div className={styled.navbar__content_left}>
              <div className={styled.logo}>
                <img
                  src="https://ossimg.bdgadminbdg.com/IndiaBDG/other/h5setting_202401100608011fs2.png"
                  alt=""
                />
              </div>
              <div className={styled.languages}>
                <div>
                  <div className={styled.right}>
                    <div className={styled.img}>
                      <img src="https://bdg1.in/assets/png/en-4c6eba8e.png" />
                    </div>
                    <span className={styled.lang_text}>EN</span>
                  </div>
                </div>
                <span className={styled.languages_text}>
                  Welcome to BDG Game
                </span>
              </div>
            </div>
            <div className={styled.navbar__content_right}>
              <div className={styled.content__right}>
                <div className={styled.btn}>
                  <span>Download APP</span>
                </div>
                <div className={styled.btn}>
                  <span>Customer Service</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeNavbar;
