import React from "react";

const MenuBar = () => {
  return (
    <div class="tabbar__container">
      <div class="tabbar__container-item active">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          class=""
        >
          <g clipPath="url(#clip0_226_251)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M52.3509 27.9323L29.2711 5.07261C28.5724 4.37977 27.4394 4.37847 26.74 5.07065L26.7387 5.07261L3.65824 27.931C2.98428 28.5985 2.60466 29.5042 2.604 30.4484C2.60596 32.411 4.21107 34.0005 6.19061 34.0018H8.6227V50.3141C8.62075 51.2941 9.42168 52.0898 10.4108 52.0911C10.4121 52.0911 10.4141 52.0911 10.416 52.0911H24.4186V39.65H30.6945V52.0911H45.5925C46.5816 52.0924 47.3838 51.2993 47.3858 50.3186C47.3858 50.3173 47.3858 50.3154 47.3858 50.3141V34.0018H49.8172C51.8 33.9966 53.4032 32.3987 53.3973 30.4334C53.3947 29.4951 53.0183 28.5959 52.3496 27.931L52.3509 27.9323Z"
              fill="url(#paint0_linear_226_251)"
            ></path>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_226_251"
              x1="2.604"
              y1="52.0931"
              x2="2.604"
              y2="4.55225"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#6F7381"></stop>
              <stop offset="1" stopColor="#A9AAB5"></stop>
            </linearGradient>
            <linearGradient
              id="paint0_linear_226_233"
              x1="6.5116"
              y1="52.7442"
              x2="6.5116"
              y2="3.90698"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#C4933F"></stop>
              <stop offset="1" stopColor="#FAE59F"></stop>
            </linearGradient>
            <clipPath id="clip0_226_251">
              <rect width="56" height="56" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
        <span>Home</span>
      </div>
      <div class="tabbar__container-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          class=""
        >
          <g clipPath="url(#clip0_226_254)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M44.182 30.186C41.748 35.6375 36.5016 39.6076 30.2276 40.2914V48.3554H36.3668C37.5955 48.3554 38.6009 49.3412 38.6009 50.5472C38.6009 51.7538 37.5955 52.7396 36.3668 52.7396H19.6234C18.396 52.7396 17.3906 51.7538 17.3906 50.5472C17.3906 49.3412 18.396 48.3554 19.6234 48.3554H25.7619V40.223C19.7836 39.3569 14.8048 35.4838 12.4515 30.2133C7.01041 30.1039 2.60074 25.719 2.60074 20.3475C2.60074 15.4697 6.25572 11.3862 11.0001 10.6192V7.05601C11.0001 5.30242 12.4515 3.90503 14.2103 3.90503H42.3966C44.182 3.90503 45.6035 5.33042 45.6035 7.05601V10.7559C50.0412 11.7698 53.3908 15.6885 53.3908 20.3475C53.3908 25.5002 49.3158 29.7751 44.182 30.186ZM11.0001 23.6913V15.1135C8.73925 15.7985 7.06576 17.8809 7.06576 20.3475C7.06576 22.8695 8.79655 25.006 11.1401 25.6096C11.0561 24.9786 11.0001 24.3483 11.0001 23.6913ZM35.7827 17.7989L31.317 17.1959L29.3336 13.0584C28.5815 11.6057 27.4647 11.6057 26.8513 13.0584L24.8692 17.0865L20.4048 17.6895C18.8987 17.9363 18.5353 19.0322 19.6514 20.1288L22.8884 23.4165L22.1337 28.0488C21.8843 29.611 22.749 30.2407 24.1171 29.5009L28.1068 27.3085L32.0971 29.6377C33.4646 30.3774 34.3299 29.7751 34.0793 28.1856L33.3259 23.5532L36.5628 20.2655C37.6795 19.1696 37.3168 18.073 35.7827 17.7989ZM45.6035 15.3603V23.7186C45.6035 24.2936 45.5488 24.8419 45.4941 25.4175C47.5017 24.5951 48.9251 22.6221 48.9251 20.3749C48.9251 18.1277 47.5577 16.2094 45.6035 15.3603Z"
              fill="url(#paint0_linear_226_254)"
            ></path>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_226_254"
              x1="2.60074"
              y1="52.7442"
              x2="2.60074"
              y2="3.90503"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#6F7381"></stop>
              <stop offset="1" stopColor="#A9AAB5"></stop>
            </linearGradient>
            <linearGradient
              id="paint0_linear_226_233"
              x1="6.5116"
              y1="52.7442"
              x2="6.5116"
              y2="3.90698"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#C4933F"></stop>
              <stop offset="1" stopColor="#FAE59F"></stop>
            </linearGradient>
            <clipPath id="clip0_226_254">
              <rect width="56" height="56" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
        <span>Activity</span>
      </div>
      <div class="tabbar__container-item">
        <svg
          width="140"
          height="140"
          viewBox="0 0 140 140"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class=""
        >
          <g clipPath="url(#clip0_610_5412)">
            <g clipPath="url(#clip1_610_5412)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M112.494 16C115.5 16 118.181 17.5313 119.725 20.1104L133.863 44.1284C135.569 47.1105 135.325 57.1851 133.213 59.8448L79.8313 119.325C75.525 124.645 67.6438 125.531 62.2 121.26C61.4688 120.696 60.8188 120.051 60.25 119.325L6.78751 59.8448C4.59376 57.1851 4.35001 47.0299 6.13751 44.1284L20.275 20.1104C21.8188 17.5313 24.5813 16 27.5063 16H112.494Z"
                fill="#3B3B3B"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.637 31.3135C105.994 31.3135 108.106 32.4418 109.244 34.3762L120.294 52.2687C121.675 54.4448 121.431 57.2657 119.725 59.2L77.9625 106.833C74.55 110.782 68.4562 111.427 64.2312 108.284C63.6625 107.881 63.175 107.397 62.6875 106.833L21.0875 59.2C19.3813 57.1851 19.2188 54.4448 20.5188 52.2687L31.5688 34.3762C32.7063 32.5224 34.9 31.3135 37.175 31.3135H103.637ZM82.7562 68.6299L72.6 83.1374C72.1125 83.8627 70.2437 84.0239 69.5125 83.4597C69.35 83.3791 69.2687 83.218 69.1062 83.1374L58.1375 68.6299C56.5938 66.5344 53.5062 66.0508 51.3125 67.5821C49.1187 69.0329 48.6312 71.9344 50.175 74.0299L61.1437 88.5374C65.3687 93.6956 68.05 95.7911 70.8937 95.7911C74.0625 95.7911 76.7437 92.2448 79.75 88.5374L90.7187 74.0299C92.2625 71.9344 91.775 69.0329 89.5812 67.5821C87.3875 66.0508 84.3 66.5344 82.7562 68.6299Z"
                fill="url(#paint0_linear_610_5412)"
              ></path>
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_610_5412"
              x1="70.4231"
              y1="31.3135"
              x2="70.4231"
              y2="110.276"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FAE59F"></stop>
              <stop offset="1" stopColor="#C4933F"></stop>
            </linearGradient>
            <clipPath id="clip0_610_5412">
              <rect width="140" height="140" fill="white"></rect>
            </clipPath>
            <clipPath id="clip1_610_5412">
              <rect
                width="130"
                height="108"
                fill="white"
                transform="translate(5 16)"
              ></rect>
            </clipPath>
          </defs>
        </svg>
        <span>Promotion</span>
      </div>
      <div class="tabbar__container-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          class="wallet"
        >
          <g clipPath="url(#clip0_226_268)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M49.4884 48.8372H13.6744C11.3784 48.8372 9.48486 47.1383 9.16775 44.9302H46.2326C48.3905 44.9302 50.1396 43.1812 50.1396 41.0233V14.3777C52.3476 14.6942 54.0465 16.5877 54.0465 18.8837V44.2791C54.0465 46.7965 52.0058 48.8372 49.4884 48.8372ZM42.3256 41.6744H7.16281C4.64542 41.6744 2.60468 39.6337 2.60468 37.1163V12.3721C2.60468 9.85471 4.64542 7.81396 7.16281 7.81396H42.3256C44.843 7.81396 46.8837 9.85471 46.8837 12.3721V37.1163C46.8837 39.6337 44.843 41.6744 42.3256 41.6744ZM10.8158 32.5621H35.1439C37.3116 32.5621 39.0691 31.8113 39.0691 29.7054C39.0691 29.4436 38.853 29.234 38.5866 29.234H18.3837C17.6791 29.234 17.1087 28.6798 17.1087 27.9955V25.6734C17.1087 25.5184 16.9804 25.3921 16.8209 25.3941C15.3134 25.409 14.7267 26.3487 13.0793 28.5502L10.4186 32.1766C10.4186 32.3888 10.5977 32.5621 10.8158 32.5621ZM38.6719 16.9257H14.3445C12.1761 16.9257 10.4193 17.6771 10.4193 19.783C10.4193 20.0448 10.6348 20.2544 10.9018 20.2544H31.1048C31.8087 20.2544 32.3797 20.8086 32.3797 21.4923V23.8143C32.3797 23.97 32.5074 24.0956 32.6676 24.0943C34.175 24.0794 34.7617 23.1397 36.4091 20.9382L39.0698 17.3118C39.0698 17.0995 38.8907 16.9257 38.6719 16.9257Z"
              fill="url(#paint0_linear_226_268)"
            ></path>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_226_268"
              x1="2.60468"
              y1="48.8372"
              x2="2.60468"
              y2="7.81396"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#6F7381"></stop>
              <stop offset="1" stopColor="#A9AAB5"></stop>
            </linearGradient>
            <linearGradient
              id="paint0_linear_226_233"
              x1="6.5116"
              y1="52.7442"
              x2="6.5116"
              y2="3.90698"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#C4933F"></stop>
              <stop offset="1" stopColor="#FAE59F"></stop>
            </linearGradient>
            <clipPath id="clip0_226_268">
              <rect width="56" height="56" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
        <span>Wallet</span>
      </div>
      <div class="tabbar__container-item">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          class=""
        >
          <g clipPath="url(#clip0_226_262)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.0837 30.8723C13.9446 30.8723 6.5116 38.149 6.5116 47.1012V48.0656C6.5116 52.7442 13.8261 52.7442 23.0837 52.7442H32.9162C41.8098 52.7442 49.4883 52.7442 49.4883 48.0656V47.1012C49.4883 38.1536 42.0547 30.8723 32.9162 30.8723H23.0811H23.0837ZM27.507 29.6318C34.7447 29.6318 40.6312 23.8638 40.6312 16.7707C40.6312 9.67694 34.7447 3.90698 27.5096 3.90698C20.2713 3.90698 14.3822 9.67694 14.3822 16.7707C14.3822 23.8619 20.2713 29.6318 27.5096 29.6318H27.507Z"
              fill="url(#paint0_linear_226_262)"
            ></path>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_226_262"
              x1="6.5116"
              y1="52.7442"
              x2="6.5116"
              y2="3.90698"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#6F7381"></stop>
              <stop offset="1" stopColor="#A9AAB5"></stop>
            </linearGradient>
            <linearGradient
              id="paint0_linear_226_233"
              x1="6.5116"
              y1="52.7442"
              x2="6.5116"
              y2="3.90698"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#C4933F"></stop>
              <stop offset="1" stopColor="#FAE59F"></stop>
            </linearGradient>
            <clipPath id="clip0_226_262">
              <rect width="56" height="56" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
        <span>Account</span>
      </div>
    </div>
  );
};

export default MenuBar;
