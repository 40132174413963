import { createSlice } from "@reduxjs/toolkit";


export const SelectCanvasWidth = ({ AviatorCanvasSlice }) => AviatorCanvasSlice.CANVAS_WIDTH
export const SelectCanvasHeight = ({ AviatorCanvasSlice }) => AviatorCanvasSlice.CANVAS_HEIGHT
export const SelectCanvasDom = ({ AviatorCanvasSlice }) => AviatorCanvasSlice.CANVAS_DOM



const AviatorCanvasSlice = createSlice({
    name: "AviatorCanvasSlice",
    initialState: {
        CANVAS_WIDTH : null,
        CANVAS_HEIGHT : null,
        CANVAS_DOM : null
        
    },
    reducers: {
        SET_CANVAS_WIDTH : (state, { payload }) => { state.CANVAS_WIDTH = payload },
        SET_CANVAS_HEIGHT : (state, { payload }) => { state.CANVAS_HEIGHT = payload },
        SET_CANVAS_DOM : (state, { payload }) => { state.CANVAS_DOM = payload },
    }
})





export const {
    SET_CANVAS_WIDTH, SET_CANVAS_HEIGHT,SET_CANVAS_DOM

} = AviatorCanvasSlice.actions;

export default AviatorCanvasSlice.reducer;