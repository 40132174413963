import { useEffect, useState } from "react";
import Navbar from "../Layouts/Navbar";
import styled from "./Login.module.css";
import Tabs from "./Tabs";

const Login = () => {
  const tabData = [
    { label: "Log in with Phone", name: "Login" },
    { label: "Register", name: "Register" },
  ];

  

  return (
    <div className={styled.login__container}>
      <Navbar />
      <div className={styled.login__container_heading}>
        <h1 className={styled.login__container_heading__title}>Log in</h1>
        <div className={styled.login__container_heading__subTitle}>
          <div>Please log in with your phone number or email</div>
          <div>
            If you forget your password, please contact customer service
          </div>
        </div>
      </div>
      <Tabs tabs={tabData} />
    </div>
  );
};

export default Login;
