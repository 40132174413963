import { createSlice } from "@reduxjs/toolkit";


export const SelectMainSuccessModal = ({ successMessageSlice }) => successMessageSlice.MainSuccessPopup



const successMessageSlice = createSlice({
    name: "successMessageSlice",
    initialState: {
        MainSuccessPopup : {show:false,message:''}
    },
    reducers: {
        SHOW_MAIN_SUCCESS_MODAL: (state, { payload }) => { state.MainSuccessPopup = {show:true,message:payload} },
        HIDE_MAIN_SUCCESS_MODAL: (state, { payload }) => { state.MainSuccessPopup = {show:false,message:''} },
    }
})





export const {
    SHOW_MAIN_SUCCESS_MODAL,HIDE_MAIN_SUCCESS_MODAL

} = successMessageSlice.actions;

export default successMessageSlice.reducer;


