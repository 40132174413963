import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectActiveInfoTab, SET_ACTIVE_INFO_TAB } from '../../../../../redux/AviatorGameSlice'
import AllBetsComponent from './AllBetsComponent'
import "./infoboard.css"
import MyBetsComponent from './MyBetsComponent'
import TopBatComponent from './TopBatComponent'

function AviatorInfoBoard() {
    const dispatch = useDispatch()
    const activeInfoTab = useSelector(SelectActiveInfoTab)

    return (
        <div className='h-full w-full flex-grow-0 flex-shrink-0 pl-[10px] flex flex-col 
        md992:!min-w-[320px] md992:!max-w-[320px] 
        md1200:!min-w-[370px] md1200:!max-w-[370px] 
        md1399:!min-w-[410px] md1399:!max-w-[410px] 
        !pt-[10px] max992:h-[80vh]'>
            {/* bets blocks */}
            <div className='flex flex-col h-full w-full bg-[#1b1c1d] rounded-[10px]'>
                {/* tab continer */}
                <div className='flex justify-center items-center my-[5px] min-h-[24px] max-h-[24px] text-[12px]'>
                    {/* navigation switcher */}
                    <div className='h-[100%] bg-[#141516] rounded-[10px] border border-[#141516] relative'>
                        <div className={`w-[100px] h-[100%] absolute rounded-[10px] bg-[#2c2d30]`} style={{ transform: `translate(${activeInfoTab * 100}px)`, transition: 'transform .5s ease' }} />
                        {['All Bets', 'My Bets'].map((tab, index) => {
                            return (
                                <button key={index} className={`w-[100px] ${activeInfoTab === index ? 'text-[#fff]' : 'text-[#9ea0a3]'} h-[100%] relative text-center bg-none border-none p-0`} onClick={() => dispatch(SET_ACTIVE_INFO_TAB(index))}>{tab}</button>
                            )
                        })}
                    </div>
                </div>

                {/* all Bets */}
                {activeInfoTab === 0 && <AllBetsComponent />}
                {activeInfoTab === 1 && <MyBetsComponent />}
                {activeInfoTab === 2 && <TopBatComponent />}

            </div>
        </div>
    )
}

export default AviatorInfoBoard
