import React from 'react'

function HugeWinsBatList() {
  return (
    <div className='scroll-restyle h-[88%] my-[5px] ml-[5px] overflow-y-scroll'>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 2, 6, 73, 2, 5, 6, 7, 8, 4,].map((number, index) => {
        return (
          <div className='mt-[5px]' key={index}>
            <div className='h-[80px] bg-[#101112] relative flex items-center'>
              <div className='ml-[30px] absolute'>
                <img src="assets/av-22.png?v=v4.2.27-hotfix-2" alt="avatar" className="w-[30px] h-[30px] mx-[5px]" />
                <div className='text-[13px] text-[#9ea0a3] text-center'>d***{number}</div>
              </div>
              {/* --- */}
              <div className='flex w-full justify-center items-center'>
                <div className='w-full'>
                  {/* ** */}
                  <div className='flex items-center'>
                    <div className='w-[50%] flex justify-end ml-[20px] text-[12px] text-[#9ea0a3]'><span >Bet INR:</span></div>
                    <span className="text-[14px] text-[#fff] ml-[5px]"> 1.20 </span>
                  </div>
                  <div className='flex items-center'>
                    <div className='w-[50%] flex justify-end ml-[20px] text-[12px] text-[#9ea0a3]'><span > Cashed out:</span></div>
                    <div className='ml-[7px] px-[11px] py-[2px] rounded-[11px] bg-[#00000080] font-bold text-[#c017b4]'> 8000.00x</div>
                  </div>
                  <div className='flex items-center'>
                    <div className='w-[50%] flex justify-end ml-[20px] text-[12px] text-[#9ea0a3]'><span >Win INR:</span></div>
                    <span className="text-[14px] text-[#fff] ml-[5px]">  9646.06  </span>
                  </div>

                  {/* ** */}

                </div>
              </div>
              {/* ** */}
              <img src='/assets/aviator-game/info-page/right_green_icon.svg' alt="right_icon" className='absolute right-0 top-0 min-w-[14px] max-w-[14px] h-[16px] cursor-pointer mr-[10px] mt-[10px]' />
              {/*  */}
            </div>
            {/* footer card */}
            <div className='h-[28px] bg-[#000000] flex justify-between items-center'>
              <div className='flex'>
                <div>
                  <span className='text-[12px] text-[#9ea0a3] ml-[10px] mr-[20px]'>27  Mar </span>
                </div>
                <div className='flex items-center'>
                  <div className='w-[50%] flex justify-end ml-[20px] text-[12px] text-[#9ea0a3]'><span >Round:</span></div>
                  <span className="text-[14px] text-[#fff] ml-[5px]">   8047.62x </span>
                </div>
              </div>

              <div className='w-[50px] h-[20px] rounded-[17px] border border-[#414148] bg-[#252528] cursor-pointer mr-[5px] flex justify-center items-center'>
                <img src='/assets/aviator-game/info-page/share_copy_icon.svg' alt="share_copy_icon" className='w-[34px] h-[14px]' />

              </div>

            </div>

          </div>
        )
      })}


    </div>
  )
}

export default HugeWinsBatList