import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_PREVIOUS_FLAG, SelectPreviousHandFlag } from '../../../../../redux/AviatorGameSlice'

function MyBetsComponent() {
  const dispatch = useDispatch()
  const previousHandFlag = useSelector(SelectPreviousHandFlag)
  return (
    <div className='flex flex-col h-[100%] min-h-0'>

      <div className='flex h-[100%] flex-col'>


        {/* spacer */}
        <div className='h-[2px] bg-[#141516]'></div>
        {/* table header */}
        <div className='text-[11px] text-[#7b7b7b] h-[20px] flex items-center justify-between px-[10px]'>
          <div className='min-w-[48px] max-w-[48px]'>Date</div>
          <div className='w-[100%] flex'>
            <span className='min-w-[42%] pr-[10px] text-right whitespace-nowrap'> Bet INR </span>
            <span> X </span>
            <span className='flex-1 text-right whitespace-nowrap pr-[10px]'>  Cash out INR  </span>
          </div>
          <div className='min-w-[35px]'></div>
          {/* <span className='w-[19%]'>Date</span>
          <span className='min-w-[35%] pr-[10px] text-right whitespace-nowrap'> Bet INR </span>
          <span> X </span>
          <span className='flex-1 text-right whitespace-nowrap'>  Cash out INR  </span> */}
        </div>
        {/* ----end header----- */}

        {/* content */}

        <div className='scroll-restyle h-[88%] my-[5px] ml-[5px] overflow-y-scroll'>
          {[1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 2, 6].map((number, index) => {
            return (
              <div key={index} className='min-w-[100%]'>
                <div className={`bet_item  flex items-center ${number === 2 && 'active_bet_item'}`}>

                  <div className="text-[13px] text-[#9ea0a3] pl-1">14:19</div>
                  <div className='w-[100%] flex items-center'>
                    <div className={`min-w-[42%] ${number !== 2 && 'text-[#9ea0a3]'} pr-[10px] text-right whitespace-nowrap`}>100.00</div>
                    <div className="bubble-multiplier font-bold px-[11px] py-[2px] rounded-[11px] text-[#34b4ff] bg-[#00000080]"> 1.32x</div>
                    <div className='flex-1 text-right whitespace-nowrap pr-[10px]'>8.19</div>
                  </div>

                  <div className='min-w-[40px] flex items-center pr-[5px]'>
                    <img src='/assets/aviator-game/info-page/right_green_icon.svg' alt="right_icon" className='w-[14px] h-[16px] cursor-pointer' />
                    <img src='/assets/aviator-game/info-page/share_icon.svg' alt="share_icon" className='w-[14px] h-[16px] ml-[5px] cursor-pointer' />
                  </div>
                </div>
              </div>
            )
          })}

        </div>

      </div>

    </div>
  )
}

export default MyBetsComponent