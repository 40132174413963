import { createSlice } from "@reduxjs/toolkit";


export const SelectActiveGameBaseUrl = ({ socketStateSlice }) => socketStateSlice.ACTIVE_GAME_BASE_URL



const socketStateSlice = createSlice({
    name: "socketStateSlice",
    initialState: {
        ACTIVE_GAME_BASE_URL : null
    },
    reducers: {
        SET_ACTIVE_GAME_BASE_URL: (state, { payload }) => { state.ACTIVE_GAME_BASE_URL = payload },
    }
})





export const {
    SET_ACTIVE_GAME_BASE_URL

} = socketStateSlice.actions;

export default socketStateSlice.reducer;