import React, { useEffect, useState } from 'react'
import "./batButton.css"
import { SocketState } from '../../../../../context/SocketProvider';
import { useSelector, useDispatch } from 'react-redux';
import { SelectNextRoundFlag, SelectPlaceBatFlag } from '../../../../../redux/comanGameInfoSlice';
import { RESET_BAT_SUCESS_RESPONSE, SET_PANDING_BAT, SelectBatSucessResponse, SelectFlyPlan, SelectFlyTimer, SelectPandingBat, SelectShowCashOutBtn, SelectUpdateCrashReward } from '../../../../../redux/AviatorGameSlice';

function BatButton({index}) {


    const dispatch = useDispatch()
    const { socket } = SocketState();
    let placeBatFlag = useSelector(SelectPlaceBatFlag)
    let nexRoundFlag = useSelector(SelectNextRoundFlag)
    let flyPlan = useSelector(SelectFlyPlan)
    // let pandingBat = useSelector(SelectPandingBat)
    let showCashoutBtn = useSelector(SelectBatSucessResponse)
    let flyTimer = useSelector(SelectFlyTimer)
    let updateCrashReward = useSelector(SelectUpdateCrashReward)

    const [activeInfoTab, setActiveInfoTab] = useState(0)
    const [batInputValue, setBatInputValue] = useState(20)
    const [autoCashInputValue, setAutoCashInputValue] = useState(1.10)
    const [autoCashOut, setAutoCashOut] = useState(false)
    const [autoBet, setAutoBet] = useState(false)
    const [cashOutDone, setCashoutDone] = useState(false)

    const [pandingBat, setpandingBat] = useState(false)


    useEffect(() => {
      
        console.log("pandingBat",pandingBat)
    }, [pandingBat])

    useEffect(() => {
        console.log('updateCrashReward: ', updateCrashReward);
      if(updateCrashReward >=autoCashInputValue && autoCashOut && activeInfoTab===1 && cashOutDone){
        
        setCashoutDone(false)
        cashOutBat()
      }else{
        if(updateCrashReward < autoCashInputValue){
            setCashoutDone(true)
        }
      }
    }, [updateCrashReward])
    

    useEffect(() => {
        // if (pandingBat[index] && placeBatFlag) {
        if (pandingBat && placeBatFlag) {
            cashInBet()
        } else {

        }
    }, [placeBatFlag, pandingBat])


    useEffect(() => {
      if(flyPlan && showCashoutBtn[index]){
            // let copyBatFlag = [...pandingBat]
            // copyBatFlag[index] = null
            // dispatch(SET_PANDING_BAT(copyBatFlag)) 
            setpandingBat(null)  
       
      }else{
        if(autoBet && !flyPlan){
            // let copyBatFlag = [...pandingBat]
            // copyBatFlag[index] = true
            // dispatch(SET_PANDING_BAT(copyBatFlag)) 
            setpandingBat(true)
        }
      }
    }, [flyPlan])

    
    


    let batValueDecrease = () => {
        if (batInputValue > 20) {
            setBatInputValue(batInputValue - 1);
        } else {
            setBatInputValue(20);
        }
    }

    let checkBatInputValue = ()=>{
        if (batInputValue < 20) {
            setBatInputValue(20);
        }
    }
    

    let batValueIncrease = (val) => () => {
        setBatInputValue(batInputValue + val)
    }


    let placeBat = () => {
        if (placeBatFlag) {
            cashInBet()
        } else {
            
            updatePandingFlag(true)
        }
    }
    


    let CancelPandingBat = () => {
        
        updatePandingFlag(false)
    }



    let updatePandingFlag = (val)=>{
        
        // let copyPaddingBatFlag = [...pandingBat]
        // copyPaddingBatFlag[index] = val
        // dispatch(SET_PANDING_BAT([...copyPaddingBatFlag]))
        setpandingBat(val)
    }

    let cashInBet = () => {
        let dataObj = {
            amount: batInputValue,
            flee_condition: autoCashOut ? autoCashInputValue : 0,
            profit_on_stop: 0,
            loss_on_stop: 0,
            mode: 'auto',
            button_index:index

        }
        let payload = {
            en: "CRASH_CASH_IN",
            data: activeInfoTab === 1 ? dataObj : { amount: batInputValue, button_index:index }
        };
        socket?.emit("req", payload);
        
    }

    let cashOutBat = () => {
        let payload =  {
            "en" : "CRASH_CASH_OUT",
            "data" : {
                "time":updateCrashReward.toFixed(2),
                button_index:index
            }
        }
        socket?.emit("req", payload);
        let copyBatSuccessResponse = [...showCashoutBtn]
        copyBatSuccessResponse[index] = null
        dispatch(RESET_BAT_SUCESS_RESPONSE([...copyBatSuccessResponse]))

        if(autoBet){
            // let copyBatFlag = [...pandingBat]
            // copyBatFlag[index] = true
            // dispatch(SET_PANDING_BAT(copyBatFlag)) 
            setpandingBat(true)
        }

    }

    let disabledBtn = () => {
        // if (pandingBat[index] && (autoBet && activeInfoTab===1)) {
        if (pandingBat && (autoBet && activeInfoTab===1)) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <div className='mb-auto flex justify-center items-center mt-[10px]  min-h-[24px] max-h-[24px] text-[12px]'>
                {/* navigation switcher */}
                <div className='h-[100%] bg-[#141516] rounded-[10px] border border-[#141516] relative'>
                    <div className={`w-[100px] h-[100%] absolute rounded-[10px] bg-[#2c2d30]`} style={{ transform: `translate(${activeInfoTab * 100}px)`, transition: 'transform .5s ease' }} />
                    {['Bet', 'Auto'].map((tab, index) => {
                        return (
                            <button key={index}
                                className={`w-[100px] ${activeInfoTab === index ? 'text-[#fff]' : 'text-[#9ea0a3]'} h-[100%] relative text-center bg-none border-none p-0`}
                                onClick={() => setActiveInfoTab(index)}
                                disabled={disabledBtn()}
                            >{tab}</button>
                        )
                    })}
                </div>
            </div>

            <div className='mb-auto w-[calc(100%_-_20px)] max-w-[390px] flex justify-center'>
                <div className='flex flex-col justify-center items-center text-center w-[140px]'>
                    {/* 1 */}
                    <div className='h-[34px] text-[18px] w-[140px]'>
                        <div className='h-[100%] flex justify-between items-center rounded-[22px] bg-[#000000b3] font-bold text-inherit px-[10px]'>
                            <div className='inline-flex items-center'>
                                <button onClick={batValueDecrease} disabled={disabledBtn()}><img src="/assets/aviator-game/bat-button/minus.svg" alt="minus" /></button>
                            </div>
                            <div className='flex items-center w-[calc(100%_-_35px)] h-[100%]'>
                                <input type='number' disabled={disabledBtn()} value={batInputValue} min={20} onBlur={checkBatInputValue} onChange={(e) => setBatInputValue(e.target.value)} className='w-[100%] bg-transparent text-[#fff] p-[0] text-center' />
                            </div>
                            <div className='inline-flex items-center'>
                                <button onClick={batValueIncrease(1)} disabled={disabledBtn()}><img src="/assets/aviator-game/bat-button/plus.svg" alt="minus" /></button>
                            </div>
                        </div>
                    </div>
                    {/* 2 */}
                    <div className='mt-1'></div>
                    <div className='flex justify-center flex-wrap text-[14px] text-[#9ea0a3] w-[100%]' >
                        {[20, 50, 100,200,500,1000,5000,20000].map((number, index) => {
                            return <button key={index} onClick={batValueIncrease(number)} disabled={disabledBtn()} className='text-[14px] w-[64px] h-[18px] mt-[2px] bg-[#141516] border-none p-0 rounded-[100px]'><span className='text-[#ffffff80;]'>{number}</span></button>
                        })}

                    </div>
                </div>
                {/* button blocks */}
                <div className='w-[calc(100%_-_145px)] ml-[5px] flex justify-center items-center flex-col '>
                    {/* {(pandingBat[index])   ? */}
                    {(pandingBat)   ?
                        <div className='w-full h-full flex flex-col items-center'>
                            {nexRoundFlag && <p className='text-[13px] text-white'>Waiting for next round</p>}
                            <button className='nextRoundButton mt-[2px]' onClick={CancelPandingBat}>
                                <span className='flex flex-col justify-center items-center'>
                                    <p className='inline-block m-0 text-[20px]'>CANCEL</p>

                                </span>
                            </button>
                        </div>
                        :

                        <>
                        {(showCashoutBtn[index] && flyPlan) ?
                            <button className='cashoutButton' onClick={cashOutBat}>
                            <span className='flex flex-col justify-center items-center'>
                                <p className='inline-block m-0 text-[20px]'>CASH OUT</p>
                                <p className='inline-block m-0 text-[18px]'><p className='text-[24px] inline-block'>{(batInputValue + updateCrashReward).toFixed(2)  }</p> INR</p>

                            </span>
                        </button>
                        :
                        
                        <button className='betButton' onClick={placeBat}>
                            <span className='flex flex-col justify-center items-center'>
                                <p className='inline-block m-0 text-[20px]'>BET</p>
                                <p className='inline-block m-0 text-[18px]'><p className='text-[24px] inline-block'>{batInputValue}.00</p> INR</p>

                            </span>
                        </button>
                        }
                        </>
                    }
                </div>


            </div>


            {activeInfoTab === 1 &&
                <div className='mb-auto flex w-[100%] max-w-[390px] justify-between'>
                    <div className='w-[140px]  flex justify-start items-center'>
                        <p className='text-[12px] text-white mr-1'>Auto Bet</p>
                        <Switch checked={autoBet} onChange={(e) => {
                            placeBat()
                            setAutoBet(e.target.checked)

                        }
                        } />

                    </div>
                    <div className='w-[calc(100%_-_145px)] flex justify-center items-center'>
                        <p className='text-[12px] text-white mr-1'>Auto Cash Out</p>
                        <Switch checked={autoCashOut} onChange={(e) => setAutoCashOut(e.target.checked)}  />
                        <div className=' ml-4 w-[100px] h-[34px] flex justify-between items-center rounded-[22px] bg-[#000000b3] font-bold text-inherit px-[10px]'>

                            <div className='flex items-center w-[calc(100%_-_35px)] h-[100%]'>
                                <input disabled={disabledBtn()} type='number' value={autoCashInputValue} onChange={(e) => setAutoCashInputValue(e.target.value)} className='text-[18px] w-[100%] bg-transparent text-[#fff] p-[0] text-center' />
                            </div>
                            <div className='inline-flex items-center'>
                                <p className='text-[16px] text-[#FFFFFF]'>x</p>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}


let Switch = ({ checked, onChange, disabled }) => {
    return (
        <label className='h-[24px] relative inline-block'><input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} /></label>
    )
}


export default BatButton