import React, { useState } from "react";
import styled from "./Login.module.css";
import Tab from "./Tab";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import "./loginPageComan.css"

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState("Login");
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  return (
    <>
      <div className={styled.login_container_tab}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            onClick={() => handleTabClick(tab.name)}
            isActive={tab.name === activeTab}
          />
        ))}
      </div>
      <div className={styled.login__container_form}>
        <div
          className={`${styled.tab_content} ${
            activeTab == "Login" ? styled.activecontent : ""
          }`}
        >
          <LoginPage />
        </div>
      
      {/* Register */}

        <div
          className={`${styled.tab_content} ${
            activeTab == "Register" ? styled.activecontent : ""
          }`}
        >
         <RegisterPage />
        </div>
      </div>
    </>
  );
};

export default Tabs;
