import "swiper/css";
// import "../index.css";
import { Swiper, SwiperSlide } from "swiper/react";

const Sildes = () => {
  return (
    <>
      <Swiper className="mySwiper">
        <SwiperSlide className="my-swipe">
          <img src="images/Banner_20231125140847kw1d.png" />
        </SwiperSlide>
        <SwiperSlide className="my-swipe">
          <img src="images/Banner_20240110082959xdnc.png" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Sildes;
