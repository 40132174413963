import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthState } from '../context/AuthProvider';

const AuthGuard = ({ component }) => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();

  const { userDetail } = AuthState()

  useEffect(() => {
    checkToken();
  }, [component]);

  const checkToken = async () => {
    if (!userDetail) {
      // localStorage.removeItem("token")
      navigate(`/color`);
    } else {
      setStatus(true);
    }

  }


  return status ? <React.Fragment>{component}</React.Fragment> : <React.Fragment></React.Fragment>;
}

export default AuthGuard;