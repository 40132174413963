import { configureStore } from "@reduxjs/toolkit";
import errorMessageSlice from "./errorMessageSlice";
import loaderSlice from "./loaderSlice";
import successMessageSlice from "./successMessageSlice";
import socketStateSlice from "./socketStateSlice"
import AviatorGameSlice from "./AviatorGameSlice"
import AviatorCanvasSlice from "./AviatorCanvasSlice"
import registerPageSlice from "./registerPageSlice"
import comanGameInfoSlice from "./comanGameInfoSlice"
import colorGameSlice from "./colorGameSlice"


export default configureStore({
    reducer: {
        loaderSlice,
        errorMessageSlice,
        successMessageSlice,
        socketStateSlice,
        AviatorGameSlice,
        AviatorCanvasSlice,
        registerPageSlice,
        comanGameInfoSlice,
        colorGameSlice
        },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})