import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import { RESET_CASH_OUT_SUCESS_RESPONSE, SelectCashOutSuccessResponse, SelectRestCashOutIndex } from '../../redux/AviatorGameSlice'

function AvaiatorBatWinPopup() {
    let dispatch = useDispatch()
    let cashoutRes = useSelector(SelectCashOutSuccessResponse)
    let resetIndex = useSelector(SelectRestCashOutIndex)
    
    

    useEffect(() => {
      if(resetIndex >=0){
        dispatch(RESET_CASH_OUT_SUCESS_RESPONSE({button_index:resetIndex}))
      }
    }, [resetIndex])
    


    return (
        <div className='absolute top-0 w-full flex flex-col justify-center items-center py-3 z-50'>
            {cashoutRes.map((res, index) => {
                if(res){

                
                return (
                    <div key={res.ticket_id} className='w-[300px] h-[56px] border border-solid bg-[#123405] border-[#427f00] flex justify-between items-center  mb-[3px] rounded-3xl'>
                        <div className='flex flex-col justify-center items-center w-full h-[90%] px-2'>
                            <p className='text-[12px] text-[#9ea0a3] text-center'> You have cashed out! </p>
                            <p className='text-[20px] mt-[3px] text-white text-center'> {res?.total_win_amount}x </p>
                        </div>
                        <div className='flex flex-col justify-center w-full h-[85%] px-2 bg-[#4eaf11] rounded-3xl'>
                            <p className='text-[14px] text-white text-center'>Win INR</p>
                            <p className='text-[20px] text-white text-center'>{res?.total_win_amount}</p>
                        </div>

                        <div className='flex justify-center items-center  px-[10px]'>
                            <button className='text-[20px] text-[#9ea0a3]'>X</button>
                        </div>
                    </div>


                )
            }
            return null
            })}

        </div>
    )
}

export default AvaiatorBatWinPopup