import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_PREVIOUS_FLAG, SelectCashInInfoUser, SelectPreviousHandFlag } from '../../../../../redux/AviatorGameSlice'

function AllBetsComponent() {
  const dispatch = useDispatch()
  const previousHandFlag = useSelector(SelectPreviousHandFlag)
  let CashInInfoUser = useSelector(SelectCashInInfoUser)
  return (
    <div className='flex flex-col h-[100%] min-h-0'>

      <div className='flex h-[100%] flex-col'>

        {/* all Bets Header */}
        <div className='text-[14px] px-[10px] flex justify-between items-center pb-[5px]'>
          {/* bets count */}
          <div className='text-[#fff]'>
            <div>ALL BETS</div>
            <div>679</div>
          </div>
          {/* previous hand */}
          <div>
            <div className={`previousContainer h-[20px] text-[12px] p-[0px 8px 0px 3px] whitespace-nowrap text-[#9ea0a3] rounded-[17px] border  border-[#414148] bg-[#252528] cursor-pointer flex justify-between items-center px-[5px] ${previousHandFlag && 'border-[#e90000] bg-[#600]'}`}
              onClick={() => {
                dispatch(SET_PREVIOUS_FLAG(!previousHandFlag))
              }}>
              {previousHandFlag ?
                <img src='/assets/aviator-game/info-page/close_icon.svg' alt='close icon' className='close_icon w-[12px] h-[12px] ' /> :
                <>
                  <img src='/assets/aviator-game/info-page/refresh_icon.svg' alt='refresh icon' className='refresh_icon w-[12px] h-[12px]' />
                  <img src='/assets/aviator-game/info-page/refresh_icon_red.svg' alt='refresh icon' className='refresh_icon_red  w-[12px] h-[12px]' />
                </>
              }
              <span className='ml-[5px]'>Previous hand</span>
            </div>
          </div>

        </div>
        {/* spacer */}
        <div className='h-[2px] bg-[#141516]'></div>
        {/* table header */}
        <div className='text-[#7b7b7b] h-[20px] flex items-center justify-between mx-[10px] mt-[1px] text-[11px]'>
          <span className='w-[19%]'>User</span>
          <span className='min-w-[35%] pr-[10px] text-right whitespace-nowrap'> Bet INR </span>
          <span> X </span>
          <span className='flex-1 text-right whitespace-nowrap'>  Cash out INR  </span>
        </div>
        {/* ----end header----- */}

        {/* content */}

        <div className='scroll-restyle h-[88%] my-[5px] ml-[5px] overflow-y-scroll'>
          {CashInInfoUser.map((user, index) => {
            return (
              <div key={index} className='min-w-[100%]'>
                {/* <div className={`bet_item  flex items-center ${number === 2 && 'active_bet_item'}`}> */}
                <div className={`bet_item  flex items-center ${user.crash_reward > 0 && 'active_bet_item'}`}>
                  <div className="w-[19%] flex items-center">
                    <img className="w-[30px] h-[30px] mx-[5px] " src="assets/av-22.png?v=v4.2.27-hotfix-2" alt="assets/av-22.png" />
                    <div className="text-[13px] text-[#9ea0a3]">{user.user_name}</div></div>
                  <div className='min-w-[35%] pr-[10px] text-right whitespace-nowrap'>{user.bet}.00</div>
                 {user.crash_reward >0 && <div className="bubble-multiplier font-bold px-[11px] py-[2px] rounded-[11px] text-[#34b4ff] bg-[#00000080]"> {user.crash_reward || ''}</div>}
                  {user.win_amount >0 && <div className='flex-1 text-right whitespace-nowrap mr-[5px]'>{user.win_amount.toFixed(2) || ''}</div>}
                </div>
              </div>
            )
          })}
          {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2, 3, 4, 5, 2, 6, 73, 2, 5, 6, 7, 8, 4,].map((number, index) => {
            return (
              <div key={index} className='min-w-[100%]'>
                <div className={`bet_item  flex items-center ${number === 2 && 'active_bet_item'}`}>
                  <div className="w-[19%] flex items-center">
                    <img className="w-[30px] h-[30px] mx-[5px] " src="assets/av-22.png?v=v4.2.27-hotfix-2" alt="assets/av-22.png" />
                    <div className="text-[13px] text-[#9ea0a3]">d***{index}</div></div>
                  <div className='min-w-[35%] pr-[10px] text-right whitespace-nowrap'>100.00</div>
                  <div className="bubble-multiplier font-bold px-[11px] py-[2px] rounded-[11px] text-[#34b4ff] bg-[#00000080]"> 1.32x</div>
                  <div className='flex-1 text-right whitespace-nowrap mr-[5px]'>156.00</div>
                </div>
              </div>
            )
          })} */}


        </div>

      </div>

    </div>
  )
}

export default AllBetsComponent