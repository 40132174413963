import { useSelector, useDispatch } from "react-redux"
import { SET_HISTORY_RECORD_STATE, SET_MY_HISTORY_RECORD_STATE, SelectColorActiveGame, SelectHistoryRecord } from "../../../redux/colorGameSlice";
import { useEffect, useState } from "react";
import { SocketState } from "../../../context/SocketProvider";


const GameRecord = () => {
  let dispatch = useDispatch()
  let activeGame = useSelector(SelectColorActiveGame)
  const { socket } = SocketState()
  let { game_lists, total_page, page } = useSelector(SelectHistoryRecord)
  
  
  

  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (index === 0) {
      fetchMetalyHistory(1)
    } else if (index === 1) {

    } else if (index === 2) {
      fetchMyMetalyHistory(1)
    }
  }, [index])


  let nextPage =()=>{
    if (index === 0) {
      fetchMetalyHistory(page+1)
    } else if (index === 1) {

    } else if (index === 2) {
      fetchMyMetalyHistory(page+1)
    }
  }

  let previewPage = ()=>{
    if (index === 0) {
      fetchMetalyHistory(page-1)
    } else if (index === 1) {

    } else if (index === 2) {
      fetchMyMetalyHistory(page-1)
    }
  }

  let fetchMetalyHistory = (total_page) => {
    socket.emit('req', {
      "en": `${activeGame}_SHOP_METALY_HISTORY`,
      "data": {
        "page": total_page || 1
      }
    })
  }

  let fetchMyMetalyHistory = (total_page) => {
    socket.emit('req', {
      en: `${activeGame}_SHOP_MY_METALY_HISTORY`,
      data: {
        page: total_page || 1,
      },
    });

  }



  return (
    <>
      <div className="RecordNav__C">
        {['Game history', 'Chart', 'My history'].map((histoyName, historyIndex) => {
          return (
            <div key={historyIndex} className={historyIndex === index && 'active'} onClick={() => setIndex(historyIndex)}>{histoyName}</div>

          )
        })}
      </div>


      {game_lists?.length ?
        <div className="GameRecord__C">
          <div className="GameRecord__C-head">
            <div className="van-row">
              <div className="van-col van-col--8">Period</div>
              <div className="van-col van-col--5">Number</div>
              <div className="van-col van-col--5">Big Small</div>
              <div className="van-col van-col--6">Color</div>
            </div>
          </div>


          <div className="GameRecord__C-body">

            {game_lists?.map((gamelist, listIndex) => {
              // let resultReplace = gamelist?.result_card ? gamelist.result_card.replace("violet", "#b659fe"): Object.keys(gamelist.card_details).join('').replace("violet", "#b659fe") ;
              let resultReplace = gamelist.result_card.replace("violet", "#b659fe");
              let splitData = resultReplace?.split(' ') || ['', '', '',''];
              

              let colorArray = splitData.slice(2, 4)
              

              return (
                <div className="van-row" key={gamelist._id} >
                  <div className="van-col van-col--8">{gamelist.game_id}</div>
                  <div className="van-col van-col--5 numcenter">
                    <p className="GameRecord__C-body-num defaultColor" style={{
                      background: `linear-gradient(to bottom, ${colorArray[0]} 50%, ${colorArray[1] ? colorArray[1] : colorArray[0]} 50%)`, WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }} >{splitData.slice(0, 1)}</p>
                  </div>
                  <div className="van-col van-col--5">
                    <span>{splitData.slice(1, 2)}</span>
                  </div>
                  <div className="van-col van-col--6">
                    <div className="GameRecord__C-origin">
                      {splitData.slice(2, 4).map((color, index) => {
                        return <div key={index} className="w-[15px] h-[15px] rounded-[40px]" style={{ backgroundColor: color }}></div>
                      })}
                    </div>
                  </div>
                </div>
              )
            })}

          </div>



          <div className="GameRecord__C-foot">
            <button disabled={page <= 1} className="GameRecord__C-foot-previous" onClick={previewPage}>
              <i
                className="van-badge__wrapper van-icon van-icon-arrow-left GameRecord__C-icon"
                style={{ fontSize: "20px" }}
              ></i>
            </button>
            <div className="GameRecord__C-foot-page">{page}/{total_page}</div>
            <button disabled={page >= total_page} className="GameRecord__C-foot-next" onClick={nextPage}>
              <i
                className="van-badge__wrapper van-icon van-icon-arrow GameRecord__C-icon"
                style={{ fontSize: "20px" }}
              ></i>
            </button>
          </div>
        </div>
        :

            <div className="GameRecord__C">

        <div className="MyGameRecord__C">
          <div  className="MyGameRecord__C-head">
            <div  className="MyGameRecord__C-head-moreB">
              Detail
            </div>
          </div>
          <div  className="MyGameRecord__C-body">
            <div  className="MyGameRecord__C-body-empty">
              <div   className="empty__container">
                <img
                  
                  alt=""
                  className=""
                  data-origin="https://www.bdggame.in/assets/png/empty-ea102850.png"
                  src="https://www.bdggame.in/assets/png/empty-ea102850.png"
                />
                <p >No data</p>
              </div>
            </div>
          </div>
        </div>
        </div>
        
      }

    </>

  );
};

export default GameRecord;
