import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from '@hookform/resolvers/yup';

import { SET_LOGIN_FORM_ERROR, SelectLoginFormError } from '../../../../redux/errorMessageSlice';
import { SET_MAIN_LOADER } from '../../../../redux/loaderSlice';
import { SocketState } from '../../../../context/SocketProvider';
import styled from "./Login.module.css";


const schema = yup.object().shape({
  mobile_number: yup
    .string()
    .required('*You must enter a phone number.'),
  password: yup.string().required('*Please enter your password.'),
  remember: yup.boolean()
});

const defaultValues = {
  mobile_number: JSON.parse(localStorage.getItem('creditionlsDetails'))?.mobile_number || '',
  password: JSON.parse(localStorage.getItem('creditionlsDetails'))?.password || '',
  remember: JSON.parse(localStorage.getItem('creditionlsDetails'))?.remembr || false,
};

function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { socket } = SocketState();

  const LoginFormError = useSelector(SelectLoginFormError)

  const { control, formState, handleSubmit, setError, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const watchRemember = watch("remember")

  const { isValid, dirtyFields, errors } = formState;

  const [passWordShow, setPassWordShow] = useState(false)

  // useEffect(() => {
  //   onSubmit({ mobile_number: '0000012345', password: '12345', remember: true })
  // }, [])



  function onSubmit({ mobile_number, password, remember }) {
    console.log('mobile_number, password, remember: ', mobile_number, password, remember);

    setTimeout(() => {
      dispatch(SET_LOGIN_FORM_ERROR(null));
      dispatch(SET_MAIN_LOADER(true));
      // socket call
      let payload = {
        en: "LOGIN",
        data: {
          mobile_number,
          password,
        },
      };
      if (remember) {
        localStorage.setItem(
          "creditionlsDetails",
          JSON.stringify({ mobile_number, password, remember })
        );
      }
      localStorage.setItem(
        "creditionls",
        JSON.stringify({ mobile_number, password, remember })
      );
      socket?.emit("req", payload);
    });
  }


  return (
    <form name="loginForm" noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={styled.signIn__container}>
        <div className={styled.phoneInput__container}>
          <div className={styled.phoneInput__container_label}>
            <img
              src="/images/phoneActive.png"
              className={styled.phoneInput__container_label__icon}
            />
            <span>Phone number</span>
          </div>
          <div className={styled.phoneInput__container_input}>
            <div className={styled.dropdown}>
              <div className={styled.dropdown__value}>
                <span>+91</span>
                <i
                  className={`${styled.van_badge__wrapper} ${styled.van_icon} ${styled.van_icon_arrow_down}`}
                ></i>
              </div>
              <div className={styled.dropdown__list}>
                <div className={`${styled.dropdown__list_item} active`}>
                  <span>+91</span> India (भारत)
                </div>
              </div>
            </div>
            <Controller
              control={control}
              name="mobile_number"
              render={({ field }) => (
                <input
                  type="text"
                  name="mobile_number"
                  placeholder="Please enter the phone number"
                  {...field}
                />
              )}
            />


          </div>
          <ErrorMessage
            errors={errors}
            name="mobile_number"
            render={({ message }) => (
              <p className="text-red-500 text-[12px] mt-1">{message}</p>
            )}
          />
        </div>
        <div className={styled.passwordInput__container}>
          <div className={styled.passwordInput__container_label}>
            <img
              className={styled.passwordInput__container_label__icon}
              src="/images/password-b827c2b3.png"
            />
            <span>Password</span>
          </div>
          <div className={styled.passwordInput__container_input}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <input
                  type={passWordShow ? 'text' : 'password'}
                  placeholder="Password"
                  maxLength="32"
                  autoComplete="new-password"
                  {...field}
                />
              )}
            />
            <img
              src="https://bigdaddygame.in/assets/png/eyeInvisible-2fa5c152.png"
              className={styled.eye}
              onClick={() => setPassWordShow(!passWordShow)}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="text-red-500 text-[12px] mt-1">{message}</p>
            )}
          />
        </div>
        <div className={styled.signIn__container_remember}>
          <div
            role="checkbox"
            className={`${styled.van_checkbox} van-checkbox`}
            tabIndex="0"
            aria-checked="true"
          >
            <div className={`van-checkbox__icon van-checkbox__icon--round  `} //van-checkbox__icon--checked
            >

              {/* <i className={`van-badge__wrapper van-icon van-icon_success`} onClick={()=>setValue('remember',!watchRemember)}></i> */}
              {/* check */}
              <i className={`van-badge__wrapper van-icon van-icon_success ${styled.active_checkbox}`}></i>
            </div>
            <span
              className={`${styled.van_checkbox__label} van-checkbox__label`}
            >
              Remember password
            </span>
          </div>
        </div>
        <div className={styled.signIn__container_button}>
          <button className={styled.active} type='submit'>Log in</button>
          <button className={styled.register}>Register</button>
        </div>
        <div className={styled.signIn_footer}>
          <div className={styled.forgetcon}>
            <div className={styled.forgetbg}></div>
            <div className={styled.font24}>Forgot password</div>
          </div>
          <div className={styled.customcon}>
            <div className={styled.custombg}></div>
            <div className={styled.font24}>Customer Service</div>
          </div>
        </div>
        <div className="popups"></div>
      </div>
    </form>
  )
}

export default LoginPage