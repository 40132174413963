import styled from "./css/GameNavbar.module.css";
const GameNavbar = ({backScreen}) => {
  return (
    <div className={styled.navbar}>
      <div
        className={styled.navbar_fixed}
        style={{ background: "rgb(63, 63, 63)" }}
      >
        <div className={styled.navbar__content}>
          <div className={styled.navbar__content_left} onClick={backScreen}>
            <i
              className={`${styled.van_icon} van-badge__wrapper van-icon van-icon-arrow-left`}
            ></i>
          </div>
          <div className={styled.navbar__content_center}>
            <div
              className={styled.headLogo}
              style={{
                backgroundImage:
                  'url("https://ossimg.bdgadminbdg.com/IndiaBDG/other/h5setting_20240110060804ufv8.png")',
              }}
            ></div>
            <div className="navbar__content-title"></div>
          </div>
          <div className="navbar__content-right">
            <div className="WinGo__C-head-more">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameNavbar;
