import React from 'react'
import { useSelector } from 'react-redux'
import { SelectLastWinCard } from '../../../../redux/comanGameInfoSlice'

function LastWinCardHistory() {
  let lastWinCard = useSelector(SelectLastWinCard)


  let showTextColor = (showNumber) => {
    if (showNumber >= 2) {
      return "rgb(145, 62, 248)"
    } else if (showNumber >= 10) {
      return "rgb(192, 23, 180)"
    } else {
      return "rgb(52, 180, 255)"
    }
  }
  return (
    <>
      {lastWinCard.map((card, index) => {
        let [winX] = card.split('|')
        return <div style={{ color: showTextColor(winX) }} className='px-[11px] py-[2px] bg-[#00000080] rounded-[11px] mr-[4px] ml-[1px] text-[12px] font-semibold'>{winX}x</div>
      })}
    </>
  )
}

export default LastWinCardHistory
