import { useState } from "react";
import { SET_ACTIVE_GAME_BASE_URL } from "../../../redux/socketStateSlice";
import { AviatorPreload } from "../../preload-game-state/AviatorPreload";
import {useDispatch, useSelector} from "react-redux"
import { SET_MAIN_LOADER, SelectGameIconList } from "../../../redux/loaderSlice";
import { SocketState } from "../../../context/SocketProvider";
import { useNavigate } from "react-router-dom";
import { winGoPreload } from "../../preload-game-state/wingoPreload";



const GameMenu = () => {
  const dispatch = useDispatch()
  const {socket} = SocketState()
  const navigate = useNavigate()

  const GameIcon = useSelector(SelectGameIconList)


  let openGame = (game)=>()=>{
    setTimeout(() => {
      dispatch(SET_ACTIVE_GAME_BASE_URL(game.socketBaseUrl))
      game.preloadFunction(dispatch).then((res)=>{
        dispatch(SET_MAIN_LOADER(true))
        socket.emit('req', { "en": `${game.socketBaseUrl}GAME_INFO`, "data": {} })
        navigate(game.navigationPath)
      })
      localStorage.setItem("gameObj",JSON.stringify(game))
    });
  }


  return (
    <div class="game_menu">
      <div class="menu_box3">
      {GameIcon.map((icon,index)=>{
        return(
        <div key={index} onClick={openGame(icon)}>
          <img
            alt="game_icon"
            data-origin="public/images/gamecategory_202401100619315n2k.png"
            src={icon.gameImage}
          />
          <span>{icon.gameTitle}</span>
        </div>
        )

      })}
        
        
      </div>
    </div>
  );
};

export default GameMenu;
