import React from 'react'
import { SelectMainLoader } from '../../redux/loaderSlice'
import { useSelector } from 'react-redux'

function Loader() {

  let mainLoader = useSelector(SelectMainLoader)

  if (mainLoader) {
    return (
      <div className='fixed top-0 left-0 flex justify-center items-center z-[1056] h-screen w-screen bg-black bg-opacity-70'>
        <img src='assets/loader/loader1.gif' alt='gif' className='w-[12%]' />
      </div>
    )
  }


  return null



}

export default Loader