import styled from "./css/Navbar.module.css";
const Navbar = () => {
  return (
    <div className={styled.navbar}>
      <div
        className={styled.navbar_fixed}
        style={{ background: "rgb(63, 63, 63)" }}
      >
        <div className={styled.navbar__content}>
          <div className="navbar__content-left">
            <i className="van-badge__wrapper van-icon van-icon-arrow-left"></i>
          </div>
          <div className="navbar__content-center">
            <div
              className="headLogo"
              style={{
                backgroundImage:
                  'url("https://ossimg.bdgadminbdg.com/IndiaBDG/other/h5setting_20240110060804ufv8.png")',
              }}
            ></div>
            <div className="navbar__content-title"></div>
          </div>
          <div className="navbar__content-right">
            <div>
              <div className="right">
                <div className="img">
                  <img className="" src="./public/images/en-4c6eba8e.png" />
                </div>
                <span className="lang-text">EN</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
