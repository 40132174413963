import "./Withdraw.css";
const Withdraw = () => {
  return (
    <>
      <div class="withdraw__container">
        <div class="navbarWithdraw">
          <div
            class="navbarWithdraw-fixed"
            style={{ background: "rgb(63, 63, 63)" }}
          >
            <div class="navbarWithdraw__content">
              <div class="navbarWithdraw__content-left">
                <i class="van-badge__wrapper van-icon van-icon-arrow-left"></i>
              </div>
              <div class="navbarWithdraw__content-center">
                <div class="navbarWithdraw__content-title">Withdraw</div>
              </div>
              <div class="navbarWithdraw__content-right">
                <span>Withdrawal history</span>
              </div>
            </div>
          </div>
        </div>
        <div class="withdraw__container-content">
          <div class="balanceAssets">
            <div class="balanceAssets__header">
              <div class="balanceAssets__header__left">
                <img src="/images/wallet.png" />
                Available balance
              </div>
            </div>
            <div class="balanceAssets__main">
              <p>₹0.00</p>
              <img src="/images/refresh.png" alt="" />
            </div>
            <div class="balanceAssets__tip">
              <img src="/images/cip-7e500e5e.png" alt="" />
            </div>
          </div>
          <div class="withdrawWay">
            <div class="selectW">
              <div>
                <img src="https://ossimg.bdgadminbdg.com/IndiaBDG/payNameIcon/WithBeforeImgIcon2_20240110091444bekj.png" />
              </div>
              <span> BANK CARD</span>
            </div>
          </div>
          <div class="addWithdrawType" style={{}}>
            <div class="addWithdrawType-top">
              <img src="/images/plus.png" />
              <span>Add a bank account number</span>
            </div>
            <div class="addWithdrawType-text">
              Need to add beneficiary information to be able to withdraw money
            </div>
          </div>
          <div class="explain" style={{}}>
            <div class="input">
              <div class="place-div">₹</div>
              <input placeholder="Please enter the amount" class="inp" />
            </div>
            <div class="balance bank">
              <div>
                <span>
                  Withdrawable balance <h6 class="red">₹0.00</h6>
                </span>
                <input type="button" value="All" />
              </div>
              <div>
                <span>Withdrawal amount received</span>
                <div class="rightD">
                  <span class="yellow">₹0.00</span>
                </div>
              </div>
            </div>
          </div>
          <div class="recycleBtnD">
            <button class="recycleBtn">Withdraw</button>
          </div>
          <div class="Recharge__container-intro">
            <div>
              <p>
                Need to bet <span class="red">₹0.00</span> to be able to
                withdraw
              </p>
              <p>
                Withdraw time <span class="red">00:00-23:59</span>
              </p>
              <p>
                Inday Remaining Withdrawal Times
                <span class="red">3</span>
              </p>
              <p>
                Withdrawal amount range{" "}
                <span class="red">₹110.00-₹50,000.00</span>
              </p>
              <p>
                Please confirm your beneficial account information before
                withdrawing. If your information is incorrect, our company will
                not be liable for the amount of loss
              </p>
              <p>
                If your beneficial information is incorrect, please contact
                customer service
              </p>
            </div>
          </div>
          <div class="rechargeh__container">
            <div class="rechargeh__container-head">
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgB7dnNDYJAEAXggXAhXixBT7QBDUiM3m3FEixETahA2yAx0SYMJ0C8GlecBfZlk/edSPjbl8ns8iNCNEhg2nE7r3d10xy6zbm4G80j2RRLzSmhaUfdNntxOfi3VhaiFPVdLNkWgThQHvNWLITiOQZAi5THS3nK7zbN9vf1v/XCj9lJX4EJB29zT/YAGgOgMQAaA6AxAJr6WajPpbhK9axU58SzWLI8FRvjV8Dm9WfAK9PoFchWqbjEJkZjADSuA5+4DihxHUBjADQGQOudhWy/27tirkD3QVU8oF5CUBUx/SliE6MxAJo+AGJ28mRGJC+9AJ8KNFKq/j7kAAAAAElFTkSuQmCC" />
              <h1>Withdrawal history</h1>
            </div>
            <div class="rechargeh__container-content">
              <div class="empty__container">
                <img
                  alt=""
                  class=""
                  data-origin="https://bdg1.in/assets/png/empty-ea102850.png"
                  src="https://bdg1.in/assets/png/empty-ea102850.png"
                />
                <p>No data</p>
              </div>
            </div>
            <div class="rechargeh__container-footer">
              <button>All history</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
