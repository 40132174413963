import "./Recharge.css";

import { useSelector, useDispatch } from "react-redux"
import { SelectUserWallet } from "../../../../redux/comanGameInfoSlice";
import { useState } from "react";
import { SET_MAIN_LOADER } from "../../../../redux/loaderSlice";
import { SocketState } from "../../../../context/SocketProvider";

  const Recharge = () => {
  const dispatch = useDispatch()
  let walletAmount = useSelector(SelectUserWallet)
  const { socket } = SocketState();
    
  const [amount, setAmount] = useState()

  const [depositOptions, setDepositOptions] = useState([{label:'500',value:500},{label:'1K',value:1000},{label:'5K',value:5000},{label:'10K',value:10000},{label:'15K',value:15000},{label:'20K',value:20000}])

  const onPay = async () => {
    dispatch(SET_MAIN_LOADER(true))
    // Step 1: Create Order
   let data =  {
        "en" : "PROCESS_DEPOSIT_REQUEST",
        "data" : {
            "amount":amount
        }
    }
    socket.emit('req',data)
    console.log("enter amount socket call")

    

  };


  return (
    <div className="Recharge__box">
      <div className="Recharge__container">
        <div className="navbarR">
          <div className="navbarR-fixed" style={{ background: "rgb(63, 63, 63)" }}>
            <div className="navbarR__content">
              <div className="navbarR__content-left">
                <i className="van-badge__wrapper van-icon van-icon-arrow-left"></i>
              </div>
              <div className="navbarR__content-center">
                <div className="navbarR__content-title">Deposit</div>
              </div>
              <div className="navbarR__content-right">
                <div className="title">Deposit history</div>
              </div>
            </div>
          </div>
        </div>
        <div className="balanceAssets">
          <div className="balanceAssets__header">
            <div className="balanceAssets__header__left">
              <img src="/public/images/wallet.png" /> Balance
            </div>
          </div>
          <div className="balanceAssets__main">
            <p>₹{walletAmount}</p>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAwCAYAAAC4wJK5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANeSURBVHgB7ZhPUtswFMbfk2vKZBVuQJddQW+Q7LpMSDvTXcIJinuBhgvUcAJg1xk6JCy7Sm4Auy7xDfAGGpJa6pP8J05IQAr2NDPVbxPbkWJ90nufngJgsVgsFotl/UGTxv7exgl9dKAQRAiIAQgcwgYee99HAayIqYhb+qhCGSCcgssOVxFjJqK10QABu1AEAqvIYEcIUcs9DcBxm9753TUYYCSiDPzG5jYg79JI2smjkITUTYT8cxEpFKpd+via3AbAK++8fhjq9HVgTfj5Kxq+f+vISa2BzDucPMhnOn21VsJvVKvwarQL6ISm8WqK33o9gDhPQu9ivKXThz3X4FvLbQO7uwHOBxBNrmjZb/xPFMdlIfhlclX1P2zWdLo8KUImHQo4ogXL2+o2THhPrU4Z8Mnp9FpoOeFSEco1GM3+rIAYabPO/aAMIV4fZDKrhEYUOzp9lq+Ew3sgZ11BO2uCEHAWX5QnBBIRuiwU4e+5fm5TO6QRn6XffemNO7NCfvfAECn8JXk1P3GPRFDiklfjgbxGxCE5RHe+jRRCXw7VDTkJ7eQnYIJz14Mxv4nfZQa96wrY/S1NdCd9NiMicYNufIeBcHF/6a9FD00y6NhuBXTMhGR51jURMhsh0y0uE6ESmfN0ICFVlvWnijGVgNG4vpKQiDVzeaYlJB8hFAXX3sXoFOZFAItqME1krWoyFpIbkNAr070+/TbH+qyQ1wfL2vsfXTn73WRsAbjYzH8/FcEnfaosj6mR5108HIEm2YBUsqNn3C9zIuEftzbb8+1UhESYmsfCCNErO3LFGSV6oUWjmuUIaT+KzykMWYcLqmopKpDOGOSENZhGyMIJfrbsKBvvfHINjshWhATIQSpBdNZoQCYADpdFiKYIkW0+ZdRNSghjaZxXITs9xi5GK9JfZPUpeiKYM61cx8oACsf7MRrSixZYOll9VNl/qq+eiD8jKSJeDWRtKAllm4zV5czLjVYZjUzkZw5H2knqN90jWtfP6oZeFM/ceqCf2MKRSRXPCG2KpZ4pDNEWoXydNsHkdptqn8G6CDH2/LkDffx/EcAlRJWh7sG+aFbauB4JKZ6ALPWNbuOVd98F/xcVC69s6a7si0sIdUBx7muF/TOoYEG+SrVYLBaL5b/nL0VAXPfugc55AAAAAElFTkSuQmCC"
              alt=""
            />
          </div>
          <div className="balanceAssets__tip">
            <img src="https://bdg1.in/assets/png/cip-7e500e5e.png" alt="" />
          </div>
        </div>
        <div>
          <div className="Recharge__container-tabcard">
            <div className="Recharge__container-tabcard__items active">
              <div className="centers">
                <div className="Recharge__container-tabcard__top">
                  <img
                    className="img"
                    src="https://ossimg.bdgadminbdg.com/IndiaBDG/payNameIcon/payNameIcon2_20240110090938rryt.png"
                    alt=""
                  />
                </div>
                <div className="Recharge__container-tabcard__bot">UPI-QRpay </div>
              </div>
            </div>
            <div className="Recharge__container-tabcard__items">
              <div className="centers">
                <div className="Recharge__container-tabcard__top">
                  <img
                    className="img"
                    src="https://ossimg.bdgadminbdg.com/IndiaBDG/payNameIcon/payNameIcon_20240324145355ec8w.png"
                    alt=""
                  />
                </div>
                <div className="Recharge__container-tabcard__bot">Wake UP-APP </div>
              </div>
            </div>
            <div className="Recharge__container-tabcard__items">
              <div className="centers">
                <div className="gift">
                  <span>+2%</span>
                </div>
                <div className="Recharge__container-tabcard__top">
                  <img
                    className="img"
                    src="https://ossimg.bdgadminbdg.com/IndiaBDG/payNameIcon/payNameIcon_20231209230609nudu.png"
                    alt=""
                  />
                </div>
                <div className="Recharge__container-tabcard__bot">
                  TRX 2% Bonus{" "}
                </div>
              </div>
            </div>
            <div className="Recharge__container-tabcard__items">
              <div className="centers">
                <div className="gift">
                  <span>+3%</span>
                </div>
                <div className="Recharge__container-tabcard__top">
                  <img
                    className="img"
                    src="https://ossimg.bdgadminbdg.com/IndiaBDG/payNameIcon/payNameIcon_20240110090829ntd3.png"
                    alt=""
                  />
                </div>
                <div className="Recharge__container-tabcard__bot">
                  USDT 3% Bonus{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Recharge__content">
          <div className="Recharge__content-paymoney boxStyle">
            <div className="Recharge__content-paymoney__title">
              <img
                src="https://bdg1.in/assets/png/saveWallet-2bb70adc.png"
                alt=""
              />
              <p>Deposit amount</p>
            </div>
            <div className="Recharge__content-paymoney__money-list">
              

            {depositOptions.map((amountoption,index)=>{
              return(
                <div className={`Recharge__content-paymoney__money-list__item ${amountoption.value === amount && 'active'}`} key={index} onClick={()=>setAmount(amountoption.value)}>
                <div className="amount">
                  <span>₹</span> {amountoption.label}
                </div>
              </div>
              )
            })}

            </div>
            <div className="Recharge__content-paymoney__money-input">
              <div className="place-div">₹</div>
              <div
                className="van-cell van-field amount-input"
                modelmodifiers="[object Object]"
              >
                <div className="van-cell__value van-field__value">
                  <div className="van-field__body">
                    <input
                      type="number"
                      className="van-field__control"
                      placeholder="Please enter the amount"
                      value={amount}
                      onChange={(e)=>setAmount(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="place-right cursor-pointer" onClick={()=>setAmount('')}>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAAPFBMVEUAAABoaGhoaGhmZmZnZ2dmZmZmZmZwcHBmZmZnZ2doaGhnZ2dmZmZmZmZmZmZpaWlmZmZgYGBnZ2dmZmbTzN1eAAAAE3RSTlMAXyDvv3DfEK+fQN+AUM8wkBDPjWztQAAAAuRJREFUaN7tWduu4yAM5H4LNO3p///rbkO1PNhh4BBVWql+aomZwWMSLhZf+9rHTMkcjXPPv6adCbnsF4J7G/STmA5SXYNunqdmljlS1M++RbUCb5og5i6LOsD8nuzd6HUKFf4pYZmU7tasUdj3GLfsz6fW9qbI88M378Gnvl95+zk1P/wKDy3VKLSdwb/XPnLMW26TMvkadvDDesapDurGh4wlvakhfHfMncmkqW001f4Y/w/rieO+YZXCge/FtD0OBoPcMsCHDHeQLISPGWxXSI3yizOte90dcIAMup+GDELEZrsI6vj0iiULPQ0iTgBO9Euk0AlAikWTL5R0HoBYNnOWZ8VRF+1Kd7z0eToLIXLMjqhG9NBcCPEkADJaTfJC9N4EE4L2rPPGtTYG/iGVcGPfBVOBMAN+lN9iU4UUgBmkfrweeOpeWTEQFs8wGoXWhqFoI/0iBWa67AIwDOKLvc1e0oQZaAM1TRJaWgowA8XHUzK31RQwtD9gZ5i5HGOG9hN5RxJTEgMMGJ9X3LVJBBgAfnttHUm7FyMMLD4mePUSyCTGp3i0ATNI8b8STEpEZpFaSTL9YGtAsDRNdzKLDFn7ll60RF60CPrgTwVdED77sZPLn2uguLp+wdk/sGR+dNG3V29bJNgszW+86CaOsmYAM0FtWN0caS0UBDO42sodrhLxxdt3zQ5KDR5AND6AbJ0DCD76lE3z+O154XAUOL2tmDs9bKfrjrGqc0r3a/iqBtC5yghrBJEGQK8yFsyC+01zxXXOhhycWkgAGqAFV4fowhJLfAcMED8jNwMYAH4YvDq8/fJi9scvXBIvXUivX47PXEg/wmRlxtcOZjxzuZZNRgsU+nC//6LE4tJoLYxKilNdwy4QvvpxemKZqlCnfX12TzB8UJkZLNQFMHxIUUuNNu3+aFVFtlIjqoVhir7pmJYr1b1yr/XXVMP5gjVBX7G95GDcweOciVkq8bWvfcr+APQTXgFY+Cs5AAAAAElFTkSuQmCC"
                  alt=""
                />
              </div>
            </div>
            <button disabled={!amount} className={`Recharge__container-rechageBtn ${amount && 'rechage_active'}`} onClick={onPay} >Deposit</button>
          </div>
          <div
            className="Recharge__content-waitPay boxStyle"
            style={{ display: "none" }}
          >
            <img src="https://bdg1.in/assets/png/tip-b4d09791.png" alt="" />
            <div className="wait_text">You have 1 unpaid order</div>
            <div className="Recharge__content-waitPay__countdown">
              <span>3</span>
              <span>0</span>
              <span>:</span>
              <span>0</span>
              <span>0</span>
            </div>
            <div className="go_pay">Go pay</div>
          </div>
        </div>
        <div className="Recharge__container-intro">
          <div className="Recharge__container-intro__title">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGUSURBVHgB7ZlNTsJAFIDfE0JccoSyQnZ6A04gRWStN0BOYDmB3EBYm/LjCTgCOxVM4AgsldAZp91o1Om0hOZNw/uSJm2nnfSbN20n7wEwzHGDSS5ajd3yToiOkHirDh3ICoR1tTWtpLmlaLrgfdS82Qair3ovQ9bI9INzEte4GLudQIiB2s3+4TW8+Y1VuOnatRF4GbuODKQH1Biioo1AQch7IBz5pOinkIRzyAFaAQk5F8gLLEANC1DDAtSwADUsQA0LUMMC1LAANSxADQscFJVahJQYU4vffeMskNA7LcK80pxswBISCQgpe7X21AMLMU4hRBzU2s8eWIpRIBAwBIsxTqFaezL7fe7hqhRmix04LOvuaJuqNhBijEBY3Ph7NlFdJCX79RkXgbXanI9dlCOd/Wzojj5Tj1RWxGSnMZr7BYzS7NaiFSgVQZWVYKOy1PWlf2mthFYg/FkJxG64r4p73sJvPC6f3DpYhvHNefXdO5T01Zrq9fTfZzV+hc5ak74o4IVyHarlxBwo2GONxDDHwhdCEmZUjyAjGQAAAABJRU5ErkJggg=="
              alt=""
            />
            <p>Recharge instructions</p>
          </div>
          <div className="Recharge__container-intro__lists">
            <div className="item">
              <p>
                If the transfer time is up, please fill out the deposit form
                again.
              </p>
              <p>
                The transfer amount must match the order you created, otherwise
                the money cannot be credited successfully.
              </p>
              <p>
                If you transfer the wrong amount, our company will not be
                responsible for the lost amount!
              </p>
              <p>
                Note: do not cancel the deposit order after the money has been
                transferred.
              </p>
            </div>
          </div>
        </div>
        <div className="record__main" payid="1">
          <div className="record__main-title">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgB7dnNDYJAEAXggXAhXixBT7QBDUiM3m3FEixETahA2yAx0SYMJ0C8GlecBfZlk/edSPjbl8ns8iNCNEhg2nE7r3d10xy6zbm4G80j2RRLzSmhaUfdNntxOfi3VhaiFPVdLNkWgThQHvNWLITiOQZAi5THS3nK7zbN9vf1v/XCj9lJX4EJB29zT/YAGgOgMQAaA6AxAJr6WajPpbhK9axU58SzWLI8FRvjV8Dm9WfAK9PoFchWqbjEJkZjADSuA5+4DihxHUBjADQGQOudhWy/27tirkD3QVU8oF5CUBUx/SliE6MxAJo+AGJ28mRGJC+9AJ8KNFKq/j7kAAAAAElFTkSuQmCC"
              alt=""
            />
            <span>Deposit history</span>
          </div>
          <div className="empty__container mgt40">
            <img
              alt=""
              className="ar-lazyload"
              data-origin="public/images/empty-ea102850.png"
              src="/images/empty-ea102850.png"
            />
            <p>No data</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recharge;
