import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HIDE_MAIN_ERROR_MODAL, SelectMainErrorModal } from '../../redux/errorMessageSlice'

function ErrorMessagePopup() {
    const dispatch = useDispatch()
    let mainErrorPopup = useSelector(SelectMainErrorModal)


    if (mainErrorPopup.show) {
        return (
            <div
                className="relative z-[1065] bg-black bg-opacity-70 ease-out duration-300"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            // backdrop
            >

                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 ">

                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                    </svg>

                                </div>
                            </div>

                            <div className='w-full flex justify-center mb-4 font-[600]'>
                                <h2>{mainErrorPopup.message}</h2>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex justify-center sm:px-6 mb-4">

                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={() => dispatch(HIDE_MAIN_ERROR_MODAL())}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default ErrorMessagePopup