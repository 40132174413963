import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import "./colorgameoutlet.css"

function ColorGmaeOutlet() {


  return (
    <div className='color-game-wrapper'>
      <div id="app">
        <Outlet />
      </div>
    </div>
  )
}

export default ColorGmaeOutlet