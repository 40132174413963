import { createSlice } from "@reduxjs/toolkit";


export const SelectColorActiveGame = ({ colorGameSlice }) => colorGameSlice.colorActiveGame
export const SelectHistoryRecord = ({ colorGameSlice }) => colorGameSlice.historyRecordState
export const SelectMyHistoryRecord = ({ colorGameSlice }) => colorGameSlice.myHistoryRecordState
export const SelectSecondTimer = ({ colorGameSlice }) => colorGameSlice.secondTimer
export const SelectBatBtnDisable = ({ colorGameSlice }) => colorGameSlice.batBtnDisable
export const SelectlastWinCard = ({ colorGameSlice }) => colorGameSlice.lastWinCard



const colorGameSlice = createSlice({
    name: "colorGameSlice",
    initialState: {
        colorActiveGame : 'METALY',
        historyRecordState:{},
        myHistoryRecordState:{},
        secondTimer : null ,
        gameID: '',
        batBtnDisable: false,
        lastWinCard: []

    },
    reducers: {
        SET_COLOR_ACTIVE_GAME: (state, { payload }) => { state.colorActiveGame = payload },
        SET_HISTORY_RECORD_STATE:(state, { payload }) => { state.historyRecordState = payload },
        SET_MY_HISTORY_RECORD_STATE:(state, { payload }) => { state.myHistoryRecordState = payload },
        SET_SECOND_TIMER: (state, { payload }) => {state.secondTimer = payload},
        SET_BAT_BUTTON_DISABLE: (state, { payload }) => {state.batBtnDisable = payload},
        SET_LAST_WIN_CARD: (state, { payload }) => {state.lastWinCard = [...payload]},
    }
})





export const {
    SET_COLOR_ACTIVE_GAME, SET_HISTORY_RECORD_STATE, SET_MY_HISTORY_RECORD_STATE, SET_SECOND_TIMER, SET_BAT_BUTTON_DISABLE, SET_LAST_WIN_CARD

} = colorGameSlice.actions;

export default colorGameSlice.reducer;