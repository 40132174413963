import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_ACTIVE_TOP_WINS_TAB, SET_BAT_WINS_TYPE, SET_PREVIOUS_FLAG, SelectActiveTopWinsTab, SelectPreviousHandFlag, SelectTopBatWinsList, SelectTopBatWinsType } from '../../../../../redux/AviatorGameSlice'
import HugeWinsBatList from './HugeWinsBatList'

function TopBatComponent() {
  const dispatch = useDispatch()
  let topBatWinsList = useSelector(SelectTopBatWinsList)
  let topBatWinsType = useSelector(SelectTopBatWinsType)
  let activeTopWinsTab = useSelector(SelectActiveTopWinsTab)


  return (
    <div className='flex flex-col h-[100%] min-h-0'>

      <div className='flex h-[100%] flex-col'>

        {/* win list header */}
        <div className='min-h-[24px] max-h-[24px] mb-[5px] flex items-center justify-center leading-[1]'>
          <div className='flex justify-around w-full max-w-[300px]'>
            {topBatWinsList.map((list) => {
              return (
                <button
                  key={list.id}
                  onClick={() => dispatch(SET_BAT_WINS_TYPE(list.id))}
                  className={`${topBatWinsType === list.id ? 'border-[#e50539]' : 'border-transparent'} border h-[100%] rounded-[20px] bg-none text-[#fff] text-[13px] px-[5px] py-[4px]`}>
                  {list.title}
                </button>
              )
            })}
          </div>
        </div>

        {/* tab continer */}
        <div className='flex justify-center items-center my-[5px] min-h-[24px] max-h-[24px] text-[12px]'>
          {/* navigation switcher */}
          <div className='h-[100%] bg-[#141516] rounded-[10px] border border-[#141516] relative'>
            <div className={`w-[100px] h-[100%] absolute rounded-[10px] bg-[#2c2d30]`} style={{ transform: `translate(${activeTopWinsTab * 100}px)`, transition: 'transform .5s ease' }} />
            {['Day', 'Month', 'Year'].map((tab, index) => {
              return (
                <button key={index} className={`w-[100px] ${activeTopWinsTab === index ? 'text-[#fff]' : 'text-[#9ea0a3]'} h-[100%] relative text-center bg-none border-none p-0`} onClick={() => dispatch(SET_ACTIVE_TOP_WINS_TAB(index))}>{tab}</button>
              )
            })}
          </div>
        </div>
        {/*  */}
        {/* all Bets */}
        {topBatWinsType === 'HUGE_WINS' && <HugeWinsBatList />}

      </div>

    </div>
  )
}

export default TopBatComponent