import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import planeImage from "./sprite2.png"
import { SET_UPDATE_CRASH_REWARD, SelectCrashPlan, SelectCrashReward, SelectDefaultPlanPositoin, SelectFlyPlan, SelectFlyTimer, SelectTotalTime } from '../../../../redux/AviatorGameSlice';
import { useSelector, useDispatch } from "react-redux"


let cW;
let cH;
let canvas;
let ctx;
let screenHeight;
let screenWidth;

let x = 0;
let canvasHeight = 0;
let canvasWidth = 0;
let calcwidth = 0;
let calcheight = 0;
let horizontalLine = 0;
let verticalLine = 0;
let verticaldots = 0;
let verticalDotSize = 0;
let boardWidth = 0;
let boardheight = 0;
let widthDouble = 0;
let xPoint = 0;
let yPoint = 0;
let diffx = 0;
let imgheight = 0;
let imgwidth = 0;
let imgyposition = 0;
let imgxposition = 0;
let settimeinterval = 0;
let checkuplinedownlinecount = 0;
let diffy = 0;
let diffx1 = 0;
let yend = 0;
let xend = 0;
let backgroundImage = '';
let start = null;
let progress = 0;
let frameIndex = 0;
let countInterval = 0;
let estimateHeight = 0;
let estimateWidth = 0;
let HorizontalDotsCountRun = 0;
let VerticalDotsCountRun = 0;
let lastUpdate = Date.now();
let y0 = 0;
let x0 = 0;
let y1 = 0;
let x1 = 0;
let y2 = 0;
let x2 = 0;
let intervalID;
let intervalID1;
let stopPlaneEvent = 0;
let nx0 = 0;
let ny0 = 0;
let nx1 = 0;
let ny1 = 0;
let nx2 = 0;
let ny2 = 0;
let StopPlaneIntervalID1 = 0;
let startupdown = 0;
let imgTag;
let showStaticImage
let StopPlaneIntervalID;
let gameStart = false
let loaderInterval

function AviatorCanvas() {
    // 
    let stageRef = useRef(null)
    let dispatch = useDispatch()

    let flyPlan = useSelector(SelectFlyPlan)
    let crashPlan = useSelector(SelectCrashPlan)
    let defaultPlanPosition = useSelector(SelectDefaultPlanPositoin)
    let flyTimer = useSelector(SelectFlyTimer)
    let crashReward = useSelector(SelectCrashReward)
    let totalTime = useSelector(SelectTotalTime)

    const [WindowSize, WindowSetSize] = useState({ width: 0, height: 0 });
    const [canvasInnerSize, setCanvasInnerSize] = useState({ width: 0, height: 0 });
    const [stageDivSize, setStageDivSize] = useState({ width: 0, height: 0 });
    const [loaderFlag, setloaderFlag] = useState(false)
    const [showNumberFlag, setShowNumberFlag] = useState(true)
    const [showNumber, setShowNumber] = useState(crashReward || 1)
    const [loaderPer, setLoaderPer] = useState(100)





    useEffect(() => {
        canvas = $('#myCanvas');
        ctx = canvas[0].getContext('2d');
        function handleResize() {
            // Check if divRef.current exists to prevent errors
            if (stageRef.current) {
                const { width, height } = stageRef.current.getBoundingClientRect();
                setStageDivSize({ width, height })
                WindowSetSize({ width: window.innerWidth, height: window.innerHeight });
                cW = $('.stage-board').width();
                cH = $('.stage-board').height();
                $('#myCanvas').attr('width', cW).attr('height', cH);
                screenHeight = $(window).height() - 4;
                screenWidth = $(window).width();
                canvasHeight = $('canvas').innerHeight();
                canvasWidth = $('canvas').innerWidth();
                calcwidth = canvasWidth / 100;
                calcheight = canvasHeight / 100;

                verticaldots = verticalLine / 100;
                verticalDotSize = (verticaldots * 50);
                boardWidth = canvasWidth;
                boardheight = canvasHeight;
                widthDouble = boardWidth * 2.5;
                xPoint = 0 - (boardWidth * 1.25);
                yPoint = boardheight - (boardWidth * 1.25);
                $(".rotateimage").css("width", widthDouble).css("height", widthDouble).css("top", yPoint).css("left", xPoint);

                if (canvasWidth < 992) {
                    diffx = calcwidth * 35;
                    horizontalLine = calcwidth * 10;
                    verticalLine = calcheight * 10;

                }
                else {
                    diffx = calcwidth * 30;
                    horizontalLine = calcwidth * 5;
                    verticalLine = calcheight * 5;
                }


                imgTag = new Image();

                if (canvasWidth < 992) {
                    imgheight = 48;
                    imgwidth = 200;
                    imgyposition = 45;
                    imgxposition = 20;
                    imgTag.src = "/assets/sprite2.png";
                    settimeinterval = 40;
                    checkuplinedownlinecount = 50; //UPDOWN
                    diffy = calcheight * 80; //HEIGHT
                    diffx1 = canvasWidth - (calcwidth * 60) //CURVE
                }
                else {
                    imgheight = 71;
                    imgwidth = 300;
                    imgyposition = 66;
                    imgxposition = 15;
                    imgTag.src = "/assets/sprite3.png";
                    settimeinterval = 20;
                    checkuplinedownlinecount = 150;
                    diffy = calcheight * 90;
                    diffx1 = canvasWidth - (calcwidth * 70)
                }


                yend = canvasHeight - diffy;

                xend = canvasWidth - diffx;


                y0 = (ctx.canvas.height - verticalLine);
                x0 = verticalLine;
                y1 = (ctx.canvas.height - verticalLine);
                x1 = diffx1;
                y2 = yend;
                x2 = xend;

            }
        }
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        // Initial call to set size
        handleResize();
        x = 0;
        backgroundImage = '';
        start = null;
        progress = 0;
        frameIndex = 0;
        countInterval = 0;
        estimateHeight = 0;
        estimateWidth = 0;
        HorizontalDotsCountRun = 1;
        VerticalDotsCountRun = 1;
        lastUpdate = Date.now();

        startupdown = 0;
        stopPlaneEvent = 1;


        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (flyPlan) {
            setTimeout(() => {
                start = null
                stopPlaneEvent = 0
                setShowNumberFlag(true)
                animatePathDrawing()
            }, 800);

        }
    }, [flyPlan])

    useEffect(() => {
        if (crashPlan) {
            stopPlane()
        }
    }, [crashPlan])

    useEffect(() => {
        if (defaultPlanPosition) {
            DrawImage()
            setloaderFlag(true)
            setShowNumberFlag(false)

            loaderInterval = setInterval(() => {
                setLoaderPer(prev => {
                    if (prev > 0) {
                        return prev - 1;
                    } else {
                        clearInterval(loaderInterval); // Clear interval when progress is 0
                        return 0;
                    }
                });
            }, 50);


        } else {
            setLoaderPer(100)
            setloaderFlag(false)
        }

        return () => clearInterval(loaderInterval);
    }, [defaultPlanPosition])




    // code new
    useEffect(() => {
        if (!flyPlan && !crashReward) {
            return
        }
        let loop = 0
        let updateNumber = () => {
            if (showNumber < crashReward)

                // Update the showNumber state
                setShowNumber((old) => {
                    // console.log('old: ', old);
                    if (+old >= crashReward) {

                        return crashReward
                    }
                    dispatch(SET_UPDATE_CRASH_REWARD(Number(+old + (0.08 / 10))))
                    // if(+old === 0){
                    //     return (Number(1))
                    // }
                    return (Number(+old + (0.08 / 10)))


                });
            loop += 1
        }

        const intervalId = setInterval(updateNumber, 100);
        return () => {
            clearInterval(intervalId)
            setShowNumber(crashReward || 1)
            dispatch(SET_UPDATE_CRASH_REWARD(crashReward))
        };
    }, [flyTimer, crashReward, flyPlan]);

    // new code end



    let DrawImage = () => {
        gameStart = true
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        GameObject(imgTag, 2, canvasHeight - imgheight, imgwidth, imgheight, 300, 2, ctx)
        showStaticImage = window.requestAnimationFrame(DrawImage);
    }

    function animatePathDrawing() {

        cancelAnimationFrame(showStaticImage);

        let step = function animatePathDrawingStep(timestamp) {

            if (start === null) {

                start = timestamp;
            }

            let delta = timestamp - start,
                progress = Math.min(delta / 5000, 1);

            // Draw curve
            if (imgTag != '') {

                drawBezierSplit(0, progress);
            }
            if (progress < 1) {

                window.requestAnimationFrame(step);
            }
        };


        window.requestAnimationFrame(step);
    }

    function stopPlane() {
        // 
        if (StopPlaneIntervalID1 == 0) {
            ctx.beginPath();
            clearInterval(intervalID);
            clearInterval(intervalID1);
            stopPlaneEvent = 1;
            $(".rotateimage").removeClass('rotatebg');

            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            let intervalTimex = 100;
            let intervalTimey = 50;

            if (startupdown == 1) {
                nx2 = estimateWidth;
                ny2 = estimateHeight;
            }
            let stopPlaneCount = Math.round((ctx.canvas.width - nx2) / 4);


            StopPlaneIntervalID = setInterval(() => {

                ctx.beginPath();
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.moveTo(nx0, ny0);
                ctx.quadraticCurveTo(nx1, ny1, nx2 + intervalTimex, ny2 - intervalTimey);
                GameObject(imgTag, (nx2 + intervalTimex) - imgxposition, (ny2 - intervalTimey) - imgyposition, imgwidth, imgheight, 300, 2, ctx);
                ctx.closePath();
                StopPlaneIntervalID1++;
                intervalTimex = intervalTimex + 4;
                intervalTimey = intervalTimey + 1;

                if (StopPlaneIntervalID1 >= (stopPlaneCount)) {

                    window.clearInterval(StopPlaneIntervalID);
                    StopPlaneIntervalID1 = 0;


                }

            }, 1);
            ctx.closePath();
        }

    }
    function drawLine() {
        // 
        ctx.beginPath();
        ctx.moveTo((verticalLine), 0);
        ctx.lineTo((verticalLine), (ctx.canvas.height - verticalLine));
        ctx.lineTo((ctx.canvas.width), (ctx.canvas.height - verticalLine));
        ctx.lineWidth = 1;
        ctx.strokeStyle = '#423033';
        ctx.stroke();
        ctx.closePath();
    }
    function drawHorizontalDots() {
        let HorizontalDotsCount = 1;
        let verticalLinedata;
        let horizontalLinedata;
        ctx.save();
        ctx.beginPath();
        if (canvasWidth < 992) {
            verticalLinedata = verticalLine / 2.5;
            horizontalLinedata = horizontalLine / 2.5;
        }
        else {
            verticalLinedata = verticalLine;
            horizontalLinedata = horizontalLine;
        }
        ctx.rect(verticalLine, (ctx.canvas.height - verticalLine), ctx.canvas.width, verticalLine);
        ctx.closePath();
        ctx.clip();
        for (let i = 0; i < 20; i++) {
            ctx.beginPath();
            ctx.arc(((horizontalLinedata * 2) * i) + 3, (ctx.canvas.height - verticalLine) + verticalDotSize, 2, 0, 2 * Math.PI);
            ctx.fillStyle = 'white';
            ctx.fill();
            ctx.closePath();
        }
        ctx.restore();
    }
    function animationHorizontalDots() {
        let verticalLinedata;
        let horizontalLinedata;
        ctx.beginPath();
        ctx.save();
        ctx.beginPath();
        if (canvasWidth < 992) {
            verticalLinedata = verticalLine / 2;
            horizontalLinedata = horizontalLine / 2;
        }
        else {
            verticalLinedata = verticalLine;
            horizontalLinedata = horizontalLine;
        }
        ctx.fillStyle = "rgba(0,0,0,0.1)";
        ctx.rect(verticalLine, (ctx.canvas.height - verticalLine), ctx.canvas.width, verticalLine);
        ctx.fill();
        ctx.closePath();
        ctx.clip();
        for (let i = 0; i < 2000; i++) {
            ctx.beginPath();
            ctx.arc((((horizontalLinedata * 2) * i) + 3) - HorizontalDotsCountRun, (ctx.canvas.height - verticalLine) + verticalDotSize, 2, 0, 2 * Math.PI);
            ctx.fillStyle = 'white';
            ctx.fill();
            ctx.closePath();
        }
        HorizontalDotsCountRun = HorizontalDotsCountRun + 1;
        ctx.restore();
    }
    function animationVerticalDots() {
        let verticalLinedata;
        let horizontalLinedata;
        ctx.beginPath();
        if (canvasWidth < 992) {
            verticalLinedata = verticalLine / 2;
            horizontalLinedata = horizontalLine / 2;
        }
        else {
            verticalLinedata = verticalLine;
            horizontalLinedata = horizontalLine;
        }
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = "rgba(0,0,0,0.1)";
        ctx.rect(0, 0, verticalLine, (ctx.canvas.height - verticalLine));
        ctx.closePath();
        ctx.clip();
        for (let i = 0; i < 2000; i++) {
            ctx.beginPath();
            ctx.arc((verticalLine - verticalDotSize), ((ctx.canvas.height - (verticalLinedata * i)) * 2 - 5) + VerticalDotsCountRun, 2, 0, 2 * Math.PI);
            ctx.fillStyle = '#1197D6';
            ctx.fill();
            ctx.closePath();
        }
        VerticalDotsCountRun = VerticalDotsCountRun + 1;
        ctx.restore();
    }
    function drawVerticalDots() {
        let verticalLinedata;
        let horizontalLinedata;
        let VerticalDotsCount = 0;
        ctx.save();
        ctx.beginPath();
        if (canvasWidth < 992) {
            verticalLinedata = verticalLine / 2;
            horizontalLinedata = horizontalLine / 2;
        }
        else {
            verticalLinedata = verticalLine;
            horizontalLinedata = horizontalLine;
        }
        ctx.rect(0, 0, verticalLine, (ctx.canvas.height - verticalLine));
        ctx.closePath();
        ctx.clip();
        for (let i = 0; i < 20; i++) {
            ctx.beginPath();
            // 
            ctx.arc((verticalLine - verticalDotSize), (verticalLinedata * i) * 2 + 5, 2, 0, 2 * Math.PI);
            ctx.fillStyle = '#1197D6';
            ctx.fill();
            ctx.closePath();
        }
        ctx.restore();
    }
    function draw(spritesheet, x, y, width, height, timePerFrame, numberOfFrames, ctx, frameIndex) {
        ctx.drawImage(spritesheet, (frameIndex * width / numberOfFrames), 0, (width / numberOfFrames), height, x, y, (width / numberOfFrames), height);
    }
    function GameObject(spritesheet, x, y, width, height, timePerFrame, numberOfFrames, ctx) {
        spritesheet = spritesheet;             //the spritesheet image
        x = x;                                 //the x coordinate of the object
        y = y;                                 //the y coordinate of the object
        width = width;                         //width of spritesheet
        height = height;                       //height of spritesheet
        timePerFrame = timePerFrame;           //time in(ms) given to each frame
        numberOfFrames = numberOfFrames || 1;  //number of frames(sprites) in the spritesheet, default 1

        if (Date.now() - lastUpdate >= timePerFrame) {
            frameIndex++;
            if (frameIndex >= numberOfFrames) {
                frameIndex = 0;
            }
            lastUpdate = Date.now();
        }
        // window.onload=function(){
        draw(spritesheet, x, y, width, height, timePerFrame, numberOfFrames, ctx, frameIndex);
        // }
    }

    function drawBezierSplit(t0, t1) {



        if (0.0 == t0 && t1 == 1.0) {
            if (stopPlaneEvent == 0) {
                startupdown = 1;
                ctx.beginPath();
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                $.when(drawLine()).then(animationHorizontalDots());
                animationVerticalDots();
                ctx.moveTo(verticalLine, (ctx.canvas.height - verticalLine));
                ctx.quadraticCurveTo(diffx1, (ctx.canvas.height - verticalLine), xend, yend);
                GameObject(imgTag, x2 - imgxposition, y2 - imgyposition, imgwidth, imgheight, 300, 2, ctx);
                ctx.lineWidth = 5;
                ctx.strokeStyle = '#F00B3E';
                ctx.stroke();
                ctx.closePath();
                fillShape(xend, yend, verticalLine, (ctx.canvas.height - verticalLine), diffx1, (ctx.canvas.height - verticalLine), t1);
                startfirstinterval();
                animationHorizontalDots();
            }

        }
        else if (t0 != t1) {
            if (stopPlaneEvent == 0) {
                ctx.beginPath();
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                $.when(drawLine()).then(drawHorizontalDots());
                $.when(drawHorizontalDots()).then(drawVerticalDots());
                let t00 = t0 * t0,
                    t01 = 1.0 - t0,
                    t02 = t01 * t01,
                    t03 = 2.0 * t0 * t01;

                nx0 = t02 * verticalLine + t03 * diffx1 + t00 * xend;
                ny0 = t02 * (ctx.canvas.height - verticalLine) + t03 * (ctx.canvas.height - verticalLine) + t00 * yend;

                t00 = t1 * t1;
                t01 = 1.0 - t1;
                t02 = t01 * t01;
                t03 = 2.0 * t1 * t01;

                nx2 = t02 * verticalLine + t03 * diffx1 + t00 * xend;
                ny2 = t02 * (ctx.canvas.height - verticalLine) + t03 * (ctx.canvas.height - verticalLine) + t00 * yend;

                nx1 = lerp(lerp(verticalLine, diffx1, t0), lerp(diffx1, xend, t0), t1);
                ny1 = lerp(lerp((ctx.canvas.height - verticalLine), (ctx.canvas.height - verticalLine), t0), lerp((ctx.canvas.height - verticalLine), yend, t0), t1);
                ctx.moveTo(nx0, ny0);
                ctx.quadraticCurveTo(nx1, ny1, nx2, ny2);
                GameObject(imgTag, nx2 - imgxposition, ny2 - imgyposition, imgwidth, imgheight, 300, 2, ctx);
                ctx.lineWidth = 5;
                ctx.strokeStyle = '#F00B3E';
                ctx.stroke();
                ctx.closePath();
                fillShape(nx2, ny2, nx0, ny0, nx1, ny1, 0);
            }
        }

    }
    function startfirstinterval() {


        intervalID = setInterval(() => {
            downplane();
            if (++countInterval >= checkuplinedownlinecount) {
                window.clearInterval(intervalID);
                countInterval = 0;
                startsecondinterval();
            }

        }, settimeinterval);
    }
    function startsecondinterval() {


        intervalID1 = setInterval(() => {
            upplane();
            if (++countInterval >= checkuplinedownlinecount) {
                window.clearInterval(intervalID1);
                countInterval = 0;
                startfirstinterval();
            }
        }, settimeinterval);
    }
    function upplane() {

        ctx.beginPath();
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        $.when(drawLine()).then(animationHorizontalDots());
        animationVerticalDots();
        ctx.moveTo(x0, y0);
        ctx.quadraticCurveTo(x1, y1, estimateWidth - (countInterval), estimateHeight - (countInterval));
        GameObject(imgTag, estimateWidth - (countInterval) - imgxposition, estimateHeight - (countInterval) - imgyposition, imgwidth, imgheight, 300, 2, ctx);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#F00B3E';
        ctx.stroke();
        ctx.closePath();
        ctx.beginPath();
        ctx.moveTo(x0, y0);
        ctx.quadraticCurveTo(x1, y1, estimateWidth - (countInterval), estimateHeight - (countInterval));
        ctx.lineTo(estimateWidth - (countInterval) + 3, estimateHeight - (countInterval));
        ctx.lineTo(estimateWidth - (countInterval), y0);
        ctx.fillStyle = "rgba(104,1,14,0.8)";
        ctx.fill();
        ctx.closePath();
    }

    function downplane() {

        ctx.beginPath();
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        $.when(drawLine()).then(animationHorizontalDots());
        animationVerticalDots();

        estimateHeight = y2 + (countInterval);
        estimateWidth = x2 + (countInterval);
        ctx.moveTo(x0, y0);
        ctx.quadraticCurveTo(x1, y1, estimateWidth, estimateHeight);
        GameObject(imgTag, estimateWidth - imgxposition, estimateHeight - imgyposition, imgwidth, imgheight, 300, 2, ctx);
        ctx.lineWidth = 5;
        ctx.strokeStyle = '#F00B3E';
        ctx.stroke();
        ctx.stroke();
        ctx.closePath();
        ctx.beginPath();
        ctx.moveTo(x0, y0);
        ctx.quadraticCurveTo(x1, y1, estimateWidth, estimateHeight);
        ctx.lineTo(estimateWidth + 3, estimateHeight);
        ctx.lineTo(estimateWidth, y0);
        ctx.fillStyle = "rgba(104,1,14,0.8)";
        ctx.fill();
        ctx.closePath();
    }

    function lerp(v0, v1, t) {
        return (1.0 - t) * v0 + t * v1;
    }

    function fillShape(nx2, ny2, nx0, ny0, nx1, ny1, t1) {
        if (t1 == 1.0) {

            ctx.beginPath();
            ctx.moveTo(nx0, ny0);
            ctx.quadraticCurveTo(nx1, ny1, nx2, ny2);
            ctx.lineTo(nx2 + 3, ny2);
            ctx.lineTo(nx2 + 3, y0);
            ctx.fillStyle = "rgba(104,1,14,0.8)";
            ctx.fill();
            ctx.closePath();
        }
        else {

            ctx.beginPath();
            ctx.moveTo(nx0, ny0);
            ctx.quadraticCurveTo(nx1, ny1, nx2, ny2);
            ctx.lineTo(nx2, ny2);
            ctx.lineTo(nx2, y0);
            ctx.fillStyle = "rgba(104,1,14,0.8)";
            ctx.fill();
            ctx.closePath();
        }
    }

    let showBgColor = (showNumber) => {
        if (showNumber >= 2) {
            return "radial-gradient(rgb(145, 62, 248,0.7), transparent , transparent)"
        } else if (showNumber >= 10) {
            return "radial-gradient(rgb(192, 23, 180,0.7), transparent , transparent)"
        } else {
            return "radial-gradient(rgb(52, 180, 255,0.7), transparent , transparent)"
        }
    }

    return (
        <div ref={stageRef} className='absolute w-full h-full rounded-[20px] border border-[red] stage-board overflow-hidden'>

            <img src="/assets/bg-rotate-old.svg" className="rotateimage rotatebg"></img>
            {loaderFlag &&
                <div className='w-full h-full flex absolute top-0 left-0 justify-center items-center flex-col'>
                    <img src="/assets/aviator-game/loader/loader.svg" className='aviator_loader' ></img>
                    <p className='text-white mt-2 text-[24px] uppercase tracking-wide'>Waiting for Next Results</p>
                    <div className="flex w-[10%] mt-1 h-[5px] bg-[#2a2b2e] rounded-full overflow-hidden dark:bg-neutral-700" role="progressbar" aria-valuenow={loaderPer} aria-valuemin="0" aria-valuemax="100">
                        <div className="flex flex-col justify-center rounded-full overflow-hidden bg-red-700 text-xs text-white text-center whitespace-nowrap transition duration-500" style={{ width: `${loaderPer}%` }}></div>
                    </div>
                </div>
            }

            {showNumberFlag &&
                <div className='w-full h-full flex absolute z-10 top-0 left-0 justify-center items-center flex-col'>
                    <div className='w-full h-full absolute z-0' style={{
                        backgroundImage: `${showBgColor(showNumber)}`,
                        transition: 'opacity 0.5s ease-in-out' // Add transition property
                    }}></div>
                    {crashPlan && <p className='text-white'>FLEW AWAY</p>}
                    {/* {crashReward && <p className='text-[100px] font-bold text-white' >{crashReward}x</p>} */}
                    {(crashReward && showNumber) ? <p className='text-[100px] font-bold text-white' >{showNumber.toFixed(2)}x</p> : null}
                </div>
            }
            <div className='stage-canvas'>
                <canvas id="myCanvas" className="absolute z-2" height='400' width="1900"></canvas>
            </div>

        </div >
    )
}

export default AviatorCanvas