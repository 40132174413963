import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectMainSuccessModal } from '../../redux/successMessageSlice'
import { HIDE_MAIN_SUCCESS_MODAL } from '../../redux/successMessageSlice'

function SuccessMessagePopup() {
    const dispatch = useDispatch()
    let mainSuccessPopup = useSelector(SelectMainSuccessModal)




    if (mainSuccessPopup.show) {
        return (
            <div
                className="relative z-10 bg-black bg-opacity-70 ease-out duration-300"
                aria-labelledby="modal-title"
                role="dialog"
                aria-modal="true"
            // backdrop
            >

                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="mx-auto flex h-[3rem] w-[3rem] items-center justify-center rounded-[9999px] bg-[green]">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                        className="w-[1.5rem] h-[1.5rem] text-[#f0f0f0]"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4.5 12.75l6 6 9-13.5"
                                        />
                                    </svg>
                                </div>
                            </div>

                            <div className='w-full flex justify-center mb-4 font-[600]'>
                                <h2>{mainSuccessPopup.message}</h2>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex justify-center sm:px-6 mb-4">

                                <button
                                    type="button"
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-10 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    onClick={() => dispatch(HIDE_MAIN_SUCCESS_MODAL())}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    // return null


}

export default SuccessMessagePopup