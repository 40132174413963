import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from '@hookform/resolvers/yup';
import { SocketState } from '../../../../context/SocketProvider';
import styled from "./Login.module.css";
import { AuthState } from '../../../../context/AuthProvider';
import moment from 'moment';
import { SET_GET_OTP_FLAG, SET_VERIFY_OTP_FLAG, SelectGetOtpFlag, SelectRegisteFormSubmitFlag, SelectVerifyOtpFlag } from '../../../../redux/registerPageSlice';





const schema = yup.object().shape({
  mobile_number: yup.string().min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits').required('*You must enter a user name.'),
  otp: yup.string().required('*You must enter a OTP.'),
  password: yup.string().required('*You must enter a password.'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  remember: yup.boolean()
});

const defaultValues = {
  mobile_number: '0000012345',
  otp: '166549',
  password: '12345',
  passwordConfirmation: '12345'
};

//   ------------------------------------

function RegisterPage() {

  let { setUser } = AuthState();

  let getOtpFlag = useSelector(SelectGetOtpFlag)
  let verifyOtpFlag = useSelector(SelectVerifyOtpFlag)
  let registeFormSubmitFlag = useSelector(SelectRegisteFormSubmitFlag)


  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { socket } = SocketState();

  const { control, formState, handleSubmit, setError, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const watchRemember = watch("remember")
  let mobileNumberWatch = watch('mobile_number')
  let otpWatch = watch('otp')

  const { isValid, dirtyFields, errors } = formState;

  const [passWordShow, setPassWordShow] = useState(false)
  const [conPassWordShow, setConPassWordShow] = useState(false)



  let getMobileOtp = (mobile_number)=>{
    let payload = {
      en: "OTP_SEND",
      data: {
        mobile_number:mobile_number,
      },
    };
    socket?.emit("req", payload);
    

  }

  let verifyOtp = (mobile_number,otp)=>{
    let payload = {
      en: "VOTP",
      data: {
        mobile_number,
        otp:otp
      },
    };
    socket?.emit("req", payload);
    // dispatch(SET_GET_OTP_FLAG(false))
    // dispatch(SET_VERIFY_OTP_FLAG(true))

  }




  function onSubmit({ mobile_number, password, remember }) {
    console.log('mobile_number, password, remember: ', mobile_number, password, remember);
    let payload = {
      "en": "SIGNUP",
      "data": {
        mobile_number,
        password,
        referral_code:''
      }
    }

    if (remember) {
      localStorage.setItem(
        "creditionlsDetails",
        JSON.stringify({ mobile_number, password, remember })
      );
    }
    localStorage.setItem(
      "creditionls",
      JSON.stringify({ mobile_number, password, remember })
    );

    socket?.emit("req", payload);
    console.log("call socket")

  }


  return (
    <form name="loginForm" noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className={styled.signIn__container}>
        {/* phone field */}
        <div className={styled.phoneInput__container}>
          <div className={styled.phoneInput__container_label}>
            <img
              src="/images/phoneActive.png"
              className={styled.phoneInput__container_label__icon}
            />
            <span>Phone number</span>
          </div>
          <div className={styled.phoneInput__container_input}>
            <div className={styled.dropdown}>
              <div className={styled.dropdown__value}>
                <span>+91</span>
                <i
                  className={`${styled.van_badge__wrapper} ${styled.van_icon} ${styled.van_icon_arrow_down}`}
                ></i>
              </div>
              <div className={styled.dropdown__list}>
                <div className={`${styled.dropdown__list_item} active`}>
                  <span>+91</span> India (भारत)
                </div>
              </div>
            </div>
            <Controller
              control={control}
              name="mobile_number"
              render={({ field }) => (
                <input
                  type="text"
                  name="mobile_number"
                  placeholder="Please enter the phone number"
                  {...field}
                  disabled={!getOtpFlag}
                />
              )}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name="mobile_number"
            render={({ message }) => (
              <p className="text-red-500 text-[12px] mt-1">{message}</p>
            )}
          />
        </div>

        {/* ----------------------otp field--------------- */}

        <div className={styled.passwordInput__container}>
          <div className={styled.passwordInput__container_label}>
            {/* <img
          className={styled.passwordInput__container_label__icon}
          src="/images/password-b827c2b3.png"
        /> */}
            <span>Verify OTP</span>
          </div>
          <div className={styled.otp__container_input}>
            <Controller
              control={control}
              name="otp"
              render={({ field }) => (
                <input
                  type="text"
                  name="otp"
                  placeholder="Please enter OTP"
                  {...field}
                  disabled={registeFormSubmitFlag}
                />
              )}
            />
            <div className={styled.getOtp__container_button}>
              {getOtpFlag ?
                <button onClick={(e) => {
                  e.preventDefault()
                  getMobileOtp(mobileNumberWatch)
                }}
                disabled={registeFormSubmitFlag}
                >Get OTP</button> :
                <button onClick={(e) => {
                  e.preventDefault()
                  verifyOtp(mobileNumberWatch,otpWatch)
                }}
                disabled={registeFormSubmitFlag}
                >Verify OTP</button>
              }

            </div>
          </div>
          <ErrorMessage
            errors={errors}
            name="otp"
            render={({ message }) => (
              <p className="text-red-500 text-[12px] mt-1">{message}</p>
            )}
          />
        </div>

        {/* --------------- password field  ---------------------- */}

        <div className={styled.passwordInput__container}>
          <div className={styled.passwordInput__container_label}>
            <img
              className={styled.passwordInput__container_label__icon}
              src="/images/password-b827c2b3.png"
            />
            <span>Set Password</span>
          </div>
          <div className={styled.passwordInput__container_input}>
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <input
                  type={passWordShow ? 'text' : 'password'}
                  name="password"
                  placeholder="Please enter OTP"
                  {...field}
                  disabled={!registeFormSubmitFlag}
                />
              )}
            />
            <img
              src="https://bigdaddygame.in/assets/png/eyeInvisible-2fa5c152.png"
              className={styled.eye}
              onClick={() => setPassWordShow(!passWordShow)}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="text-red-500 text-[12px] mt-1">{message}</p>
            )}
          />
        </div>

        {/* ---------------confirm password field--------------- */}
        <div className={styled.passwordInput__container}>
          <div className={styled.passwordInput__container_label}>
            <img
              className={styled.passwordInput__container_label__icon}
              src="/images/password-b827c2b3.png"
            />
            <span>Confirm Password</span>
          </div>
          <div className={styled.passwordInput__container_input}>
            {/* passwordConfirmation */}
            <Controller
              control={control}
              name="passwordConfirmation"
              render={({ field }) => (
                <input
                  type={conPassWordShow ? 'text' : 'password'}
                  name="passwordConfirmation"
                  placeholder="Please enter Confirmation Password"
                  {...field}
                  disabled={!registeFormSubmitFlag}
                />
              )}
            />
            <img
              src="https://bigdaddygame.in/assets/png/eyeInvisible-2fa5c152.png"
              className={styled.eye}
              onClick={() => setConPassWordShow(!conPassWordShow)}
            />
          </div>
          <ErrorMessage
            errors={errors}
            name="passwordConfirmation"
            render={({ message }) => (
              <p className="text-red-500 text-[12px] mt-1">{message}</p>
            )}
          />
        </div>

        {/* -----------------remember ------------------ */}
        <div className={styled.signIn__container_remember}>
          <div
            role="checkbox"
            className={`${styled.van_checkbox} van-checkbox`}
            tabIndex="0"
            aria-checked="true"
          >
            <div
              className={`van-checkbox__icon van-checkbox__icon--round  `} //van-checkbox__icon--checked
            >
              <i className={`van-badge__wrapper van-icon van-icon_success ${watchRemember && styled.active_checkbox}`} onClick={() => setValue('remember', !watchRemember)}></i>
            </div>
            <span
              className={`${styled.van_checkbox__label} van-checkbox__label`}
            >
              Remember password
            </span>
          </div>
        </div>

        {/* ----------- dfdf ------------- */}
        <div className={styled.register__container_button}>
          <button type='submit' disabled={!registeFormSubmitFlag}>Register</button>
          <button className={styled.login} onClick={(e) => {
            e.preventDefault()
          }}>
            <div className={styled.account}>I have an account</div>
            <div className={styled.loginin}>Login</div>
          </button>
        </div>
      </div>
    </form>
  )
}

export default RegisterPage