import React, { useEffect } from 'react'
import AviatorCanvas from './layout-component/game-container/AviatorCanvas';
import AviatorInfoBoard from './layout-component/game-container/info-board/AviatorInfoBoard';
import BatButton from './layout-component/game-container/bat-button/BatButton';
import { useDispatch, useSelector } from "react-redux";
import { SHOW_MAIN_ERROR_MODAL } from '../../redux/errorMessageSlice';
import LastWinCardHistory from './layout-component/game-container/LastWinCardHistory';
import AvaiatorBatWinPopup from '../../comonComponent/success-message-popup/AvaiatorBatWinPopup';

const AviatorHeader = React.lazy(() => import("./layout-component/header/AviatorHeader"));

function Aviatorlayout() {
    const dispatch = useDispatch()



    return (
        <div className='w-[full] h-[100vh] bg-[#0e0e0e] max992:!h-auto'>
            <AvaiatorBatWinPopup />
            {/* header height 38px */}
            <AviatorHeader />
            {/* game container */}
            <div className='w-full h-[calc(100vh_-_38px)] flex flex-row max992:!flex-col-reverse max992:px-[5px] max992:!h-auto'>
                {/* info board md992:!min-w-[320px] md992:!max-w-[320px]  */}
                <AviatorInfoBoard />
                {/* game play */}
                <div className='w-full h-full flex flex-col justify-between overflow-y-hidden px-[10px] pb-[10px] md992:!w-[calc(100%_-320px)]'>
                    {/* result */}
                    <div className='min-h-[22px] max-h-[22px] flex justify-center items-center my-[10px] overflow-scroll'>
                        <LastWinCardHistory />
                    </div>
                    {/* stage board */}
                    <div className='h-full relative max992:h-[calc(40vh_-_38px)] max992:!min-h-[200px]'>

                        {/* play board */}
                        <div className='w-full h-full absolute z-0'>
                            {/* stage canvas */}
                            <AviatorCanvas />
                            {/* dom container */}
                            <div className='absolute w-full h-full top-0 left-0'>
                                {/* fun mode */}
                                {/* <div className='absolute top-0 left-0 bg-[#e59407cc] text-center text-sm font-bold w-full rounded-t-[20px] border border-[#e59407] text-[#fff]'>
                                    FUN MODE
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* bet control */}
                    <div className='flex justify-center flex-shrink-0 pt-[10px] max625:pt-[5px]'>
                        <div className='w-full flex justify-center max625:flex-wrap'>
                            <div className='h-[250px]  w-full max-w-[750px] min-w-[310px] rounded-[20px] mr-[5px]'>
                                <div className='flex relative w-full flex-col justify-center items-center rounded-[10px] bg-[#1b1c1d] h-full my-0 mx-auto '>
                                    <BatButton key={0} index={0} />
                                </div>

                            </div>

                            <div className='h-[250px] w-full max-w-[750px] min-w-[310px] rounded-[20px] mr-[5px] max625:mt-[5px]'>
                                <div className='flex relative w-full flex-col justify-center items-center rounded-[10px] bg-[#1b1c1d] h-full my-0 mx-auto '>
                                    <BatButton key={1}  index={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Aviatorlayout

