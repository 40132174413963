const NoticeBar = () => {
  return (
    <div class="noticeBar__container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.977 36.5356L41.976 36.5376C41.488 37.7866 40.241 38.3286 39.189 37.7476C38.141 37.1596 37.692 35.6806 38.171 34.4346C39.25 31.7086 39.804 28.5196 39.804 25.1886C39.804 21.3406 39.04 17.6176 37.646 14.6966C37.077 13.4856 37.437 11.9706 38.44 11.2946C39.445 10.6176 40.732 11.0446 41.302 12.2386C43.071 15.9616 44.014 20.4326 43.999 25.1886C43.999 29.2326 43.295 33.1696 41.977 36.5356ZM34.32 32.2426C34.095 32.2426 33.87 32.1896 33.646 32.1006C32.554 31.6596 31.969 30.2506 32.34 28.9526L32.341 28.9476C32.686 27.7366 32.866 26.4716 32.866 25.1706C32.866 23.6386 32.627 22.1786 32.148 20.7886C31.713 19.5066 32.222 18.0636 33.3 17.5466C34.38 17.0476 35.592 17.6536 36.027 18.9366C36.702 20.9136 37.046 23.0156 37.046 25.1886C37.046 27.0416 36.793 28.8406 36.298 30.5686C35.998 31.6016 35.188 32.2426 34.32 32.2426ZM27.461 41.2976C26.664 41.7546 25.802 41.9976 24.923 41.9976C24.114 41.9976 23.317 41.8086 22.575 41.4046C22.535 41.3786 22.481 41.3516 22.44 41.3246L13.396 34.4406H10.278C7.364 34.4406 5 33.0836 5 30.1766V18.7326C5 15.8386 7.362 14.4826 10.264 14.4826H13.382L22.44 7.66557C22.481 7.63857 22.522 7.62557 22.561 7.59857C24.088 6.76357 25.945 6.80457 27.435 7.70657C29.027 8.66257 29.984 10.3716 30.012 12.2706V36.7336C30.012 38.6456 29.055 40.3546 27.461 41.2976Z"
          fill="url(#paint0_linear_235_31)"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_235_31"
            x1="5"
            y1="7"
            x2="5"
            y2="40.9996"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAE59F"></stop>
            <stop offset="1" stopColor="#C4933F"></stop>
          </linearGradient>
        </defs>
      </svg>
      <div class="noticeBar__container-body">
        <div class="noticeBar__container-body-text">
          Please Fill In The Correct Bank Card Information. The Platform Will
          Process Withdrawals Within 1-24 Hours Or More. The Withdrawal Status
          Is "Completed" And The Transaction Has Been Approved By The Platform.
          The Bank Will Complete The Transfer Within 1-7 Working Days, But
          Delays May Occur, Especially During Holidays. But You Are Guaranteed
          To Receive Your Funds.
        </div>
      </div>
      <button>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_235_37)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.4481 16.0478C20.874 14.8905 21.0547 13.6797 20.9857 12.4515L20.9287 11.4448C20.9182 11.2994 20.7615 11.2118 20.6303 11.2798L19.723 11.7474C19.1553 12.0426 18.4527 12.2279 17.6444 12.2967C17.5904 12.3003 17.5544 12.28 17.5327 12.2627C17.5102 12.2453 17.4832 12.212 17.4809 12.1599C17.4764 12.1128 17.4952 12.0665 17.5297 12.0347C18.6754 10.9773 19.561 9.39667 20.1594 7.33113C20.6588 5.61661 20.6835 3.93176 20.2404 2.32001L19.9157 1.14539C19.8737 0.991232 19.6705 0.948532 19.5647 1.07157L18.7452 2.00518C18.1843 2.64207 17.5957 3.11684 16.9988 3.40922C16.2677 3.76675 15.4871 4.00124 14.675 4.1069C14.1051 4.17928 13.5353 4.19013 12.9751 4.13585C11.6217 4.00269 10.23 4.18145 8.95373 4.64826C7.6655 5.12158 6.4815 5.8967 5.5247 6.89183C4.67963 7.77261 4.03176 8.78656 3.5991 9.91197C3.18294 10.9968 2.98048 12.1374 3.00148 13.299C3.02473 14.4614 3.26542 15.5882 3.72208 16.647C4.19523 17.7442 4.87684 18.7198 5.75115 19.5499C6.62472 20.38 7.63551 21.0126 8.75727 21.4331C9.84229 21.8391 10.9851 22.0302 12.1548 21.9961C13.3231 21.9657 14.4606 21.7132 15.5298 21.2485C16.6396 20.7665 17.6287 20.0775 18.4752 19.1967C19.348 18.2877 20.0132 17.2289 20.4481 16.0478Z"
              fill="#292929"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_235_37">
              <rect width="24" height="24" rx="10" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
        Detail
      </button>
    </div>
  );
};

export default NoticeBar;
