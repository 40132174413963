import { createSlice } from "@reduxjs/toolkit";


export const SelectLoginFormError = ({ errorMessageSlice }) => errorMessageSlice.LoginFormError
export const SelectMainErrorModal = ({ errorMessageSlice }) => errorMessageSlice.MainSErrorPopup


const errorMessageSlice = createSlice({
    name: "errorMessageSlice",
    initialState: {
        LoginFormError : null,
        MainSErrorPopup : {show:false,message:''}
    },
    reducers: {
        SET_LOGIN_FORM_ERROR: (state, { payload }) => { state.LoginFormError = payload },
        SHOW_MAIN_ERROR_MODAL: (state, { payload }) => { state.MainSErrorPopup = {show:true,message:payload} },
        HIDE_MAIN_ERROR_MODAL: (state, { payload }) => { state.MainSErrorPopup = {show:false,message:''} },
    }
})





export const {
    SET_LOGIN_FORM_ERROR, SHOW_MAIN_ERROR_MODAL, HIDE_MAIN_ERROR_MODAL

} = errorMessageSlice.actions;

export default errorMessageSlice.reducer;