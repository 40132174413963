import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SocketState } from "./SocketProvider";
import { SET_MAIN_LOADER, SelectGameIconList } from "../redux/loaderSlice";
import { SET_ACTIVE_GAME_BASE_URL, SelectActiveGameBaseUrl } from "../redux/socketStateSlice";
import { SHOW_MAIN_ERROR_MODAL } from "../redux/errorMessageSlice";
import { SET_GET_OTP_FLAG, SET_REGISTER_FORM_SUBMIT_FLAG, SET_VERIFY_OTP_FLAG } from "../redux/registerPageSlice";
import { SET_GAME_ID, SET_LAST_TICKET_ID, SET_LAST_WIN_CARD, SET_NEXT_ROUND_FLAG, SET_PLACE_BET_FLAG, SET_USER_WALLET, SET_WIN_AMOUNT, SET_WIN_CARD } from "../redux/comanGameInfoSlice";
import { RESET_BAT_SUCESS_RESPONSE, RESET_CASH_IN_INFO_USER, RESET_CASH_OUT_Index, RESET_CASH_OUT_SUCESS_RESPONSE, SET_BAT_SUCESS_RESPONSE, SET_CASH_IN_INFO_USER, SET_CASH_OUT_SUCESS_RESPONSE, SET_CRASH_PLAN, SET_CRASH_REWARD, SET_DEFAULT_PLAN_POSITION, SET_FLY_PLAN, SET_FLY_TIMER, SET_PANDING_BAT, SET_PER_SEC_REWARD, SET_TOTAL_TIME, UPDATE_CASH_IN_INFO_USER } from "../redux/AviatorGameSlice";

import { AviatorPreload } from "../game-page/preload-game-state/AviatorPreload";
import { SET_BAT_BUTTON_DISABLE, SET_HISTORY_RECORD_STATE, SET_SECOND_TIMER, SET_LAST_WIN_CARD as SET_COLOR_LAST_WIN_CARD } from "../redux/colorGameSlice";
import { SHOW_MAIN_SUCCESS_MODAL } from "../redux/successMessageSlice";
import moment from "moment";






const AuthContext = createContext();

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  let { socket } = SocketState();
  let navigate = useNavigate();
  const chanelBaseUrl = useSelector(SelectActiveGameBaseUrl)
  const GameIcon = useSelector(SelectGameIconList)

  const [userDetail, setuserDetail] = useState(null);

  


  useEffect(() => {
    if (localStorage.getItem("gametoken")) {
      //   token code
    } else {
      if (!userDetail) {
        navigate("/color");
        // navigate("/aviator");
      }
    }
  }, []);

  // socket state

  useEffect(() => {
    // disconnect code
    socket.on("disconnect", function () {
      if (
        !window.location.pathname.includes("/login") &&
        localStorage.getItem("creditionls")
      ) {
        socket.connect();
        dispatch(SET_MAIN_LOADER(true));
        let creditionls = localStorage.getItem("creditionls");
        var data = {
          en: "AppLunchDetails",
          data: JSON.parse(creditionls),
        };
        socket?.emit("req", data);
      } else {
        socket.connect();
        setuserDetail(null);
        navigate("/login");
      }
    });

    socket.on("res", (socketData) => {
      if (
        socketData.err &&
        socketData.msg === "User session not set, please restart game!"
      ) {
        socket.disconnect();
      }
      if (socketData.en === "hb" || socketData.en === "CRASH_USER_CASH_OUT" || socketData.en === "CRASH_CASH_IN_INFO" || socketData.en === "CRASH_PLAN_START_TIMER") {
      } else {
        console.log("socketData",socketData)
        
      }

      
      // OSR
      if (socketData.en === "OSR") {
        localStorage.removeItem("creditionls");
        localStorage.clear()
        socket.disconnect();
      }

      if (socketData.en === "OTP_SEND") {
        if (socketData.err) {
          dispatch(SET_MAIN_LOADER(false));
          dispatch(SHOW_MAIN_ERROR_MODAL(socketData.msg));
          return;
        }
        dispatch(SET_MAIN_LOADER(false));
        dispatch(SET_GET_OTP_FLAG(false))
        dispatch(SET_VERIFY_OTP_FLAG(true))

      }

      if (socketData.en === "VOTP") {
        if (socketData.err) {
          dispatch(SET_MAIN_LOADER(false));
          dispatch(SHOW_MAIN_ERROR_MODAL(socketData.msg));
          return;
        }

        dispatch(SET_VERIFY_OTP_FLAG(false))
        dispatch(SET_REGISTER_FORM_SUBMIT_FLAG(true))

      }

      // AppLaunch Detail
      if (socketData.en === "AppLunchDetails") {
        if (socketData.err) {
          dispatch(SET_MAIN_LOADER(false));
          dispatch(SHOW_MAIN_ERROR_MODAL(socketData.msg));
          return;
        }
        dispatch(SET_MAIN_LOADER(false));
        setuserDetail(socketData.data);
        localStorage.setItem("token",socketData.data.relogin_token)
        dispatch(SET_USER_WALLET(socketData.data.chips))
        let gameObj = localStorage.getItem('gameObj')
        if(gameObj){
          
          let obj = JSON.parse(gameObj)
          dispatch(SET_ACTIVE_GAME_BASE_URL(GameIcon[obj.id-1].socketBaseUrl))
          GameIcon[obj.id-1].preloadFunction(dispatch).then((res)=>{
            socket.emit('req', { "en": `${GameIcon[obj.id-1].socketBaseUrl}GAME_INFO`, "data": {} })
            navigate(GameIcon[obj.id-1].navigationPath)
          })
        }else{
          navigate('/color/dashboard')
        }
      }

      // Game Info
      if (socketData.en === `${chanelBaseUrl}GAME_INFO`) {
        dispatch(SET_GAME_ID(socketData.data.game_id))
        dispatch(SET_WIN_CARD(socketData.data.win_card))
        dispatch(SET_LAST_WIN_CARD(socketData.data.last_win_cards))
        dispatch(SET_LAST_TICKET_ID(socketData.data.ticket_id))
        dispatch(SET_USER_WALLET(socketData.data.total_wallet))
        dispatch(SET_WIN_AMOUNT(socketData.data.win))
        
        if (socketData.data.game_state === "start_plan") {
          dispatch(SET_FLY_PLAN(true))
          dispatch(SET_PLACE_BET_FLAG(false))
          dispatch(SET_NEXT_ROUND_FLAG(true))
          dispatch(SET_TOTAL_TIME(socketData.data.timer))
        } else if (socketData.data.game_state === "bet_timer_start") {
          dispatch(SET_NEXT_ROUND_FLAG(false))
          dispatch(SET_PLACE_BET_FLAG(true))
        } else {
          dispatch(SET_DEFAULT_PLAN_POSITION(true))
          dispatch(SET_NEXT_ROUND_FLAG(true))
          dispatch(SET_PLACE_BET_FLAG(false))
        }

        dispatch(SET_MAIN_LOADER(false))
      }

      if (socketData.en === `${chanelBaseUrl}PLAN_START`) {
        dispatch(SET_FLY_PLAN(true))
        dispatch(SET_CRASH_PLAN(false))
        dispatch(SET_PLACE_BET_FLAG(false))
        dispatch(SET_NEXT_ROUND_FLAG(true))
        // dispatch(SET_PANDING_BAT([null,null]))

      }

      if (socketData.en === `${chanelBaseUrl}PLAN_START_TIMER`) {
        dispatch(SET_CRASH_REWARD(socketData.data.reward))
        dispatch(SET_FLY_TIMER(socketData.data.time))
      }

      if (socketData.en === `${chanelBaseUrl}PLAN_CRASH`) {
        
        dispatch(SET_CRASH_PLAN(true))
        dispatch(SET_FLY_PLAN(false))
        dispatch(SET_LAST_WIN_CARD(socketData.data.last_win_cards))
        dispatch(RESET_BAT_SUCESS_RESPONSE([null,null]))
      }

      if (socketData.en === `${chanelBaseUrl}INIT_GAME`) {
        dispatch(SET_DEFAULT_PLAN_POSITION(true))
        dispatch(SET_CRASH_REWARD(0))
      }
      if (socketData.en === `${chanelBaseUrl}GAME_TIMER_START`) {
        dispatch(SET_DEFAULT_PLAN_POSITION(false))
        dispatch(SET_PLACE_BET_FLAG(true))
        dispatch(SET_NEXT_ROUND_FLAG(false))
        dispatch(RESET_CASH_IN_INFO_USER())
        dispatch(SET_TOTAL_TIME(socketData.data.timer))
      }
      if (socketData.en === `${chanelBaseUrl}CASH_IN`) {
        
        if (socketData.err) {
        dispatch(SHOW_MAIN_ERROR_MODAL(socketData.msg));
        return
        // dispatch(SET_BAT_SUCESS_RESPONSE(socketData.data))
        }
        
        dispatch(SET_BAT_SUCESS_RESPONSE(socketData.data))
        
      }

      if(socketData.en === `${chanelBaseUrl}CASH_IN_INFO`){
        dispatch(SET_CASH_IN_INFO_USER(socketData.data))
      }
      if(socketData.en === `${chanelBaseUrl}CASH_OUT`){
        if (socketData.msg) {
          return
        }
        let btnIndex = socketData.data.button_index
        console.log('btnIndex: ', btnIndex);
        dispatch(SET_CASH_OUT_SUCESS_RESPONSE(socketData.data))
        setTimeout(() => {
          dispatch(RESET_CASH_OUT_Index({btnIndex}))
        }, 2000);
      }
      if(socketData.en === `UPDATED_WALLET`){
        dispatch(SET_USER_WALLET(socketData.data.chips))
      }
      if(socketData.en === `${chanelBaseUrl}USER_CASH_OUT`){
        
        dispatch(UPDATE_CASH_IN_INFO_USER(socketData.data))
      }


      // color game

      if (socketData.en === `${socketData.en.split('_')[0]}_SHOP_METALY_HISTORY`) {
          dispatch(SET_HISTORY_RECORD_STATE({ ...socketData.data }))
      }
      if (socketData.en === `${socketData.en.split('_')[0]}_SHOP_MY_METALY_HISTORY`) {
          dispatch(SET_HISTORY_RECORD_STATE({ ...socketData.data }))
      }

     if (socketData.en === `${socketData.en.split('_')[0]}_SHOP_GAME_INFO`) {
      dispatch(SET_COLOR_LAST_WIN_CARD(socketData.data.last_win_cards)); //disable bat button

      if(socketData.data.timer > 0){
        dispatch(SET_SECOND_TIMER(socketData.data.timer)); // updateTimer
      }else{
        dispatch(SET_SECOND_TIMER(null)); // updateTimer
      }
      SET_USER_WALLET(socketData.data.total_wallet); //updatewallet
      SET_GAME_ID(socketData.data.game_id)
    } 

    if (socketData.en === `${socketData.en.split('_')[0]}_SHOP_GAME_TIMER_START`) {
      dispatch(SET_SECOND_TIMER(socketData.data.timer)); // updateTimer
      dispatch(SET_BAT_BUTTON_DISABLE(false));
      // fetchMyMetalyHistory(`${socketResponse.en.split('_')[0]}`)
      // fetchMetalyHistory(`${socketResponse.en.split('_')[0]}`)
    }

    if (socketData.en === `${socketData.en.split('_')[0]}_SHOP_LOCK_BET`) {
      dispatch(SET_BAT_BUTTON_DISABLE(true)); //disable bat button
      dispatch(SET_SECOND_TIMER(socketData.data.time));
    }
    
    if (socketData.en === `${socketData.en.split('_')[0]}_SHOP_WINNER_DECLARE`) {
      dispatch(SET_COLOR_LAST_WIN_CARD(socketData.data.last_win_cards)); //disable bat button
      fetchMetalyHistory(socketData.en.split('_')[0])
      fetchMyMetalyHistory(socketData.en.split('_')[0])
    }

    if(socketData.en === `${socketData.en.split('_')[0]}_SHOP_PLACE_BET`) {
      if(socketData.err){
        dispatch(SHOW_MAIN_ERROR_MODAL(socketData.msg));
      }
      fetchMetalyHistory(socketData.en.split('_')[0])
      fetchMyMetalyHistory(socketData.en.split('_')[0])
      dispatch(SHOW_MAIN_SUCCESS_MODAL(`You Bet Rs. ${socketData.data.total_bet_amount} and Ticket is : ${socketData.data.ticket_id} and Time : ${ moment(socketData.data.draw_time).format('DD-MM-YYYY, hh:mm:ss A')}`))
    }

    });


    return () => {
      socket.removeAllListeners("res");
      socket.removeAllListeners("disconnect");
    }
  }, [chanelBaseUrl]);

  useEffect(() => {
    let id = setInterval(() => {
      socket?.emit("hb", {});
    }, 2000);

    let siteTitle = '';


    return () => {
      clearInterval(id);


    };
  }, [socket]);

  let fetchMetalyHistory = (activeGame) => {
    socket.emit('req', {
      "en": `${activeGame}_SHOP_METALY_HISTORY`,
      "data": {
        "page":  1
      }
    })
  }

  let fetchMyMetalyHistory = (activeGame) => {
    socket.emit('req', {
      en: `${activeGame}_SHOP_MY_METALY_HISTORY`,
      data: {
        page:  1,
      },
    });

  }


  let state = {
    userDetail,
    setuserDetail,
  };
  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}

let AuthState = () => {
  return useContext(AuthContext);
};

export { AuthState };
export default AuthProvider;
