import "./Home.css";
import MenuBar from "./MenuBar";
const Home = () => {
  return (
    <>
      <div class="userInfo__container">
        <div class="navbar" style={{ display: "none" }}>
          <div
            class="navbar-fixed"
            style={{
              background:
                "linear-gradient(90deg, rgb(196, 147, 63) 0%, rgb(250, 229, 159) 100%)",
            }}
          >
            <div class="navbar__content">
              <div class="navbar__content-left">
                <i class="van-badge__wrapper van-icon van-icon-arrow-left"></i>
              </div>
              <div class="navbar__content-center">
                <div class="navbar__content-title">Settings Center</div>
              </div>
              <div class="navbar__content-right"></div>
            </div>
          </div>
        </div>
        <div class="userInfo__container-content">
          <div class="userInfo__container-content-wrapper">
            <div class="userInfo__container-content__avatar">
              <img src="/images/1-c7e4efc8.png" class="userAvatar" />
            </div>
            <div class="userInfo__container-content__name">
              <div class="userInfo__container-content-nickname">
                <h3>MEMBERNNGJQZSG</h3>
                <div class="n0"></div>
                <img
                  class="editPencil"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////8IN+deAAAAGnRSTlMAZjN/DllfHwa/Jm8sPxnvn0oT38+PTjhAr4Ga/9wAAAE+SURBVFjD7dfBboMwEEVRG8YGEwiBBJL6/z+0JU31FAQRnpcuWnE33h15xmwwe/+9Muu8dV3FMo195Cjq6C3qRO1k9iknrAOJdSCRDqSSc9A11SnsSsfUh1+DukSo9GtS6paua1Cx3TjIq9nqzU4eD9MxrkDZdieG6RTlaHC+al5sqU9w4uX+cG7JcSlO/N5S7xegKsmJ7X0TlfJCcB77NrWdJxsdNCxKdZqDNZnepX1DcJA3U3LjHNzJVE7poNyA0jho+Hmk4uY0Djo3BmkcdCpoBxTpoFCQDl5vQ55zUOAc1L7JEc5BDeegkXNQzjnog3NQ4BzUvskRzkEN56CRc1BOOoA4B3nOQUI6aOAcJCGi8+WUezHKfGgn4eAzMXu/VllnqiqZQc4qc/NfMnX982R6aDZbppzN12bv7/YJ4RFYNVkqAPgAAAAASUVORK5CYII="
                  style={{ display: "none" }}
                />
              </div>
              <div class="userInfo__container-content-uid">
                <span>UID</span>
                <span>|</span>
                <span>3290222</span>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAG1BMVEUAAAD////////////////////////////////rTT7CAAAACHRSTlMAf2/fzyAQv1i/o5gAAABWSURBVBjTY0AHSYJgoApis1p0gEFzApDD1AKR8VAAchgbIaolBIA6xNvBOkAchA4gB6EDyEHooBOn0EMBweloDkCSUUP2ALrX2GBeCADJB0Fk1NCDCQANHCEHp1BAOwAAAABJRU5ErkJggg==" />
              </div>
              <div class="userInfo__container-content-logintime">
                <span>Last login:&nbsp;</span>
                <span>2024-04-02 13:56:42</span>
              </div>
              <div
                class="userInfo__container-content-logintime"
                style={{ display: "none" }}
              >
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
          <div
            class="userInfo__container-content-right"
            style={{ display: "none" }}
          >
            <h5>Change avatar</h5>
          </div>
        </div>
        <div
          class="userInfo__container-setting-center"
          style={{ display: "none" }}
        >
          <div class="userInfo__container-setting-center-header">
            <div class="userInfo__container-content__avatar">
              <img
                data-img="/images/avatar1-2f23f3bd.png"
                class="ar-lazyload"
                data-origin="/images/1-c7e4efc8.png"
              />
            </div>
            <div class="userInfo__container-setting-center-header-edit">
              <span>Change avatar</span>
              <i
                class="van-badge__wrapper van-icon van-icon-arrow"
                style={{ color: "rgb(136, 136, 136)" }}
              ></i>
            </div>
          </div>
          <div class="userInfo__container-setting-center-content ar-1px-b">
            <h5>Nickname</h5>
            <div>
              <span>MemberNNGJQZSG</span>
              <i
                class="van-badge__wrapper van-icon van-icon-arrow"
                style={{ color: "rgb(136, 136, 136)" }}
              ></i>
            </div>
          </div>
          <div class="userInfo__container-setting-center-content">
            <h5>UID</h5>
            <div>
              <span>3290222</span>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGDSURBVHgB7ZdRToNAEIb/mfjQNzkCR+AI9AaliVZ8qTeoPUJPYD2BT4pWU+oJ5Aj1BhyhjyamO+62llI0AVIsNeF7WjYDfDCz2VmgYRcqEhSGofXxqTpKYKMCmDnyz7wIZUSCILSF5U0PbVQIg8YXPW9YXGQS3onIlR7OBfKKPSEhSxh9ElikqO376z9zkn+r2KsHKBpe+t0IFXD/ODW/YKBYufoyMnOMOiBZZKfqEfmFndSYwiTGQEiczZwCHFNIumBvgsl0gbIsabSpg8IiZnWIWR2ynUtVsyOC8rC4+gPbeTJJah6eXjqoeIkmkFznhSQiAnLwVzBO80OOhEYkSyOSpRHJ0ohkaUSyFOjQ9kfv2lbwHLqrsVpaehfsr1/O84OKwLQQatWAI5WEuNfzZgcVEcJCvz75elF41030OB1zEBEj4Z932zkxx0EiQsLl+9EKSYlsc1g9FOdFJCKmudXL7BbVE2OJUV7QjyOn7rjd7xPY3rBC3GrxzPO8WtP+P/kC9A55nxt0GWAAAAAASUVORK5CYII=" />
            </div>
          </div>
        </div>
        <div class="info-dialog"></div>
        <div class="info-dialog"></div>
      </div>
      <div className="userinfo-content">
        <div class="totalSavings__container">
          <div class="totalSavings__container-header">
            <div class="totalSavings__container-header-box ar-1px-b">
              <div class="totalSavings__container-header__title">
                <span>Total balance</span>
              </div>
              <p class="totalSavings__container-header__subtitle">
                <span>₹0.00</span>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAOVBMVEUAAACjo6OgoKCjo6OhoaGjo6Ofn5+np6ejo6Ojo6OlpaWioqKkpKSjo6Ojo6Ojo6Ojo6OkpKSjo6PuE3LfAAAAEnRSTlMAf0C/H98QIM/vMGCfj29Qr1+b1SuLAAAA4UlEQVQ4y+3Sy3aEIBBF0eJR8hCx+/7/x8amdCVCZYUMetZnpm4BBfr05hbblX+lEX2pBJ1uUDKTK1ojUGgyA2z/sHmcbVetG4YNDHBQaEXqxjykbj3gbzceSIdUbUcN8ARyQlz+oBUwHqC7dTF8000ehISNXpQsflh5T6gBrHw8L0JpBR6nlDmE7ucOZ7CnRsVe9AVio4GvAexCjcqFPaG8Vw6a27RXQrvEAg7sh189VNHa7/sFWP24AEY5F0qri3VcVwo0GYNnbU7g56xlgIvp0mfyBWNMer4W11Xp03v7Att9D7xKb3uAAAAAAElFTkSuQmCC" />
              </p>
            </div>
          </div>
          <div class="totalSavings__container-content">
            <div class="totalSavings__container-content-item">
              <div>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAeFBMVEUAAAD/oKD/oKD/oaH/n5//n5//oKD/oaH/n5//n5//oKD/n5//oKD/n5/+i4v/n5/+iIj/n5//oaH/n5//n5//oaH/n5/5WVn9hYX6a2v7c3P////8i4v7eXn6YmL5XV3+lpb+j4/+9fX+6ur+m5v8trb7f3/6Zmaz6OagAAAAFnRSTlMA359fIBBvP++vjzC/f9/Pv49/YFBPchRNZwAAANhJREFUSMft1bkSgjAUheEEw467XoQQdvX931CLFBdDSFJYMMNfn2+YYQlkyz7/mnjaLqmyv0WwmMem+wBMUR/vWQTGzhgkYBG+RGYDdgjQtYCxEiXq5AVsCYyiySc9AGiqB20tdxgAxUDdqwBCHSjzebDTgCp3AfgCtSWQu6rnTzsgNwPn/OUC+i+orIB8ZsD5kFuBQo6EqP90W+HtCrrG7dXAAoPI1wJoixkQLH5AnShwhywOydoOgZ/urkclQyAEczHB7cEUZcRJUOUfxLwj1RYHPtly6gOS6nMolZZu1QAAAABJRU5ErkJggg=="
                  alt=""
                />
                <span>Wallet</span>
              </div>
            </div>
            <div class="totalSavings__container-content-item">
              <div>
                <img src="/images/rechargeIcon-c2eb6382.png" alt="" />
                <span>Deposit</span>
              </div>
            </div>
            <div class="totalSavings__container-content-item">
              <div>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKgSURBVHgB7ZrPbxJBFMe/M/wQqmnw5kHjYmyQg4me9Ajx4smCP87tv8Bf0PAXEP8C6c2DttujJ/kHjCSNYsTYPdiDJtZNkxbTZXecRYNNeTsshAZ2M5+EMHnvzWS/mffmkVkAjUaj0Wg084JhQhqVXA78qCKHOTk9h3nB0EaKt2svf1uTTQtJ42mmBCE25KeERYKxFoS3WdtymqHCxwUMdjRx/AICFSwyDE254/VxO64U3KhkDHDvrRwaiAYW0rysEs2V06Ml1seA422rAhJBjsbj9Ib8Wuw0prnysJhgbzpui3KSKf0vlfcQXWx4S/maadtnHXRKc7eEaCNbZ2+dctCCGV9D1GFYpcy0YCHuIOoIYVDmoFN6fr+gZodBGdVtKYZowXEnSRlXbq8gFmx9GDEFCL6JeDAqWNdw3NGC444WHHe04LijBccdLTjuaMFxR18AhMFx+rA+7eH4qDfiS6VTMAoGli5mh7bubpeMpaDmz5KpBHfefcS3r/uB/sNfh7j/4N5g7Md1d79gEvblnPKjEpLpqR5PyVQp/fP7gdLfP+kPxwc/1LEUzomD8yJIsAUFxi0j0JeUKXn6isgoXEf20mTp6a8/g921KSO5KgOzBOibe5+8rLFrN66SO+HXYDL1f9nly8uD9OyFrGGf7AzqV74lbFN2UrDHsMMESlDgizotbBzZczqEgnAZ26TsZEpf4GgiICUiA0eLNhPkq6YNwZ4jonhC1ItV06J8yv94fH61+l4A0XqTyGAVnuzkg9zKtuQmWNVfAFFBPqvHWVkVohTsp8XfBegDYKFgzExzdjcolYdhCEnndWU9IbAm21UJC4RsoS1XoF58ZrbCxU9IZ7ticFfWtZhfbQsOW7iwMymYgwNWo9FoNBpNJPgDNMGzUq2rkHsAAAAASUVORK5CYII="
                  alt=""
                />
                <span>Withdraw</span>
              </div>
            </div>
            <div class="totalSavings__container-content-item">
              <div>
                <img
                  alt=""
                  class=""
                  data-origin="/images/VipIcon-1bb97a71.png"
                  src="/images/VipIcon-1bb97a71.png"
                />
                <span>VIP</span>
              </div>
            </div>
          </div>
        </div>
        <div class="financialServices__container">
          <div class="financialServices__container-footer"></div>
          <div class="financialServices__container-box">
            <div>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAtFBMVEUAAABcp/9cp/9epv9dn/9cpv9cpv9cpf9cp/9bpv9cpv9dp/9cpP9bp/9eqP9apf9Sn/9dpv9Jmv9eqP9ElP9cp/9cpv////82if+t0v/B3f+Zx//W6P/m8f/N4f/0+f9Il/9Ckv9wsf9Rnv9Qmf9Lmf/r9P/a6f+00/86jf+Duf9ao/+jzf9Xo/8+j/+/2v+32P+Pwv9mrP9op//g7v+31/98tv91rv9mq//N4/+jzP+Nvf89OsrBAAAAFnRSTlMAf78gEN/vz29fr58/n28wz49vT09A/ixTGwAAAdVJREFUWMPtlttygjAQhkHAQ221xwSlCSogKJ6t1bbv/16Ngc7KTIBlai+c+t3JzH4uu3802pXzYnbuaoXcdIyicr1OymnnKowGQWHlGaC+zGAq61sETVspsAgeVQsvpAJNhaBZRXCnEOhVBDe/FdSKBdOPVxUOVjCiOfSQgl6eYIwUzPIEb0jBZkyVuOsSAbB2VBzKhljKfxKMVn0FK3wSXarm8+KTSB30EGc9FdMLysEfCuZeMAi8UsHBgcmt4XE8mNgJw/viJGbW2Ccpng0s2gY+SO9EMrAlX+H2KCCWgY7yFOoFjC6P70CEAXGY4AAEstqPmHjCWBjDn7wyiTs4xLuRnP5E1ruZ19IrrHGYtk9Dl7qcRwER1E28QDYQUxoJCU9mIGiiBLDBrRhoRlDDCWCElIb+qaCOE8AOOZWEkZ8IiIkVDBOB+PoEFhCJge8gZboUWxCaFXSAn8FPEvmQUbfiDLyMwF5SuoGbEioHGYHP0ny3cNc8GMKWMXZy2bG0DLdFglhGMaIp3IMGgBopm4LPORdbiPaTOeQQ1wJsUiZxoL70P2AMTri3F+JjA+oxi4DfBMEiJnVdU9KyChWejHRAGrqp5dF90gt5fL7tmtqVs/MN94DbsbuNvhYAAAAASUVORK5CYII=" />
              <div class="financialServices__container-box-para">
                <h3>Bet</h3>
                <span>My betting history</span>
              </div>
            </div>
            <div>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAArlBMVEUAAABK461L46xE36RL4q1L465K4q1O4a9K4a1K46xK4qxK4a1K46xL4qxK4q1L4axK4q1K361M4q0T0IE53J8u2JUg2I5L4qz///8GzHal8NW79N+O7MvS9+rg+e4X0oTp/PUo15Al0odi5rdE4KY33J0l04ch1YwLznph5rYQ0H6w8tqa79CC5bpW5LEx2Zcs2JT0/vqD68Z46cHk+vHg+e+P7cvH9uWa7tBt57tTDyxnAAAAF3RSTlMAf78Qn28/H3+vj99g79/PTzDv779vT8FjD08AAAGZSURBVFjD7ZZpb4JAEIaVS8Gj2msArYoCWrxQe/7/P1aWjQTbYXcItompzxcTk3kyzLwLW7tyXlRdU4SYekNU3rwFOVqhonEDJFpFBlZPM6hofRvIaKigC3SwFgwogY4IdIBqz1CHEphVBYpYcHh/wnijCmZ2AS5R4BYJJkTBqkjwShRsJnj9bk8QcPZDjI1siFL+k2C2HCAs6Unc2Tiri0+iPSQP8cPFOFxQDn5R4AdbbxtIBZuhm7HPVXuRw5nfi5N4ssbB8e9g4WTcNUsE6RNSPCfHGDSVHOXDaX24DpkATOph4gcgdo6M7FHIBGDRjvPyOZ3fIidIDEwA/RJrnDsZU2bY8jDQBayBcGRnvKR9qWRB4CSsf3zl2mRBzHvPTTbtwEIEshFkOWCYVQXK3wliB92CVXULvRI5QLeg0q95XpbBkL8QkLueAQL8KDOkAhblzvc7twLCKXADF0Q+dls1QITHDesp+/EAAHknWXIDJ8LqGU2QzIEz9qFl1FDaXRARjJN1LuKOwstR+r26kIdHo1G7cn6+AEIy4spd9Gx2AAAAAElFTkSuQmCC" />
              <div class="financialServices__container-box-para">
                <h3>Transaction</h3>
                <span>My transaction history</span>
              </div>
            </div>
            <div>
              <img src="/images/rechargeHistory-a584338d.png" />
              <div class="financialServices__container-box-para">
                <h3>Deposit</h3>
                <span>My deposit history</span>
              </div>
            </div>
            <div>
              <img src="/images/myWithdrawHistory-4b3f11ea.png" />
              <div class="financialServices__container-box-para">
                <h3>Withdraw</h3>
                <span>My withdraw history</span>
              </div>
            </div>
          </div>
        </div>
        <div class="settingPanel__container">
          <div class="settingPanel__container-items">
            <div class="settingPanel__container-items__item ar-1px-b">
              <div class="settingPanel__container-items__title">
                <img src="/images/notifyIcon-eb7c8400.png" />
                <span>Notification</span>
              </div>
              <div class="settingPanel__container-items-right">
                <h5>2</h5>
                <span style={{ display: "none" }}>English</span>
                <i
                  class="van-badge__wrapper van-icon van-icon-arrow"
                  style={{ color: "rgb(166, 169, 174)" }}
                ></i>
              </div>
            </div>
            <div class="settingPanel__container-items__item ar-1px-b">
              <div class="settingPanel__container-items__title">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMOSURBVHgB7ZrPThNBHMe/s+0ifw42mNiDkmyNgD0A9cZBY3v0YrYRjTd5A8Ij+ASNTyDcTIzp+gCGesOTVQ5NJMiSQCIHSA9GhLI7zq40QmDame0u6S7zSZp0O7+d3c/89s9MZwCFQqGIEQQhUTEzGaQOqqC0yGqtQ9fKi2//2AhAxRw0kKJvTupagjO8uGg1mwiBUIT9E9TcFfbVOPWzjQGtJCt9YV1eAzrDpTCkexbmyLaRku5YV0jSPQl3kW0jJC1UVwjSGnqhuyz88iN3pfJikBsn2HAARQGp3yvogcDClbnBIrrLtuFKC8u2YdIVc6SAgPSWYTnOSUvLtkk7GQSk6z28WTUzx8coOBoKxMWZA200NhZcx5U6uH5Nt41xY9n7bq/bL1uHLSPo/qdhmauNP7Nq3fbvKPy9ahaoQ70MBG7RS4XAJg4pTzy36vwQDo2qaWgO/YK4yP6nOZAiuVzZuvBJzr2H2eVrIn6yHpnDY8zzCvnCoI8QX4q8Ar4wJXHMro9G6HVuGa4YSjjpcIUpsIWY4oJ+5ZVxhV3KBt4xhVLN4pVxhfNeN43SZcQMCvo636GL2bUv3XhvzqcoFlhFgUcol0CTgNQdguX8U2upU6DQHwAn3cxNSPBtdQ3bP7YRhOnZady+c0tmF3ty7kNOJFA9pZOOEk46SjjpKOGko4STjhJOOmlERFo/X3V6QEdu0kDunoHWUYsNLnawvrZ+Lk7XdUR2XogIT2p3ZxcHvw787dHsDczMTmFoZOjfgVmDjE/d9UdFqx8/n4nLjt1EVEQm7Ik9fPwAez/3oLPMjmZHuXGlJ0Xs7+7727y4sIhM2K+cZTE7lhWKjVq0jXpKJx0lnHSEhPNly0ZCkMlwKCvhIkL43BIhTCkRnhaSECaf0L/URAOFhft5rommYYnGCgv78zUENvoMQsiSzENV6rVENFJGP93LLAGOhlcyu0gJT5Stupsi9/sh02zyrOZqpCT7ygy8mrbxziwSApO12AybWTQgu3xQHptdv3VKscU+Vl5g1Z1CoVDEnr+42gd6kDDMugAAAABJRU5ErkJggg==" />
                <span>Gifts</span>
              </div>
              <div class="settingPanel__container-items-right">
                <h5 style={{ display: "none" }}>2</h5>
                <span style={{ display: "none" }}>English</span>
                <i
                  class="van-badge__wrapper van-icon van-icon-arrow"
                  style={{ color: "rgb(166, 169, 174)" }}
                ></i>
              </div>
            </div>
            <div
              class="settingPanel__container-items__item ar-1px-b"
              style={{ display: "none" }}
            >
              <div class="settingPanel__container-items__title">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJGSURBVHgB7dq/btNAHAfw712c0ikKXRgxA4EwRTxBmBjjioJgql/BK0vDEzQ8QdMVNa3zBPAGjcRQJUWqFxYYKBJQ/iT+cWdCxUAa+5qkzun3kSI5ih3lG9/57LsfwBhjS0Qgo21v1YUceWrzNNj/1caSyRR420MZcuVQbbrjw4Ng/0cLS0Rm2ttZreE8rIor0MCSyRbYAhzYdg4MlK6XcO9+FU7RqQ2elV7DlECEkXhZeRL2sCBGgZ2VItZurOnNMgF1mFIHQ5I/2Gu8UVfA3cqjsI05Mwo8a8mfRlTvdxpbgqg1KshudT2MMAf56sMElyBackQng05jZ/DKq2HGcnGG/4cIvm7u/b0UQ72+FpAI7myE4bRd7bhKk74Zop2TA688bVebhqXy9yGmdgG+8bAdB7YdB7YdB7YdB7YdB7YdB7YdB7YdB7Ydrzykcfb1G47fvkPxWjFyb7u7MCWSybdNLJBZ4C9nKvCxWiwQ0cPnvSYu4ejAayJGXYK2/sw+zteVz0uPVxja+nXU8fwCYZNAdZhw1Pz0FLnqw1W1tlTZCB/EBXFLtfdMXSUmepFmeSaXKw/jH+4nzX2YopmrM1td70ZIIbdLLdo4eIQZ4nHYdhzYdhzYdvzw8C818Lsihqdeycr650+nNz+8/3j+uXp4cPtP3ebf90IiWkQlzmVMLC7V5QM/YzrMekNPJIK7j8PcFpxObNJDXT5g8PQiBeW64HRiYMdBL6mOySgm0UWOXVgvrfuwHMJH+m/rpSkdYowxltJvqIujkC1YysoAAAAASUVORK5CYII=" />
                <span>my tournament</span>
              </div>
              <div class="settingPanel__container-items-right">
                <h5 style={{ display: "none" }}>2</h5>
                <span style={{ display: "none" }}>English</span>
                <i
                  class="van-badge__wrapper van-icon van-icon-arrow"
                  style={{ color: "rgb(166, 169, 174)" }}
                ></i>
              </div>
            </div>
            <div
              class="settingPanel__container-items__item ar-1px-b"
              style={{ display: "none" }}
            >
              <div class="settingPanel__container-items__title">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMrSURBVHgB7ZpNTxNBGMf/z7bFoIG0eOhFk3IROAjlBIkmSjx5og1wtnwC+QaUTwB8Az8AhJWzicWLclCaXlAvNNFLSYQGoihtZ5zBlpSXbp+WBZdlfknTfZmZnd/uM7OT9gEMBoPhGkFog62VRPiPQMoChqSQcdVKWB2ufS6LvOpsXl0rr7q9VrGQGUjaebRIS8JatCzESyFpBpcrx4JILoQsa643aRfZdbgFtexhRb5Vm3F4CYlsR5DGuNIWmJSEnIfXZDWEeEmIWX5xBpsriZhVkVvwMCJAvZwxzXrCVhkpeByrLFKscpxCRHgCj0NErD6yhCWk98buKSRzfmkqrGdneOAVxCBc7asjTYV/Xw/ZIzh9DTYtUEZMNJnLf/08QO5DDjuFHfREezA4OojbdzqPz5dKZWx/K6BdIqrN+vYaESi5IMxh/c06DpS0Rktr+dFnI8fnc+9zKHxvXzjUEcKIaq870uVYrkK4eEhzqMnW2N/dP7F/EVlN6bCEne0fcANXhHUY19MV6XY83ypB9YSj96JwA1dCekiN2Y/vPmFvd0/J3VX7D0+c1+FdUGO4rMZyO0TvRxEMudJVd4Q71YTy+PkjxzK6017AlZC+Thhhv3PjhF2ZtLyy0uLgq5UWB7PSagez0jIrLbPS+m8YYb9jhP2OEfY7RtjvGGG/Y4T9jhH2O+ZXy9OUg8hbFXAoEiivvyXJGCRiuBi6vazekJBPWTWCOkvPGVba0pel8V04/LuuOrR4K2Cl65PDPi8nZkge5Xa1SlFKmuufshdqB/6lTSGtrvTCqV7f5OsImsDL4qne6fMQUs71T67OnM6E65+wFwTRNFqGputlNTr/qm/STgl1IxrVUjdpDQxYwhWJcy+kZQemVtON6g1M2K8kaBFMdHtKzG7Y3pSdbiRtSR0BzWHnWm4uJ1IW5KyK37B+4vomqA5kOHW/Lo1vMNKKsiokh8FpbyURRwXz1bF9Zgg40Vb6cKtUUxc30GgeIOSFRWPtpAO3ypW8lrSICsWk2jwv47VIFiWvQlZzZe9hHf4iQMNqOGSqh/RrJ0MBGnuQtLMwGAwGw1n+Ar+kSWTjcb7TAAAAAElFTkSuQmCC" />
                <span>Product code</span>
              </div>
              <div class="settingPanel__container-items-right">
                <h5 style={{ display: "none" }}>2</h5>
                <span style={{ display: "none" }}>English</span>
                <i
                  class="van-badge__wrapper van-icon van-icon-arrow"
                  style={{ color: "rgb(166, 169, 174)" }}
                ></i>
              </div>
            </div>
            <div
              class="settingPanel__container-items__item ar-1px-b"
              style={{ display: "none" }}
            >
              <div class="settingPanel__container-items__title">
                <img src="/images/mylottery-8c69f87d.png" />
                <span>my draw</span>
              </div>
              <div class="settingPanel__container-items-right">
                <h5 style={{ display: "none" }}>2</h5>
                <span style={{ display: "none" }}>English</span>
                <i
                  class="van-badge__wrapper van-icon van-icon-arrow"
                  style={{ color: "rgb(166, 169, 174)" }}
                ></i>
              </div>
            </div>
            <div class="settingPanel__container-items__item ar-1px-b">
              <div class="settingPanel__container-items__title">
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF4SURBVHgB7dpPTsJAGAXwNxPELUfQhQt3HgFO4CBx7w20J6CeAI6ga5E2noBbEHXjEVgajf2c7hT6x8Sm6Xy8X0IgzNDw2mYeoQWIiAJiEJiXxF3Ll8T+5WBraGONzE8mT7dVnw8q8PPCOUCSqjmZmNHpZboqG7cIieCsbooPNKwZ3y8MrB0Da9dDS/7bn01p5Qjn/enDzrEbNjfw3RmvH9wQLWjnlG6gP5vCRUs7BtaOPbzt9dFNM5EbFFSK78+4rf5sSuURzvvTh41R3J9osz+bUn1Kd6g/m8JFSzsG1o6BtWNg7RhYOwbWjoG1Y2DtGFg7BtaOgX8Siw1qCP4wp0PbqQx8aHEHg7fSCX5MekhRo0vbqb0xbZ24I5PB+cevP+PzvZ1/geNxWrvXu7gdIqIglK7Ss4v+1D9dIUiSRsvPqGikMPBs0ne+wSvvS+48a0fR4n2183bhZGPD7zLJCi/il5/S44O5MeYcAfI/L++j5UcMIv2+AevjhktJIJCYAAAAAElFTkSuQmCC" />
                <span>Game statistics</span>
              </div>
              <div class="settingPanel__container-items-right">
                <h5 style={{ display: "none" }}>2</h5>
                <span style={{ display: "none" }}>English</span>
                <i
                  class="van-badge__wrapper van-icon van-icon-arrow"
                  style={{ color: "rgb(166, 169, 174)" }}
                ></i>
              </div>
            </div>
            <div class="settingPanel__container-items__item ar-1px-b">
              <div class="settingPanel__container-items__title">
                <img src="/images/languageIcon-1b8f2d0a.png" />
                <span>Language</span>
              </div>
              <div class="settingPanel__container-items-right">
                <h5 style={{ display: "none" }}>2</h5>
                <span>English</span>
                <i
                  class="van-badge__wrapper van-icon van-icon-arrow"
                  style={{ color: "rgb(166, 169, 174)" }}
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="serviceCenter-wrap">
          <div class="serviceCenter__container">
            <h1>Service center</h1>
            <div class="serviceCenter__container-items">
              <div class="serviceCenter__container-items__item">
                <img
                  class=""
                  data-origin="https://www.bdggame.in/assets/png/settingCenter-d46ac1a5.png"
                  src="https://www.bdggame.in/assets/png/settingCenter-d46ac1a5.png"
                />
                <span>Settings</span>
              </div>
              <div class="serviceCenter__container-items__item">
                <img
                  class=""
                  data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANCSURBVHgB7dpNTxNBGAfw/2xbYkGxhUgPFlMMBmpASIyRmyXecYlwpnwC4RMUvoDIJxBuJkisfAFL4sGLgWgiEhKopgY5SHoxoNB9nCExviR0ZsvTV/aXkCbtdHb/3dnZZ5cBPB6Pp44IlMHc2IUEiFIgZ1BuIuTqy6A8hLWOgJicfnaYBTP2wI8fBiYEiQUwsISVfLR8uAhGrIHn7AsxWIU190f1VHk4zV3T6XweTCxwsgoJxrBKCNZBEozOdIS3luwELOeeQ0Keqxhce70e2v28yxkY17o78313+rJCIE8kVh0/FuKj6SxKVFJgFZQEpQhI/P3+uzfvkdvOgVP0ehS3hvr/eU/udKbgE5OlBHc9pLeW7ZQj6NX/YStJbdsq0M7W8kgKLrkKfBKWaOa0z/0BP7j5m07vU55KM25DGwfWhVVaw63g1n6lvejnKvTHJXsKhowCb7ywY7qwSiTageDFILioviKdHdp2QtCc2kcYMArsc8ho2ASaAhi6f5cltOpD9WXKV6CnJu20s7T65dQEARcOvh/g294+cjtf4FZAzgPq1OjqjbmeE5p8Itw1mi5apGh79DlIENwJtgTl5eTqyV8l/ThGUr48KdZGO6QJ9AB1wrIwoG2jayBvBFgrp3JyHIR1bXhr6SqzBF3WtsE54wVudF7gRucFbnSsN7D7sn5WdTSHcKQNzS18d16/sQX+8HYD2c0suKg7r+GRRNEHAKVgG9J7uT1wOvp5hK/MfSpsgdXDNk5+eYTb5bDmxjZebvR3ozV0CcdHx+AQ6YyU5xkZGKmdrHXedbjReYEbXVUrLfWwr60Ml55iql5p3bwdR6wnhkqpeqWV23b/7Posql5pRaKVvXZXtdKq63NY8SqtGmTwrxZ8Qr0QIqtrog8ssI46UQBWdW30gS2kUS8sZPRNNNRKGQGRQY1TK3tMVvUYTVoFwixqnFrGZNLOKHB8PJ0hiHnUKIdo1nTNlquFaZtLIwtyJpxADZFhF+PjK0nT9q6uwz2y41o60gSadxNWcV149I6lpxwhzxeBLKpFbtshMdw7tmK8PuvPV89g87ltyxdbdjIgf+1BlI9amZOXo+slEdJqToHH4/GcB78AlDYBdwiSx+kAAAAASUVORK5CYII="
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANCSURBVHgB7dpNTxNBGAfw/2xbYkGxhUgPFlMMBmpASIyRmyXecYlwpnwC4RMUvoDIJxBuJkisfAFL4sGLgWgiEhKopgY5SHoxoNB9nCExviR0ZsvTV/aXkCbtdHb/3dnZZ5cBPB6Pp44IlMHc2IUEiFIgZ1BuIuTqy6A8hLWOgJicfnaYBTP2wI8fBiYEiQUwsISVfLR8uAhGrIHn7AsxWIU190f1VHk4zV3T6XweTCxwsgoJxrBKCNZBEozOdIS3luwELOeeQ0Keqxhce70e2v28yxkY17o78313+rJCIE8kVh0/FuKj6SxKVFJgFZQEpQhI/P3+uzfvkdvOgVP0ehS3hvr/eU/udKbgE5OlBHc9pLeW7ZQj6NX/YStJbdsq0M7W8kgKLrkKfBKWaOa0z/0BP7j5m07vU55KM25DGwfWhVVaw63g1n6lvejnKvTHJXsKhowCb7ywY7qwSiTageDFILioviKdHdp2QtCc2kcYMArsc8ho2ASaAhi6f5cltOpD9WXKV6CnJu20s7T65dQEARcOvh/g294+cjtf4FZAzgPq1OjqjbmeE5p8Itw1mi5apGh79DlIENwJtgTl5eTqyV8l/ThGUr48KdZGO6QJ9AB1wrIwoG2jayBvBFgrp3JyHIR1bXhr6SqzBF3WtsE54wVudF7gRucFbnSsN7D7sn5WdTSHcKQNzS18d16/sQX+8HYD2c0suKg7r+GRRNEHAKVgG9J7uT1wOvp5hK/MfSpsgdXDNk5+eYTb5bDmxjZebvR3ozV0CcdHx+AQ6YyU5xkZGKmdrHXedbjReYEbXVUrLfWwr60Ml55iql5p3bwdR6wnhkqpeqWV23b/7Posql5pRaKVvXZXtdKq63NY8SqtGmTwrxZ8Qr0QIqtrog8ssI46UQBWdW30gS2kUS8sZPRNNNRKGQGRQY1TK3tMVvUYTVoFwixqnFrGZNLOKHB8PJ0hiHnUKIdo1nTNlquFaZtLIwtyJpxADZFhF+PjK0nT9q6uwz2y41o60gSadxNWcV149I6lpxwhzxeBLKpFbtshMdw7tmK8PuvPV89g87ltyxdbdjIgf+1BlI9amZOXo+slEdJqToHH4/GcB78AlDYBdwiSx+kAAAAASUVORK5CYII="
                />
                <span>Feedback</span>
              </div>
              <div class="serviceCenter__container-items__item">
                <img
                  class=""
                  data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMxSURBVHgB7ZrPThNBHMe/s6W1CDEVDhzsYSFqw8FET3pziQ9AayHxYoJPIDwB8ASLTwDeTDTp8gTUN+BGlAN7aEQOkB74o5TO+JvyR2Jk/8DszobMJ2laZhbCJ7/fzP5+0wUMBoPBYMgMDBlhq1G1Ox04PIcS4yj9O8+B5vi018QN6YNGNj9XHWHxSSHYzHFXlGBRBAT+GwZL9AabuCFahHuiTMxzCAci3SRLVXiD0jbXFcs9UU2kJvy9UX0quGhQxtrQiIUU+PalWhVdsQahV1aSuLCMLCCW6WMJGSBRYblmZRojI7KS0DXsVkt0uzh8T1GapftFrH/8x2YL5bEHyBLhm1buqEFrz4lbo5THypmTlQQKu1NFB5w7uAaPnjyESrZb2y/d14WFiwGGdeSt9blPv3zEIDjC15QdGhlG/0A/VLLf3nfozbkYkBXZMYdbL6yQ+GJU8UQ2rfJoiqksMEPia+6boh3l8kSEh0eGkDJ2T1pusCEoF753/57ydI6IjdyhG3aRcuG7g1pkT6H0DouycuHigEZhiXVQDZyGYvL5PPTC7KDZVJqHLKFcuEPnNFqRBUkAyoVPjk+gFaq+gqaVHwDs7uxCF4xhZTak4lIe4aODI01RZr6gEjPsqkQ2rdZWC+nCfBTYRJR6OhHhn60dpIVMY/D+Z1Gbh+A1bFlN6pjmEZO9nT1ay3tKa+rB0mCT3r7+HRE++IA367Xb1DZF/TPhXb1bv0OHb/GPVYdI9sWr51CGYIuVaW8BNyQ8pbu/ayT8obdOYnAe5awRelua80Ap06HzLMgXHblOirDfqUyt9jJn4+2wDZ6N49lzEi0tx2uezyxWo49tZITEa+nHNY8qH/YOGZFOpXmoTHkey7EJ2iJ9aCa1bklGmlskDfYRGkm1PZRrmqI9wxmluKZoa+mHx+veSqW+OsrFWcRTlNf6BMDZIwzydfp1ahc2fT9uX/XIAxSgVfgyp7t5cPOuAnPEc9sxwrcdI3zbMcIR8ANnM9AgBBFfWAQX/5wLrc1BGLGFC31YYldXROvFPmsJGSa28GjNa+ept6XCf/FS+vry5wKNy3kYDAaDQQ1/ABGJBbxfrJa5AAAAAElFTkSuQmCC"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMxSURBVHgB7ZrPThNBHMe/s6W1CDEVDhzsYSFqw8FET3pziQ9AayHxYoJPIDwB8ASLTwDeTDTp8gTUN+BGlAN7aEQOkB74o5TO+JvyR2Jk/8DszobMJ2laZhbCJ7/fzP5+0wUMBoPBYMgMDBlhq1G1Ox04PIcS4yj9O8+B5vi018QN6YNGNj9XHWHxSSHYzHFXlGBRBAT+GwZL9AabuCFahHuiTMxzCAci3SRLVXiD0jbXFcs9UU2kJvy9UX0quGhQxtrQiIUU+PalWhVdsQahV1aSuLCMLCCW6WMJGSBRYblmZRojI7KS0DXsVkt0uzh8T1GapftFrH/8x2YL5bEHyBLhm1buqEFrz4lbo5THypmTlQQKu1NFB5w7uAaPnjyESrZb2y/d14WFiwGGdeSt9blPv3zEIDjC15QdGhlG/0A/VLLf3nfozbkYkBXZMYdbL6yQ+GJU8UQ2rfJoiqksMEPia+6boh3l8kSEh0eGkDJ2T1pusCEoF753/57ydI6IjdyhG3aRcuG7g1pkT6H0DouycuHigEZhiXVQDZyGYvL5PPTC7KDZVJqHLKFcuEPnNFqRBUkAyoVPjk+gFaq+gqaVHwDs7uxCF4xhZTak4lIe4aODI01RZr6gEjPsqkQ2rdZWC+nCfBTYRJR6OhHhn60dpIVMY/D+Z1Gbh+A1bFlN6pjmEZO9nT1ay3tKa+rB0mCT3r7+HRE++IA367Xb1DZF/TPhXb1bv0OHb/GPVYdI9sWr51CGYIuVaW8BNyQ8pbu/ayT8obdOYnAe5awRelua80Ap06HzLMgXHblOirDfqUyt9jJn4+2wDZ6N49lzEi0tx2uezyxWo49tZITEa+nHNY8qH/YOGZFOpXmoTHkey7EJ2iJ9aCa1bklGmlskDfYRGkm1PZRrmqI9wxmluKZoa+mHx+veSqW+OsrFWcRTlNf6BMDZIwzydfp1ahc2fT9uX/XIAxSgVfgyp7t5cPOuAnPEc9sxwrcdI3zbMcIR8ANnM9AgBBFfWAQX/5wLrc1BGLGFC31YYldXROvFPmsJGSa28GjNa+ept6XCf/FS+vry5wKNy3kYDAaDQQ1/ABGJBbxfrJa5AAAAAElFTkSuQmCC"
                />
                <span>Notification</span>
              </div>
              <div class="serviceCenter__container-items__item">
                <img
                  class=""
                  data-origin="https://www.bdggame.in/assets/png/serviceCenter-b92936b9.png"
                  src="https://www.bdggame.in/assets/png/serviceCenter-b92936b9.png"
                />
                <span>24/7 Customer service</span>
              </div>
              <div class="serviceCenter__container-items__item">
                <img
                  class=""
                  data-origin="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANuSURBVHgB7ZpNTxNBGMf/s32xSCTVAycP24ukXkQ5G9ebcnF5S4gXyyeQfgLsJ1j5BLY3I4iFs4nLkYNSQyJCjV0TDnIhe6EQ2u440wYDZbfdbUvZlvklG9KdZ6G/eZ6ZXdoHEAgEAoF/Ic0CtNmIjJOKAkJMEMlEOZJLZk0TPqDwSY2WSpCpBFmiMMtBGPGJrNHoGkdhTWWiAfoOlCo2wwabgByotY5AWE8uHebQBXY/qKNWAIoEPKC19yXXxxCCdEUiKSdxW2FNjUYhFTftfqEDbAKgswrIJJePdXSQ/JKqUMl6QSlJsJdRVxcRGGGJPIxNZM2LQzZokyGNDc2jNWryUmix1cy3JFkPpamRmbU39afthafCm6AYRbsQorOyzyRXSmk34TvLqkpAX1NAQfsYI9OrsfqTDhkOU3QWg015ykl896OaoKALbJJldBAmfMGvW8KnGAhLT5Pvjw3+olq6hC50KKMXsBMOoruwW5xV0KZu6OOzz6IWoe0vG490W7gGv6U0fQK4HCRcM4Rwv3PthD1vWkN3hhC7JzuO/93bxz47/Ipn4bHHjzAwOOA4Pnx3GF/WDlA+KcGPeCppLtpIlhMKhzB0+xb8iifho8Mj5LfyDWP2fu/hYP8AfsVzSee3fqHw8w/LpP2lfFL8TEtPWmX2MQM/ehFxW3LDwODNpjFHh0X4Ec/C98fikEfkpnGFHQPbX7fhNzyVNL/luJHlxFhcs1vYVeB5DRdd7sI8zo87tqeSLrGnp43PG9Xs8Ww3iuMl7Uc8r2GetR/f/Lc23SL+Pex3hHC/I4T7HSHc7wjhfsdJ2Bc9HG1i62AvzPs3ehxKybrdeSfhFHocGoRty4atcK0xRZpDj2IRMufUxeO4aSVXjtOwpBgoMoSAl7if17VJQHIUZNEKkFh8Kpt2CvT0tbQ2Expl0yezQyHS/16plhh/+RwtUpWzgO/szetWALlmzWhnaet7+Go/V6CowILK1v0TtnJkt9d6FDZY9lYpRTYSRM6u/8otHW08qFZAmcy7kXchbFiUZNhPPT6T1dEhLq3TQpuOKKhYCfYXXtmNOwibLJMZnslOSp7l0ltLqj2bUkVh++PC2ayfEyYsmxbJsHJ92065uqGrvTTaZChxKl4V5qIgqUa7al/Ay317SVUgEAgEAsE5/gFBJzYbmTEiCwAAAABJRU5ErkJggg=="
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANuSURBVHgB7ZpNTxNBGMf/s32xSCTVAycP24ukXkQ5G9ebcnF5S4gXyyeQfgLsJ1j5BLY3I4iFs4nLkYNSQyJCjV0TDnIhe6EQ2u440wYDZbfdbUvZlvklG9KdZ6G/eZ6ZXdoHEAgEAoF/Ic0CtNmIjJOKAkJMEMlEOZJLZk0TPqDwSY2WSpCpBFmiMMtBGPGJrNHoGkdhTWWiAfoOlCo2wwabgByotY5AWE8uHebQBXY/qKNWAIoEPKC19yXXxxCCdEUiKSdxW2FNjUYhFTftfqEDbAKgswrIJJePdXSQ/JKqUMl6QSlJsJdRVxcRGGGJPIxNZM2LQzZokyGNDc2jNWryUmix1cy3JFkPpamRmbU39afthafCm6AYRbsQorOyzyRXSmk34TvLqkpAX1NAQfsYI9OrsfqTDhkOU3QWg015ykl896OaoKALbJJldBAmfMGvW8KnGAhLT5Pvjw3+olq6hC50KKMXsBMOoruwW5xV0KZu6OOzz6IWoe0vG490W7gGv6U0fQK4HCRcM4Rwv3PthD1vWkN3hhC7JzuO/93bxz47/Ipn4bHHjzAwOOA4Pnx3GF/WDlA+KcGPeCppLtpIlhMKhzB0+xb8iifho8Mj5LfyDWP2fu/hYP8AfsVzSee3fqHw8w/LpP2lfFL8TEtPWmX2MQM/ehFxW3LDwODNpjFHh0X4Ec/C98fikEfkpnGFHQPbX7fhNzyVNL/luJHlxFhcs1vYVeB5DRdd7sI8zo87tqeSLrGnp43PG9Xs8Ww3iuMl7Uc8r2GetR/f/Lc23SL+Pex3hHC/I4T7HSHc7wjhfsdJ2Bc9HG1i62AvzPs3ehxKybrdeSfhFHocGoRty4atcK0xRZpDj2IRMufUxeO4aSVXjtOwpBgoMoSAl7if17VJQHIUZNEKkFh8Kpt2CvT0tbQ2Expl0yezQyHS/16plhh/+RwtUpWzgO/szetWALlmzWhnaet7+Go/V6CowILK1v0TtnJkt9d6FDZY9lYpRTYSRM6u/8otHW08qFZAmcy7kXchbFiUZNhPPT6T1dEhLq3TQpuOKKhYCfYXXtmNOwibLJMZnslOSp7l0ltLqj2bUkVh++PC2ayfEyYsmxbJsHJ92065uqGrvTTaZChxKl4V5qIgqUa7al/Ay317SVUgEAgEAsE5/gFBJzYbmTEiCwAAAABJRU5ErkJggg=="
                />
                <span>Beginner's Guide</span>
              </div>
              <div class="serviceCenter__container-items__item">
                <img
                  class=""
                  data-origin="https://www.bdggame.in/assets/png/about-38aa0cd6.png"
                  src="https://www.bdggame.in/assets/png/about-38aa0cd6.png"
                />
                <span>About us</span>
              </div>
            </div>
          </div>
          <div class="serviceCenter-wrap-header">
            <button>
              <img
                class=""
                data-origin="https://www.bdggame.in/assets/png/logout-3fc8c261.png"
                src="https://www.bdggame.in/assets/png/logout-3fc8c261.png"
              />{" "}
              Log out
            </button>
          </div>
          <div data-v-b9e16d43="" class="dialog inactive">
            <div
              data-v-b9e16d43=""
              class="dialog__container"
              role="dialog"
              tabindex="0"
            >
              <div data-v-b9e16d43="" class="dialog__container-img">
                <img
                  data-v-b9e16d43=""
                  alt=""
                  class=""
                  data-origin="https://www.bdggame.in/assets/png/orderCancelWarn-93894f35.png"
                  src="https://www.bdggame.in/assets/png/orderCancelWarn-93894f35.png"
                />
              </div>
              <div data-v-b9e16d43="" class="dialog__container-title">
                <h1 data-v-b9e16d43="">Do you want to log out?</h1>
              </div>
              <div data-v-b9e16d43="" class="dialog__container-content"></div>
              <div data-v-b9e16d43="" class="dialog__container-footer">
                <button data-v-b9e16d43="">Cancel</button>
                <button data-v-b9e16d43="">Confirm</button>
              </div>
            </div>
            <div data-v-b9e16d43="" class="dialog__outside"></div>
          </div>
        </div>
      </div>
      <MenuBar />
    </>
  );
};

export default Home;
