import { createSlice } from "@reduxjs/toolkit";


export const SelectActiveInfoTab = ({ AviatorGameSlice }) => AviatorGameSlice.ACTIVE_INFO_TAB
export const SelectPreviousHandFlag = ({ AviatorGameSlice }) => AviatorGameSlice.PREVIOUS_HAND_FLAG
export const SelectTopBatWinsType = ({ AviatorGameSlice }) => AviatorGameSlice.TOP_BAT_WINS_TYPE
export const SelectTopBatWinsList = ({ AviatorGameSlice }) => AviatorGameSlice.TOP_BAT_WINS_LIST
export const SelectActiveTopWinsTab = ({ AviatorGameSlice }) => AviatorGameSlice.ACTIVE_TOP_WINS_TAB
export const SelectFlyPlan = ({ AviatorGameSlice }) => AviatorGameSlice.FLY_PLAN
export const SelectCrashPlan = ({ AviatorGameSlice }) => AviatorGameSlice.CRASH_PLAN
export const SelectShowCanvasAviator = ({ AviatorGameSlice }) => AviatorGameSlice.SHOW_CANVAS_AVIATOR
export const SelectDefaultPlanPositoin = ({ AviatorGameSlice }) => AviatorGameSlice.DEFAULT_PLAN_POSITION
export const SelectPerSecReward = ({ AviatorGameSlice }) => AviatorGameSlice.PER_SEC_REWARD
export const SelectFlyTimer = ({ AviatorGameSlice }) => AviatorGameSlice.FLY_TIMER // remaing time
export const SelectCrashReward = ({ AviatorGameSlice }) => AviatorGameSlice.CRASH_REWARD
export const SelectTotalTime = ({ AviatorGameSlice }) => AviatorGameSlice.TOTAL_TIME
export const SelectPandingBat = ({ AviatorGameSlice }) => AviatorGameSlice.PANDING_BAT
export const SelectBatSucessResponse = ({ AviatorGameSlice }) => AviatorGameSlice.BAT_SUCESS_RESPONSE
export const SelectCashInInfoUser = ({ AviatorGameSlice }) => AviatorGameSlice.CASH_IN_INFO_USER
export const SelectUpdateCrashReward = ({ AviatorGameSlice }) => AviatorGameSlice.UPDATE_CRASH_REWARD
export const SelectCashOutSuccessResponse = ({ AviatorGameSlice }) => AviatorGameSlice.CASH_OUT_SUCESS_RESPONSE
export const SelectRestCashOutIndex = ({ AviatorGameSlice }) => AviatorGameSlice.resetCashOutIndex


let initialState = {
    ACTIVE_INFO_TAB: 0,
    PREVIOUS_HAND_FLAG: false,
    TOP_BAT_WINS_LIST: [{ id: 'HUGE_WINS', title: 'HUGE WINS' }, { id: 'BIGGEST_WINS', title: 'BIGGEST WINS' }, { id: 'MULTIPLIERS', title: 'MULTIPLIERS' }],
    TOP_BAT_WINS_TYPE: 'HUGE_WINS',
    ACTIVE_TOP_WINS_TAB: 0,
    FLY_PLAN: false,
    CRASH_PLAN: false,
    DEFAULT_PLAN_POSITION: false,
    PER_SEC_REWARD: 0,
    FLY_TIMER: 0,
    TOTAL_TIME:0,
    CRASH_REWARD: 0,
    PANDING_BAT: [null,null],
    BAT_SUCESS_RESPONSE:[null,null],
    CASH_IN_INFO_USER:[],
    UPDATE_CRASH_REWARD: 0,
    CASH_OUT_SUCESS_RESPONSE:[null,null],
    resetCashOutIndex : null

}

const AviatorGameSlice = createSlice({
    name: "aviator",
    initialState,
    reducers: {
        SET_ACTIVE_INFO_TAB: (state, { payload }) => { state.ACTIVE_INFO_TAB = payload },
        SET_PREVIOUS_FLAG: (state, { payload }) => { state.PREVIOUS_HAND_FLAG = payload },
        SET_BAT_WINS_TYPE: (state, { payload }) => { state.TOP_BAT_WINS_TYPE = payload },
        SET_ACTIVE_TOP_WINS_TAB: (state, { payload }) => { state.ACTIVE_TOP_WINS_TAB = payload },
        SET_FLY_PLAN: (state, { payload }) => { state.FLY_PLAN = payload },
        SET_CRASH_PLAN: (state, { payload }) => { state.CRASH_PLAN = payload },
        SET_DEFAULT_PLAN_POSITION: (state, { payload }) => { state.DEFAULT_PLAN_POSITION = payload },
        SET_PER_SEC_REWARD: (state, { payload }) => { state.PER_SEC_REWARD = payload },
        SET_FLY_TIMER: (state, { payload }) => { state.FLY_TIMER = payload },
        SET_TOTAL_TIME: (state, { payload }) => { state.TOTAL_TIME = payload },
        SET_CRASH_REWARD: (state, { payload }) => { state.CRASH_REWARD = payload },
        SET_PANDING_BAT: (state, { payload }) => { state.PANDING_BAT = [...payload] },
        SET_BAT_SUCESS_RESPONSE: (state, { payload }) => { 
            let BatSucess = [...state.BAT_SUCESS_RESPONSE]
            BatSucess[payload.button_index] = payload
            state.BAT_SUCESS_RESPONSE = BatSucess
         },
         SET_CASH_IN_INFO_USER: (state, { payload }) => { state.CASH_IN_INFO_USER=[...state.CASH_IN_INFO_USER,payload]},
         UPDATE_CASH_IN_INFO_USER: (state, { payload }) => { 
            let findIndex = state.CASH_IN_INFO_USER.findIndex((user)=>payload.ticket_id ===user.ticket_id)
            if(findIndex){
                let infoUser = [...state.CASH_IN_INFO_USER]
                infoUser[findIndex] = {...infoUser[findIndex],...payload}
                 state.CASH_IN_INFO_USER=[...infoUser]

            }else{
                state.CASH_IN_INFO_USER=[...state.CASH_IN_INFO_USER,payload]
            }
        },

         RESET_CASH_IN_INFO_USER: (state, { payload }) => { state.CASH_IN_INFO_USER=[]},
         RESET_BAT_SUCESS_RESPONSE: (state, { payload }) => { state.BAT_SUCESS_RESPONSE=[...payload]},
         SET_UPDATE_CRASH_REWARD : (state,{payload})=> {state.UPDATE_CRASH_REWARD = payload},
         SET_CASH_OUT_SUCESS_RESPONSE: (state, { payload }) => { 
            console.log("****enter cash out set")
            let cashOutSucess = [...state.CASH_OUT_SUCESS_RESPONSE]
            cashOutSucess[payload.button_index] = payload
            state.CASH_OUT_SUCESS_RESPONSE = cashOutSucess
         },
         RESET_CASH_OUT_SUCESS_RESPONSE: (state, { payload }) => { 
            let cashOutSucess = [...state.CASH_OUT_SUCESS_RESPONSE]
            cashOutSucess[payload.button_index] = null
            state.CASH_OUT_SUCESS_RESPONSE = cashOutSucess
         },
         RESET_CASH_OUT_Index: (state, { payload }) => { state.resetCashOutIndex = payload.btnIndex},
         RESET_AVIATOR_GAME_STORE : () => initialState

        
    }
})





export const {
    SET_ACTIVE_INFO_TAB, SET_PREVIOUS_FLAG, SET_BAT_WINS_TYPE, SET_ACTIVE_TOP_WINS_TAB, SET_FLY_PLAN, SET_CRASH_PLAN, SET_DEFAULT_PLAN_POSITION, SET_PER_SEC_REWARD, SET_FLY_TIMER, SET_CRASH_REWARD, SET_TOTAL_TIME, SET_PANDING_BAT, SET_BAT_SUCESS_RESPONSE,
    SET_CASH_IN_INFO_USER, RESET_CASH_IN_INFO_USER, RESET_BAT_SUCESS_RESPONSE, UPDATE_CASH_IN_INFO_USER, SET_UPDATE_CRASH_REWARD, SET_CASH_OUT_SUCESS_RESPONSE, RESET_CASH_OUT_SUCESS_RESPONSE, RESET_CASH_OUT_Index, RESET_AVIATOR_GAME_STORE  

} = AviatorGameSlice.actions;

export default AviatorGameSlice.reducer;