import { createSlice } from "@reduxjs/toolkit";


export const SelectGetOtpFlag = ({ registerPageSlice }) => registerPageSlice.GetOtpFlag
export const SelectVerifyOtpFlag = ({ registerPageSlice }) => registerPageSlice.VerifyOtpFlag
export const SelectRegisteFormSubmitFlag = ({ registerPageSlice }) => registerPageSlice.RegisteFormSubmitFlag



const registerPageSlice = createSlice({
    name: "registerPageSlice",
    initialState: {
        GetOtpFlag : true,
        VerifyOtpFlag:false,
        RegisteFormSubmitFlag:false
    },
    reducers: {
        SET_GET_OTP_FLAG: (state, { payload }) => { state.GetOtpFlag = payload },
        SET_VERIFY_OTP_FLAG: (state, { payload }) => { state.VerifyOtpFlag = payload },
        SET_REGISTER_FORM_SUBMIT_FLAG: (state, { payload }) => { state.RegisteFormSubmitFlag = payload },
    }
})





export const {
    SET_GET_OTP_FLAG, SET_VERIFY_OTP_FLAG, SET_REGISTER_FORM_SUBMIT_FLAG
} = registerPageSlice.actions;

export default registerPageSlice.reducer;