const DailyChart = () => {
  return (
    <div class="dailyProfitRank">
      <h1>Today's earnings chart</h1>
      <div class="dailyProfitRank__content">
        <div class="dailyProfitRank__content-topThree">
          <div
            class="dailyProfitRank__content-topThree__item"
            style={{ order: "2", top: "-45px" }}
          >
            <div
              style={{
                background:
                  "url('/images/border1-3b6518ec.png') center center / 100% 100% no-repeat",
              }}
            >
              <img
                data-img="/images/avatar-fa220f51.png"
                class=""
                data-origin="/images/17-bedde42f.png"
                src="/images/17-bedde42f.png"
              />
            </div>
            <div>
              <img
                class=""
                data-origin="/images/crown1-7e4e2fd9.png"
                src="/images/crown1-7e4e2fd9.png"
              />
              <img
                class=""
                data-origin="/images/place1-c2ee5099.png"
                src="/images/place1-c2ee5099.png"
              />
            </div>
            <span>Mem***DZF</span>
            <span>₹1,631,050,750.00</span>
          </div>
          <div
            class="dailyProfitRank__content-topThree__item"
            style={{ order: "1", top: "-20px" }}
          >
            <div
              style={{
                background:
                  'url("/images/border2-7a806be7.png") center center / 100% 100% no-repeat',
              }}
            >
              <img
                data-img="/images/avatar-fa220f51.png"
                class=""
                data-origin="/images/14-a397ff6b.png"
                src="/images/14-a397ff6b.png"
              />
            </div>
            <div>
              <img
                class=""
                data-origin="/images/crown2-8b009e96.png"
                src="/images/crown2-8b009e96.png"
              />
              <img
                class=""
                data-origin="/images/place2-2e2fac0d.png"
                src="/images/place2-2e2fac0d.png"
              />
            </div>
            <span>Mem***NDH</span>
            <span>₹1,252,307,700.00</span>
          </div>
          <div
            class="dailyProfitRank__content-topThree__item"
            style={{ order: "3", top: "-20px" }}
          >
            <div
              style={{
                background:
                  'url("/images/border3-cfec4a7d.png") center center / 100% 100% no-repeat',
              }}
            >
              <img
                data-img="/images/avatar-fa220f51.png"
                class=""
                data-origin="/images/14-a397ff6b.png"
                src="/images/14-a397ff6b.png"
              />
            </div>
            <div>
              <img
                class=""
                data-origin="/images/crown3-2ca02146.png"
                src="/images/crown3-2ca02146.png"
              />
              <img
                class=""
                data-origin="/images/place3-d9b0be38.png"
                src="/images/place3-d9b0be38.png"
              />
            </div>
            <span>Mem***NHD</span>
            <span>₹1,248,827,720.00</span>
          </div>
        </div>
        <div class="dailyProfitRank__content-list">
          <div class="dailyProfitRank__content-list__item">
            <span class="left-rank">4</span>
            <img
              data-img="/images/avatar-fa220f51.png"
              class=""
              data-origin="/images/14-a397ff6b.png"
              src="/images/14-a397ff6b.png"
            />
            <span class="middle-name">Mem***STY</span>
            <span class="right-box">₹1,048,709,270.00</span>
          </div>
          <div class="dailyProfitRank__content-list__item">
            <span class="left-rank">5</span>
            <img
              data-img="/images/avatar-fa220f51.png"
              class=""
              data-origin="/images/10-29a6603e.png"
              src="/images/10-29a6603e.png"
            />
            <span class="middle-name">Mem***MDN</span>
            <span class="right-box">₹964,627,720.00</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyChart;
