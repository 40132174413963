import React, { Suspense } from "react";
import { RouterProvider, createBrowserRouter, createHashRouter } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import AuthLayout from "./layout/AuthLayout";
import AuthGuard from "./AuthGuard";
import AviatorPage from "../game-page/aviator-game/Aviatorlayout";
import ColorGmaeOutlet from "../game-page/color-game/ColorGmaeOutlet";
import ColorLogin from "../game-page/color-game/color-game-page/Auth/ColorLogin";
import Dashboard from "../game-page/color-game/color-game-page/Dashboard";
import Wingo from "../game-page/color-game/color-game-page/Wingo";
import Wallet from "../game-page/color-game/color-game-page/Wallet";
import Recharge from "../game-page/color-game/color-game-page/Wallet/Recharge";
import Withdraw from "../game-page/color-game/color-game-page/Wallet/Withdraw";
import Home from "../game-page/color-game/color-game-page/Home";
import BetRecords from "../game-page/color-game/color-game-page/BetRecords";


const LoginPage = React.lazy(() => import("../game-page/login-page/LoginPage"));
// const AviatorPage = React.lazy(() => import("../game-page/aviator-game/Aviatorlayout"));

const router = createBrowserRouter([
  {
    path: "/*",
    element: <AuthLayout />,
    children: [
      {
        path: "aviator",
        element: <AviatorPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "color",
        element: <ColorGmaeOutlet />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <ColorLogin />
          },
          {
            path: "dashboard",
            element: <AuthGuard component={<Dashboard />}></AuthGuard>,
          },
          {
            path: "wingo",
            element: <Wingo />,
          },
          {
            path: "wallet",
            element: <Wallet />,
          },
          {
            path: "wallet/recharge",
            element: <Recharge />,
          },
          {
            path: "wallet/Withdraw",
            element: <Withdraw />,
          },
          {
            path: "account",
            element: <Home />,
          },
          {
            path: "BetRecords",
            element: <BetRecords />,
          },
        ]
      },
    ],
  },
]);

function Routing() {
  return (
    <Suspense fallback={<div>Loading..</div>}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default Routing;
