import GameNavbar from "./Layouts/GameNavbar";
// import "../index.css";
import Wallet from "./Wallet";
import GameNotice from "./GameNotice";
import WinGoGameList from "./WinGoGameList";
import WingoBettting from "./WingoBettting";
import WinGoTiming from "./WinGoTiming";
import GameRecord from "./GameRecord";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux"
import { SelectColorActiveGame } from "../../../redux/colorGameSlice";
import { SocketState } from "../../../context/SocketProvider";


const Wingo = () => {
  let navigate = useNavigate();

  let activeGame = useSelector(SelectColorActiveGame)
  const {socket} = SocketState()



  let backScreen = useCallback(
    () => {
      navigate('/color/dashboard')
      localStorage.removeItem("gameObj");

    },
    [],
  )


  useEffect(() => {
    
    socket.emit('req',{
      "en" : `${activeGame}_SHOP_GAME_INFO`,
      "data" : {
        
      }
    })

  return () =>{
    socket.emit('req',{
      "en" : `${activeGame}_SHOP_CLOSE_GAME`,
      "data" : {
        
      }
    })
  }
}, [activeGame])
    
  

  return (
    <>
      <div className="WinGo__C">
        <GameNavbar backScreen={backScreen} />
        <Wallet />
        <GameNotice />
        <WinGoGameList />
        <WinGoTiming />
        <WingoBettting />
        <GameRecord />
      </div>



    </>
  );
};

export default Wingo;
