import { createSlice } from "@reduxjs/toolkit";
import { AviatorPreload } from "../game-page/preload-game-state/AviatorPreload";
import { winGoPreload } from "../game-page/preload-game-state/wingoPreload";


export const SelectMainLoader = ({ loaderSlice }) => loaderSlice.mainLoaderFlag
export const SelectColorBatModalFlag = ({ loaderSlice }) => loaderSlice.colorBatModalFalg
export const SelectGameIconList = ({ loaderSlice }) => loaderSlice.gameIconList



const loaderSlice = createSlice({
    name: "loaderSlice",
    initialState: {
        mainLoaderFlag : true,
        colorBatModalFalg:false,
        gameIconList: [
            {
              id: 1,
              gameImage : '/assets/color/dashboard/aviator_game_icon.png',
              navigationPath : '/aviator',
              preloadFunction: AviatorPreload,
              socketBaseUrl : 'CRASH_',
              gameTitle: 'Aviatior'
            },
            {
              id: 2,
              gameImage : '/assets/color/dashboard/win_go_1_min.png',
              navigationPath : '/color/wingo',
              preloadFunction: winGoPreload,
              socketBaseUrl : 'METALY_SHOP_',
              gameTitle: 'Win Go'
            },
            
          ]
    },
    reducers: {
        SET_MAIN_LOADER: (state, { payload }) => { state.mainLoaderFlag = payload },
        SET_COLOR_BAT_MODAL_FLAG : (state, { payload }) => { state.colorBatModalFalg = payload },
    }
})





export const {
    SET_MAIN_LOADER, SET_COLOR_BAT_MODAL_FLAG

} = loaderSlice.actions;

export default loaderSlice.reducer;