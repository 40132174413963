import React, { useState } from 'react'
import {useSelector, useDispatch} from "react-redux"
import { SET_COLOR_BAT_MODAL_FLAG } from '../../../redux/loaderSlice'
import { SelectColorActiveGame } from '../../../redux/colorGameSlice'
import { SocketState } from '../../../context/SocketProvider'



function BatPlaceDialog({batCardNumber, backgroundColor, contractCount, setContractCount}) {
    const {socket} = SocketState()

    let activeGame = useSelector(SelectColorActiveGame)

  const dispatch = useDispatch()
  const [selectAmount, setselectAmount] = useState(10)
  const [isSelected, setSelection] = useState(true);

  let batSubmit = () =>{
    if(!isSelected){
      return
    }
    
    socket.emit('req', {
      "en" : `${activeGame}_SHOP_PLACE_BET`,
      "data" : {
          "card_details":{
              [batCardNumber]:selectAmount * contractCount
          }
      }
  })
  dispatch(SET_COLOR_BAT_MODAL_FLAG(false))
  }


  return (
    <>
    
    <div className="van-overlay" data-v-dc81bf96="" style={{ zIndex: 2004 }}></div>
  
    <div
    role="dialog"
    tabIndex={0}
    className="van-popup van-popup--round van-popup--bottom"
    data-v-dc81bf96=""
    style={{ zIndex: 2008 }}
  >
    <div data-v-dc81bf96="" className="Betting__Popup-0">
      <div data-v-dc81bf96="" className="Betting__Popup-head">
        <div data-v-dc81bf96="" className="Betting__Popup-head-title">
          Win Go 1Min
        </div>
        <div  className="Betting__Popup-head-selectName h-full flex items-center justify-center">
          <p style={{color:backgroundColor}}>Select  &nbsp; &nbsp;   {batCardNumber}</p>
        </div>
      </div>
      <div data-v-dc81bf96="" className="Betting__Popup-body">
        <div data-v-dc81bf96="" className="Betting__Popup-body-line">
          Balance{" "}
          <div data-v-dc81bf96="" className="Betting__Popup-body-line-list">
          {[10, 100, 1000, 10000].map((amount, index) => {
              return (
                <div className={`Betting__Popup-body-line-item ${selectAmount === amount && 'bgcolor'} `} onClick={()=>setselectAmount(amount)}>{amount}</div>
              )
            })}
           
          </div>
        </div>
        <div data-v-dc81bf96="" className="Betting__Popup-body-line">
          Quantity{" "}
          <div data-v-dc81bf96="" className="Betting__Popup-body-line-btnL">
            <button
              disabled={contractCount <= 1}
              className="Betting__Popup-btn reduce bgcolor"
              onClick={()=>setContractCount(old => old - 1)}
            />
            <div
              data-v-dc81bf96=""
              className="van-cell van-field Betting__Popup-input"
              
            >
             
              <div className="van-cell__value van-field__value">
                <div className="van-field__body">
                  <input
                    type="number"
                    className="van-field__control"
                    onChange={(e)=>setContractCount(e.target.value)}
                    value={contractCount}
                  />
                 
                </div>
                
              </div>
             
            </div>
            <div data-v-dc81bf96="" className="Betting__Popup-btn bgcolor" onClick={()=>setContractCount(old => old + 1)} />
          </div>
        </div>
        <div data-v-dc81bf96="" className="Betting__Popup-body-line">
          <div data-v-dc81bf96="" />
          <div data-v-dc81bf96="" className="Betting__Popup-body-line-list">
            {[1,5,10,20,50,100].map((qun,index)=>{
                return(
                    <div data-v-dc81bf96="" className={`Betting__Popup-body-line-item ${contractCount === qun && 'bgcolor'}`} onClick={()=>setContractCount(qun)}>X{qun}</div>
                )
            })}
            
           
          </div>
        </div>
        <div data-v-dc81bf96="" className="Betting__Popup-body-line">
          <span data-v-dc81bf96="" className="Betting__Popup-agree active">
            I agree
          </span>
          <span data-v-dc81bf96="" className="Betting__Popup-preSaleShow">
            《Pre-sale rules》
          </span>
        </div>
      </div>
      <div data-v-dc81bf96="" className="Betting__Popup-foot">
        <div data-v-dc81bf96="" className="Betting__Popup-foot-c cursor_pointer" onClick={()=>{dispatch(SET_COLOR_BAT_MODAL_FLAG(false))}}>
          Cancel
        </div>
        <div data-v-dc81bf96="" className="Betting__Popup-foot-s bgcolor cursor_pointer" onClick={batSubmit}>
          Total amount ₹{selectAmount * contractCount}
        </div>
      </div>
    </div>
    {/**/}
  </div>
  </>
  )
}

export default BatPlaceDialog