import { useEffect, useRef, useState } from "react";
import { SET_COLOR_BAT_MODAL_FLAG, SelectColorBatModalFlag } from "../../../redux/loaderSlice";
import BatPlaceDialog from "./BatPlaceDialog";
import {useSelector, useDispatch} from "react-redux"
import { SelectBatBtnDisable } from "../../../redux/colorGameSlice";
 
const WingoBettting = () => {

  let batModalFlag = useSelector(SelectColorBatModalFlag)
  const dispatch = useDispatch()
  const batBtnDisable = useSelector(SelectBatBtnDisable)
  const [contractCount, setContractCount] = useState(1)
  const [animationNumber, setAnimationNumber] = useState(null);
  const intervalRef = useRef(null);
  const timeoutRef = useRef(null);


  const [batCardNumber, setbatCardNumber] = useState(null)
  const [backgroundColor, setbackgroundColor] = useState();

  const [buttonArray, setButtonArray] = useState([
    { number: 0, color: ['#E92732', '#E304EB'] },
    { number: 1, color: ['#29BE3C', '#29BE3C'] },
    { number: 2, color: ['#E92732', '#E92732'] },
    { number: 3, color: ['#29BE3C', '#29BE3C'] },
    { number: 4, color: ['#E92732', '#E92732'] },
    { number: 5, color: ['#29BE3C', '#E304EB'] },
    { number: 6, color: ['#E92732', '#E92732'] },
    { number: 7, color: ['#29BE3C', '#29BE3C'] },
    { number: 8, color: ['#E92732', '#E92732'] },
    { number: 9, color: ['#29BE3C', '#29BE3C'] },
  ]);


  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);


  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * 10);
    console.log('randomNumber: ', randomNumber);
    setAnimationNumber(randomNumber);
  };

  const startAnimation = () => {
    clearInterval(intervalRef.current);
    clearTimeout(timeoutRef.current);
    
    intervalRef.current = setInterval(generateRandomNumber, 100);

    timeoutRef.current = setTimeout(() => {
      clearInterval(intervalRef.current);
      const finalNumber = Math.floor(Math.random() * 10);
    let modal = document.getElementById('modal_remainig_time')
    if(modal.style.display === 'none'){
      if (!batBtnDisable) { 
        setbatCardNumber(finalNumber.toString())
        setbackgroundColor('#d9ac4f');
        dispatch(SET_COLOR_BAT_MODAL_FLAG(true))
      }
    }
      setAnimationNumber(null);
    }, 5000);
  };


  return (
    <>
      <div className="Betting__C" voicetype="2" typeid="1">
        <div className="Betting__C-mark" id="modal_remainig_time" style={{ display: "none" }}>
          <div id="modal_remainig_time_1">2</div>
          <div id="modal_remainig_time_2">9</div>
        </div>
        <div className="Betting__C-head">
          <div className={`Betting__C-head-g`} onClick={() => {
            if(!batBtnDisable){
              setbatCardNumber('green')
              dispatch(SET_COLOR_BAT_MODAL_FLAG(true))
              setbackgroundColor('#40ad72')
            }

          }}>green</div>

          <div className={`Betting__C-head-v`} onClick={() => {
            if(!batBtnDisable){
            setbatCardNumber('violet')
            dispatch(SET_COLOR_BAT_MODAL_FLAG(true))
            setbackgroundColor('#b659fe')
            }

          }}>violet</div>

          <div className={`Betting__C-head-r`} onClick={() => {
            if (!batBtnDisable) {
              setbatCardNumber('red')
              dispatch(SET_COLOR_BAT_MODAL_FLAG(true))
              setbackgroundColor('#fd565c')
            }
          }}>red</div>
         
        </div>

         <div className="Betting__C-numC">
         {buttonArray.map((numberObj, index) => {
          // animationNumber
          return (
            <div key={index} className={`Betting__C-numC-item${numberObj.number} cursor_pointer ${animationNumber === numberObj.number && 'active'}`} onClick={()=>{
              if (!batBtnDisable) { 
                setbatCardNumber(index.toString())
                setbackgroundColor('#d9ac4f');
                dispatch(SET_COLOR_BAT_MODAL_FLAG(true))
              }
            }}></div>  
          )
         })}
         </div>
        
        <div className="Betting__C-multiple">
          <button className="Betting__C-multiple-l" onClick={startAnimation}>Random</button>
          
          
          
          {[1,5,10,20,50,100].map((qun,index)=>{
                return(
                    <div data-v-dc81bf96="" className={`Betting__C-multiple-r ${contractCount === qun && 'active'}`} onClick={()=>setContractCount(qun)}>X{qun}</div>
                )
            })}
          
        </div>
        <div className="Betting__C-foot">
          <div className="Betting__C-foot-b " onClick={() => {
            if(!batBtnDisable){
            setbatCardNumber('big')
            dispatch(SET_COLOR_BAT_MODAL_FLAG(true))
            setbackgroundColor('#d9ac4f')
            }

          }}>Big</div>
          <div className="Betting__C-foot-s" onClick={() => {
            if(!batBtnDisable){
            setbatCardNumber('small')
            dispatch(SET_COLOR_BAT_MODAL_FLAG(true))
            setbackgroundColor('#d9ac4f')
            }

          }}>Small</div>
        </div>
      </div>


      {batModalFlag &&  <BatPlaceDialog  batCardNumber={batCardNumber} backgroundColor={backgroundColor} contractCount={contractCount} setContractCount={setContractCount} />}

      {/*  */}


    </>
  );
};

export default WingoBettting;
