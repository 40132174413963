const GameList = () => {
  return (
    <div class="gamesList__container" id="gameList">
      <div class="gamesList__container-title">Lottery</div>
      <div class="gameListGrid__container all_container">
        <div class="lottery_container">
          <div class="lottery_container-tab">
            <ul class="lottery_container-tab-wrap">
              <li class="active">All</li>
              <li class="">Win Go</li>
              <li class="">K3</li>
              <li class="">5D</li>
              <li class="">Trx Win</li>
            </ul>
          </div>
          <div class="lottery_container-list">
            <div class="lotterySlotItem">
              <div class="lotterySlotItem_img">
                <img
                  class=""
                  data-origin="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062051do1k.png"
                  src="https://ossimg.bdgadminbdg.com/IndiaBDG/lotterycategory/lotterycategory_20240110062051do1k.png"
                />
              </div>
              <span>Win Go 1Min</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameList;
