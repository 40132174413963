import {useSelector} from "react-redux"
import { SelectlastWinCard } from "../../../redux/colorGameSlice";
import TimerComponent from "../component/TimerComponent";

const WinGoTiming = () => {
  let lastWinCard = useSelector(SelectlastWinCard)

  return (
    <div class="TimeLeft__C">
      <div class="TimeLeft__C-rule">How to play</div>
      <div class="TimeLeft__C-name">Win Go 1Min</div>
      <div class="TimeLeft__C-num">

        {[...lastWinCard].splice(0,5).map((card,cardIndex)=>{
          let number = card.split('')
          
          return <div key={cardIndex} className={`n${number[0]}`}></div>
        })}

        
        {/* <div class="n7"></div>
        <div class="n9"></div>
        <div class="n6"></div>
        <div class="n0"></div> */}

      </div>
      <div class="TimeLeft__C-id">20240326011071</div>
      <div class="TimeLeft__C-text">Time remaining</div>
      <TimerComponent />
      
    </div>
  );
};

export default WinGoTiming;
