import React, { createContext, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client';
import Loader from '../comonComponent/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MAIN_LOADER } from '../redux/loaderSlice';
import SuccessMessagePopup from '../comonComponent/success-message-popup/SuccessMessagePopup';
import ErrorMessagePopup from '../comonComponent/error-message-popup/ErrorMessagePopup';



const SocketContext = createContext();


function SocketProvider({ children }) {
  const dispatch = useDispatch()
  const [socket, setSocket] = useState(null);

  let state = {
    socket,
    setSocket,
  };

  useEffect(() => {
    dispatch(SET_MAIN_LOADER(true))
    async function callApi() {
      try {
        let response = await fetch("https://gapa.europainfotech.com/chooseServer?det=android") // testing 

        let data = await response.json()

        let urlPort = `wss://gaps.europainfotech.com/`
        console.log('urlPort: ', urlPort);
        // let urlPort = `http://65.0.61.50:3001`

        setSocket(
          io(urlPort, {
            transports: ['websocket'],
            pingInterval: 24 * 60 * 60 * 1000,
            pingTimeout: 3 * 24 * 60 * 60 * 1000,
          }),
        );

        dispatch(SET_MAIN_LOADER(false))
      } catch (err) {
        setSocket(null)

        if (err.message === 'NetworkError when attempting to fetch resource.') {
          // dispatch(setinternetMessage(true))
        } else {
          // toast.error("server down")
        }
      }
    }
    callApi();
    return () => {
      // setSocket(null)
    };
  }, []);



  useEffect(() => {
    console.log("socke", socket)
    if (socket) {
      socket.on("connect", () => {
        console.log("socket connect")
        if (localStorage.getItem('token')) {
          dispatch(SET_MAIN_LOADER(true))
          let token = localStorage.getItem('token')
          var data = {
            "en": "RESTART_GAME",
            "data": {
              "relogin_token": token
            }
          }
          socket?.emit("req", data);
          console.log('data: ', data);
          console.log("socket call relogin");
        }
      });

    }
  }, [socket])




  return (
    <SocketContext.Provider value={state}>
      <Loader />
      <SuccessMessagePopup />
      <ErrorMessagePopup />
      {socket ? children : null}
    </SocketContext.Provider>

  )
}


let SocketState = () => {
  return useContext(SocketContext);
};

export { SocketState };

export default SocketProvider