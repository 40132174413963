import React, { useEffect, useState } from "react";
import { SET_COLOR_ACTIVE_GAME } from "../../../redux/colorGameSlice";
import {useDispatch} from "react-redux"

const WinGoGameList = () => {
  const dispatch = useDispatch()

  const [routes, setRoutes] = useState([
    { key: 'METALY', title: '1' },
    { key: 'PERITY', title: '3' },
    { key: 'KAETY', title: '5' },
    { key: 'BECONE', title: '10' },
  ])

  const [index, setIndex] = useState(0)


  useEffect(() => {
    dispatch(SET_COLOR_ACTIVE_GAME(routes[index].key))
  }, [index])




  return (
    <div class="GameList__C">
{routes.map((route,routeIndex)=>{
  return(
    <div className={`GameList__C-item ${index===routeIndex && 'active'}`} key={routeIndex} onClick={()=>setIndex(routeIndex)}>
    <div>
      Win Go
      <br />
      {route.title}Min
    </div>
  </div>
  )
})}

     
    </div>
  );
};

export default WinGoGameList;
