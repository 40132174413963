import { createSlice } from "@reduxjs/toolkit";

export const SelectGameId = ({ comanGameInfoSlice }) => comanGameInfoSlice.game_id
export const SelectUserWallet = ({ comanGameInfoSlice }) => comanGameInfoSlice.user_wallet
export const SelectTotalPlayAmount = ({ comanGameInfoSlice }) => comanGameInfoSlice.total_play_amount
export const SelectPrintTicketFlag = ({ comanGameInfoSlice }) => comanGameInfoSlice.print_ticket_flag
export const SelectWinCard = ({ comanGameInfoSlice }) => comanGameInfoSlice.winCard
export const SelectGameTime = ({ comanGameInfoSlice }) => comanGameInfoSlice.gameTimer
export const SelectReward = ({ comanGameInfoSlice }) => comanGameInfoSlice.reward
export const SelectGameSpinnerUpdate = ({ comanGameInfoSlice }) => comanGameInfoSlice.gameSpinnerUpdate
export const SelectPlaceBatFlag = ({ comanGameInfoSlice }) => comanGameInfoSlice.placeBatFlag
export const SelectLastWinCard = ({ comanGameInfoSlice }) => comanGameInfoSlice.last_win_Card
export const SelectWinAmount = ({ comanGameInfoSlice }) => comanGameInfoSlice.winAmount
export const SelectRepeatBetData = ({ comanGameInfoSlice }) => comanGameInfoSlice.repeat_bet_data
export const SelectNextGameTime = ({ comanGameInfoSlice }) => comanGameInfoSlice.next_game_time
export const SelectResultData = ({ comanGameInfoSlice }) => comanGameInfoSlice.result_data
export const SelectHistoryData = ({ comanGameInfoSlice }) => comanGameInfoSlice.histroryData
export const SelectgameReport = ({ comanGameInfoSlice }) => comanGameInfoSlice.lottery_Report
export const SelectCloseBtnFlag = ({ comanGameInfoSlice }) => comanGameInfoSlice.closeBtnDisable
export const SelectNextRoundFlag = ({ comanGameInfoSlice }) => comanGameInfoSlice.next_round_flag




export const initialState = {
    game_id: '',
    gameTimer: null,
    next_game_time: null,
    user_wallet: 0,
    last_win_Card: [],
    last_ticket_id: null,
    winAmount: 0,
    placeBatFlag: true,
    print_ticket_flag: true,
    repeat_bet_data: null,
    gameSpinnerUpdate: 0,
    winCard: null,
    reward: 0,
    histroryData: [],
    lottery_Report: [],
    result_data: [],
    total_play_amount: 0,
    closeBtnDisable: false,
    next_round_flag: false
}

const comanGameInfoSlice = createSlice({
    name: "comanGameInfoSlice",
    initialState,
    reducers: {
        SET_GAME_ID: (state, { payload }) => { state.game_id = payload },
        SET_GAME_TIMER: (state, { payload }) => { state.gameTimer = payload },
        SET_NEXT_GAME_TIME: (state, { payload }) => { state.next_game_time = payload },
        SET_USER_WALLET: (state, { payload }) => { state.user_wallet = payload },
        SET_LAST_WIN_CARD: (state, { payload }) => { state.last_win_Card = [...payload] },
        SET_LAST_TICKET_ID: (state, { payload }) => { state.last_ticket_id = payload },
        SET_WIN_AMOUNT: (state, { payload }) => { state.winAmount = payload },
        SET_PLACE_BET_FLAG: (state, { payload }) => { state.placeBatFlag = payload },
        SET_WIN_CARD: (state, { payload }) => { state.winCard = payload },
        SET_PRINT_TICKET_FLAG: (state, { payload }) => { state.print_ticket_flag = payload },
        SET_REPEAT_BET: (state, { payload }) => { state.repeat_bet_data = payload },
        SET_GAME_SPINNER_UPDATE: (state, { payload }) => { state.gameSpinnerUpdate = state.gameSpinnerUpdate + 1 },
        SET_REWARD: (state, { payload }) => { state.reward = payload },
        SET_HISTORY_DATA: (state, { payload }) => { state.histroryData = [...payload] },
        SET_LOTTERY_REPORT: (state, { payload }) => { state.lottery_Report = [...payload] },
        SET_RESULT_DATA: (state, { payload }) => { state.result_data = [...payload] },
        SET_TOTAL_PLAY_AMOUNT: (state, { payload }) => { state.total_play_amount = payload },
        SET_CLOSE_BTN_DISABLE: (state, { payload }) => { state.closeBtnDisable = payload },
        SET_NEXT_ROUND_FLAG: (state, { payload }) => { state.next_round_flag = payload },
        RESET_GAME_INFO: () => initialState

    }

})

export const {
    SET_GAME_ID, SET_GAME_TIMER, SET_NEXT_GAME_TIME, SET_USER_WALLET, SET_LAST_WIN_CARD, SET_LAST_TICKET_ID, SET_WIN_AMOUNT, SET_PLACE_BET_FLAG,
    SET_PRINT_TICKET_FLAG, SET_REPEAT_BET, SET_WIN_CARD, SET_REWARD, SET_HISTORY_DATA, SET_LOTTERY_REPORT, SET_RESULT_DATA, SET_TOTAL_PLAY_AMOUNT,
    RESET_GAME_INFO, SET_GAME_SPINNER_UPDATE, SET_CLOSE_BTN_DISABLE, SET_NEXT_ROUND_FLAG


} = comanGameInfoSlice.actions;

export default comanGameInfoSlice.reducer;